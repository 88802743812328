import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { getAuctions, getProducts, getBids, getReviews, createBid, getCategories, getMedia, getLastBidders, startAuction } from '../utils/coreAPI';
import { Auction, Bid, Media, Product, ProductCategory, Review } from '../../../types';
import io from 'socket.io-client';
import { useError } from '../components/ErrorContext';

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
const socketUrl = apiUrl.endsWith('/api') ? apiUrl.slice(0, -4) : apiUrl;
const socket = io(socketUrl);

interface AuctionStateContextProps {
    auctions: Auction[];
    products: Product[];
    media: Media[];
    bids: Bid[];
    timers: Map<number, number>;
    prevBidders: Map<number, { username: string; uid: number }>;
    lastBidders: Map<number, { username: string; bid_time: string }[]>;
    categories: CategoryWithCounts[];
    reviews: Review[];
    isLoading: boolean;
    handleBid: (auctionId: number) => Promise<void>;
    getCountdown: (startTime: string) => string;
    getProductImage: (product: Product) => string | undefined;
    fetchData: () => Promise<void>;
}

interface CategoryWithCounts extends ProductCategory {
    item_count: number;
    active_auctions: number;
    upcoming_auctions: number;
    completed_auctions: number;
  }
  

const AuctionStateContext = createContext<AuctionStateContextProps | undefined>(undefined);

export const AuctionStateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { showError } = useError();
    const [auctions, setAuctions] = useState<Auction[]>([]);
    const [products, setProducts] = useState<Product[]>([]);
    const [media, setMedia] = useState<Media[]>([]);
    const [bids, setBids] = useState<Bid[]>([]);
    const [timers, setTimers] = useState<Map<number, number>>(new Map());
    const [prevBidders, setPrevBidders] = useState<Map<number, { username: string; uid: number }>>(new Map());
    const [lastBidders, setLastBidders] = useState<Map<number, { username: string; bid_time: string }[]>>(new Map());
    const [categories, setCategories] = useState<CategoryWithCounts[]>([]);
    const [reviews, setReviews] = useState<Review[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const token = localStorage.getItem('token');

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const auctionsResponse = await getAuctions();
            const fetchedAuctions = auctionsResponse.data;

            const bidsResponse = await getBids();
            const allBids = bidsResponse.data;

            const lastBiddersResponse = await getLastBidders();
            const lastBiddersData = lastBiddersResponse.data;

            // Transform lastBiddersData into a Map
            const lastBiddersMap = new Map<number, { username: string; bid_time: string }[]>();
            lastBiddersData.forEach((item: { auction_id: number; last_bidders: { username: string; bid_time: string }[] }) => {
                // Sort the last_bidders array by bid_time in descending order
                const sortedBidders = item.last_bidders.sort((a, b) => new Date(b.bid_time).getTime() - new Date(a.bid_time).getTime());
                
                // Keep only the 5 most recent bids
                lastBiddersMap.set(item.auction_id, sortedBidders.slice(0, 5));
            });

            setLastBidders(lastBiddersMap);

            // Set timers based on the auction's current_bid and last bid time
            const newTimers = new Map<number, number>();
            fetchedAuctions.forEach((auction) => {
                const now = new Date();
                const auctionStartTime = new Date(auction.start_time);

                // Check if an upcoming auction should be started
                if (auction.status === 'upcoming' && auctionStartTime <= now) {
                    auction.status = 'active'; // Start the auction
                    startAuction(auction.id);

                }

                if (auction.status === 'active') {
                    const auctionBids = allBids.filter((bid: Bid) => bid.auction_id === auction.id);
                    const lastBid = auctionBids.reduce((latest: Bid, bid: Bid) => {
                        return new Date(bid.bid_time) > new Date(latest.bid_time) ? bid : latest;
                    }, auctionBids[0]);

                    if (auction.current_bid === 0 || !lastBid) {
                        newTimers.set(auction.id, auction.countdown_timer);
                    } else {
                        const lastBidTime = new Date(lastBid.bid_time).getTime();
                        const now = Date.now();
                        const timeSinceLastBid = Math.floor((now - lastBidTime) / 1000);

                        if (timeSinceLastBid < 0) {
                            newTimers.set(auction.id, auction.countdown_timer);
                        } else {
                            newTimers.set(auction.id, auction.countdown_timer - timeSinceLastBid);
                        }
                    }
                }
            });
            setTimers(newTimers);

            setAuctions(fetchedAuctions);
            setBids(allBids);

            const productsResponse = await getProducts();
            const productsWithMedia = await Promise.all(
                productsResponse.data.map(async (product) => {
                    const mediaResponse = await getMedia('product', product.id);
                    return {
                        ...product,
                        media: mediaResponse.data || []
                    };
                })
            );
            setProducts(productsWithMedia);

            const reviewsResponse = await getReviews();
            setReviews(reviewsResponse.data);

            const categoriesResponse = await getCategories();
            setCategories(categoriesResponse.data);

            setIsLoading(false);
        } catch (error) {
            console.error('Failed to fetch data:', error);
            showError('Failed to fetch auction data. Please refresh the page.', 'error');
        } finally {
            setIsLoading(false);
        }
    };
    

    useEffect(() => {
        fetchData();

        socket.on('auctionDelete', handleAuctionDelete);
        socket.on('auctionUpdate', handleAuctionUpdate);
        socket.on('bidPlaced', handleBidPlaced);
        socket.on('auctionSync', handleAuctionSync);
        socket.on('newReview', handleNewReview);

        return () => {
            socket.off('auctionDelete', handleAuctionDelete);
            socket.off('auctionUpdate', handleAuctionUpdate);
            socket.off('bidPlaced', handleBidPlaced);
            socket.off('auctionSync', handleAuctionSync);
            socket.off('newReview', handleNewReview);
        };
    }, []); // Empty dependency array ensures this runs only once on mount

    useEffect(() => {
        const interval = setInterval(() => {
            setTimers((prevTimers) => {
                const newTimers = new Map<number, number>();
                prevTimers.forEach((time, auctionId) => {
                    if (time > 0) {
                        newTimers.set(auctionId, time - 1);
                    }
                });

                // Check for auctions that should be started
                auctions.forEach((auction) => {
                    const now = new Date();
                    const auctionStartTime = new Date(auction.start_time);

                    if (auction.status === 'upcoming' && auctionStartTime <= now) {
                        auction.status = 'active'; // Start the auction
                        startAuction(auction.id);
                    }
                });

                return newTimers;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [auctions]); // Add auctions to the dependency array
    

    function handleNewReview(...args: any[]): void {
        const [review] = args;
        setReviews(prevReviews => {
            // Create a new array with the new review added
            const newReviews = [...prevReviews];
            
            // Check if review already exists
            const existingIndex = newReviews.findIndex(r => r.id === review.id);
            
            if (existingIndex >= 0) {
                // Update existing review
                newReviews[existingIndex] = review;
            } else {
                // Add new review
                newReviews.push(review);
            }
            
            return newReviews;
        });
    
    }
    const handleAuctionSync = (syncData: { auction_id: number; remainingTime: number }[]) => {
        setTimers((prevTimers) => {
            const newTimers = new Map(prevTimers);
            syncData.forEach(({ auction_id, remainingTime }) => {
                newTimers.set(auction_id, remainingTime);
            });
            return newTimers;
        });
    };

    const handleAuctionUpdate = (updatedAuction: Auction) => {
        setAuctions((prevAuctions) => {
            if (updatedAuction.status === 'active') {
                const existingAuction = prevAuctions.find(auction => auction.id === updatedAuction.id);
                if (existingAuction?.status === 'upcoming') {
                    setTimers(prevTimers => {
                        const newTimers = new Map(prevTimers);
                        newTimers.set(updatedAuction.id, updatedAuction.countdown_timer);
                        return newTimers;
                    });
                }
            }
            const existingAuction = prevAuctions.find(auction => auction.id === updatedAuction.id);
            
            if (existingAuction) {
                return prevAuctions.map(auction => 
                    auction.id === updatedAuction.id ? updatedAuction : auction
                );
            } else {
                return [...prevAuctions, updatedAuction];
            }
        });
    };

    const handleAuctionDelete = (auctionId: number) => {
        setAuctions(prevAuctions => prevAuctions.filter(auction => auction.id !== auctionId));
        setTimers(prevTimers => {
            const newTimers = new Map(prevTimers);
            newTimers.delete(auctionId);
            return newTimers;
        });
    };

    const getCountdown = (startTime: string): string => {
        const now = new Date();
        const start = new Date(startTime);
        const diff = start.getTime() - now.getTime();

        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

        const timeUnits = [];
        if (days > 0) timeUnits.push(`${days}d`);
        if (hours > 0) timeUnits.push(`${hours}h`);
        if (minutes > 0 || timeUnits.length === 0) timeUnits.push(`${minutes}m`);

        return timeUnits.join(' ');
    };

    const getProductImage = useCallback((product: Product) => {
        if (product!.media && product.media.length > 0) {
            const sortedMedia = [...product.media].sort((a, b) => a.order - b.order);
            return sortedMedia[0].url;
        }
        return "https://ushop.bid/uploads/product/" + product.id + "/product_" + product.id + "_1.jpeg" || `${process.env.REACT_APP_API_URL?.replace('/api', '') || 'http://127.0.0.1:4000'}/uploads/logo.png`;
    }, []);

    const handleBidPlaced = (data: { auction: Auction; bid: Bid; uid: number; username: string }) => {
        const { auction, bid, uid, username } = data;
        console.log('auction', auction.current_bid);
        setPrevBidders((prevMap) => {
            const newMap = new Map(prevMap);
            newMap.set(auction.id, { username, uid });
            return newMap;
        });

        setLastBidders((prevMap) => {
            const newMap = new Map(prevMap);
            const existingBidders = newMap.get(auction.id) || [];
            const updatedBidders = [...existingBidders, { username, bid_time: bid.bid_time }];

            // Sort the bidders by bid_time in descending order
            updatedBidders.sort((a, b) => new Date(b.bid_time).getTime() - new Date(a.bid_time).getTime());

            // Keep only the 5 most recent bids
            newMap.set(auction.id, updatedBidders.slice(0, 5));
            return newMap;
        });
        setAuctions((prevAuctions) =>
            prevAuctions.map((a) => (a.id === auction.id ? { ...a, current_bid: auction.current_bid } : a))
        );

        setBids((prevBids) => [...prevBids, bid]);

        setTimers((prevTimers) => {
            const newTimers = new Map(prevTimers);
            newTimers.set(auction.id, auction.countdown_timer);
            return newTimers;
        });
    };

    const handleBid = async (auctionId: number) => {
        try {
            const auction = auctions.find((auction) => auction.id === auctionId);
            if (!auction) {
                showError('Auction not found. Please refresh the page.', 'error');
                return;
            }

            if (auction.status !== 'active') {
                showError('This auction is not active. You cannot place a bid at this time.', 'warning');
                return;
            }

            if (!token) {
                showError('You must be logged in to place a bid.', 'info');
                return;
            }

            await createBid(token, {
                auction_id: auctionId,
                bid_time: new Date().toISOString(),
                seconds_left: 0
            });
        } catch (error) {
            console.error('Failed to place bid', error);
            showError('Failed to place bid. Please try again.', 'error');
        }
        
    };

    return (
        <AuctionStateContext.Provider value={{ auctions, products, media, bids, timers, prevBidders, lastBidders, categories, reviews, isLoading, handleBid, getCountdown, getProductImage, fetchData }}>
            {children}
        </AuctionStateContext.Provider>
    );
};

export const useAuctionState = () => {
    const context = useContext(AuctionStateContext);
    if (!context) {
        throw new Error('useAuctionState must be used within an AuctionStateProvider');
    }
    return context;
};


