import React, { useEffect, useState } from 'react';
import { useAuctionState } from '../../components/auctionStateContext';
import { Address, Auction, Invoice, Product, User } from '../../../../types';
import { getUserInvoices, createPayPalOrder, capturePayPalOrder } from '../../utils/accountAPI';
import generateInvoice from '../../utils/accountAPI';
import styles from './WinningAuctions.module.css';
import { ReviewForm } from './ReviewForm';
import { PayPalButtons } from "@paypal/react-paypal-js";
import Loading from '../../components/loading';
import { getAddressDetails } from '../../utils/accountAPI';

interface WinningAuctionsProps {
  userId: number;
  token: string;
  user: Partial<User>;
}

const WinningAuctions: React.FC<WinningAuctionsProps> = ({ userId, token,user }) => {
  const { auctions, products, reviews,fetchData } = useAuctionState();
  const [shippingAddress, setShippingAddress] = useState<Address>();

  const [billingAddress, setBillingAddress] = useState<Address>();
  const [wonAuctions, setWonAuctions] = useState<Auction[]>([]);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [filter, setFilter] = useState<string>('all');
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'ascending' | 'descending' } | null>(null);

  useEffect(() => {
    const fetchWonAuctions = async () => {
      try {
        const response = await getUserInvoices(token);
        const addressResponse = await getAddressDetails(token);
        // Find latest shipping and billing addresses from reversed address list
        const reversedAddresses = [...addressResponse.data].reverse();
        const latestShippingAddress = reversedAddresses.find(addr => addr.address_type === 'SHIPPING');
        const latestBillingAddress = reversedAddresses.find(addr => addr.address_type === 'BILLING');

        if (latestShippingAddress) {
          setShippingAddress(latestShippingAddress);
        }
        if (latestBillingAddress) {
          setBillingAddress(latestBillingAddress);
        }

        const userInvoices = response.filter((invoice: Invoice) => invoice.user_id === userId);

        const auctionIds = userInvoices.map(invoice => invoice.auction_id).filter(id => id !== null);

        const userWonAuctions = auctions.filter(auction => auctionIds.includes(auction.id));
        setWonAuctions(userWonAuctions);
        setInvoices(userInvoices);
      } catch (err) {
        setError('Failed to fetch won auctions or invoices.');
      } finally {
        setLoading(false);
      }
    };

    fetchWonAuctions();
  }, [auctions, userId, token]);

 
  const handleGenerateInvoice = async (invoice: Invoice, auction: Auction, product: Product, user: Partial<User>) => {
    try {
      await generateInvoice(invoice, user, product, auction, shippingAddress!, billingAddress!);
    } catch (err) {
      setError('Failed to generate invoice.');
    }
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFilter(event.target.value);
  };

  const handleSort = (key: string) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedAuctions = [...wonAuctions].sort((a, b) => {
    if (!sortConfig) return 0;
    const invoiceA = invoices.find(inv => inv.auction_id === a.id);
    const invoiceB = invoices.find(inv => inv.auction_id === b.id);
    const productA = products.find(prod => prod.id === a.product_id);
    const productB = products.find(prod => prod.id === b.product_id);

    let aValue, bValue;
    switch (sortConfig.key) {
      case 'product':
        aValue = productA?.title || '';
        bValue = productB?.title || '';
        break;
      case 'winningBid':
        aValue = Number(a.current_bid);
        bValue = Number(b.current_bid);
        break;
      case 'postage':
        aValue = Number(productA?.postage || 0);
        bValue = Number(productB?.postage || 0);
        break;
      case 'totalCost':
        aValue = Number(a.current_bid) + Number(productA?.postage || 0);
        bValue = Number(b.current_bid) + Number(productB?.postage || 0);
        break;
      case 'status':
        aValue = invoiceA?.status || '';
        bValue = invoiceB?.status || '';
        break;
      case 'date':
        aValue = new Date(invoiceA?.date || '').getTime();
        bValue = new Date(invoiceB?.date || '').getTime();
        break;
      default:
        return 0;
    }

    if (aValue < bValue) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (aValue > bValue) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  const filteredAuctions = sortedAuctions.filter(auction => {
    const invoice = invoices.find(inv => inv.auction_id === auction.id);
    return filter === 'all' || (invoice && invoice.status === filter);
  });

  const handleViewReview = (auctionId: number) => {
    // Logic to fetch and display the review for the given auctionId
    // This could involve setting a modal or redirecting to a review page
  };

  const handleReviewSubmit = ({ auctionId, review }: { auctionId: number; review: any }) => {
    setInvoices(prevInvoices => prevInvoices.map(inv => 
      inv.auction_id === auctionId ? { ...inv, status: 'completed' } : inv
    ));
    fetchData();
  };

  if (loading) return <Loading message="Loading your won auctions..." subMessage="Fetching your auction details" />;
  if (error) return <div className={styles.error}>{error}</div>;

  return (
    <div className={styles.winningAuctions}>
      <h2>Your Won Auctions</h2>
      <div>
        <label htmlFor="statusFilter">Filter by Status: </label>
        <select id="statusFilter" value={filter} onChange={handleFilterChange}>
          <option value="all">All</option>
          <option value="awaiting payment">Awaiting Payment</option>
          <option value="paid">Paid</option>
          <option value="shipped">Shipped</option>
          <option value="delivered">Delivered</option>
          <option value="completed">Completed</option>
        </select>
      </div>
      {filteredAuctions.length === 0 ? (
        <p>No auctions found for the selected status.</p>
      ) : (
        <table className={styles.auctionTable}>
          <thead>
            <tr>
              <th onClick={() => handleSort('date')}>Date</th>
              <th>Invoice Number</th>
              <th onClick={() => handleSort('product')}>Product</th>
              <th onClick={() => handleSort('winningBid')}>Winning Bid</th>
              <th onClick={() => handleSort('postage')}>Postage</th>
              <th onClick={() => handleSort('totalCost')}>Total Cost</th>
              <th onClick={() => handleSort('status')}>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredAuctions.map(auction => {
              const invoice = invoices.find(inv => inv.auction_id === auction.id);
              const product = products.find(prod => prod.id === auction.product_id);
              const totalCost = (Number(auction.current_bid) + (Number(product?.postage) || 0)) / 100;
              const date = new Date(invoice?.date || '').toLocaleDateString();

              return (
                <tr key={auction.id}>
                  <td>{date}</td>
                  <td>{invoice?.id || 'N/A'}</td>
                  <td>{product?.title}</td>
                  <td>£{(auction.current_bid / 100).toFixed(2)}</td>
                  <td>£{((product?.postage || 0) / 100).toFixed(2)}</td>
                  <td>£{totalCost.toFixed(2)}</td>
                  <td><span className={styles[invoice!.status!.replace(' ', '').toLowerCase()]}>{invoice?.status}</span></td>
                  <td>
                    {invoice?.status === 'awaiting payment' ? (
                      <PayPalButtons
                        createOrder={async () => {
                          const orderResponse = await createPayPalOrder(invoice.amount, 'GBP', invoice.id);
                          return orderResponse.id;
                        }}
                        onApprove={async (data) => {
                          await capturePayPalOrder(data.orderID);
                          setInvoices(prevInvoices => prevInvoices.map(inv => 
                            inv.id === invoice.id ? { ...inv, status: 'paid' } : inv
                          ));
                        }}
                        onError={(err) => {
                          console.error('PayPal Checkout onError', err);
                          setError('Failed to process payment.');
                        }}
                      />
                    ) : invoice?.status === 'delivered' ? (
                      <div>
                        <button className={styles.payButton} onClick={() => handleGenerateInvoice(invoice!, auction, product!, user)}>Generate Invoice</button>
                        <ReviewForm auctionId={auction.id} productId={auction.product_id} userId={userId} onSubmitSuccess={handleReviewSubmit} token={token} />
                      </div>
                    ) : invoice?.status === 'completed' ? (
                      <div className={styles.reviewDisplay}>
                        <div><strong>Review:</strong> {reviews[auction.id]?.review_text}</div>
                        <div><strong>Rating:</strong> {reviews[auction.id]?.rating} / 5</div>
                      </div>
                    ) : (
                      <button className={styles.payButton} onClick={() => handleGenerateInvoice(invoice!, auction, product!, user)}>Generate Invoice</button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default WinningAuctions;
