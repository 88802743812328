// src/pages/BidsPage.tsx
import React, { useState, useEffect } from 'react';
import { getBids, deleteBid, getUsers, getProducts, getAuctions } from '../../utils/adminAPI';
import { Bid } from '../../../../types';
import Loading from '../../components/loading'; // Import Loading component
import Pagination from '../../components/pagination'; // Import Pagination component
import styles from './Management.module.css'; // Import styles from Management.module.css

const BidsPage: React.FC = () => {
  const [bids, setBids] = useState<Bid[]>([]); // State to store bid data
  const [users, setUsers] = useState<{ [key: number]: string }>({}); // State to store user data
  const [products, setProducts] = useState<{ [key: number]: string }>({}); // State to store product data
  const [auctions, setAuctions] = useState<{ [key: number]: { product_id: number; start_time: string } }>({}); // State to store auction data
  const token = localStorage.getItem('token'); // or however you're storing the token
  const [loading, setLoading] = useState<boolean>(true); // State to manage loading
  const [currentPage, setCurrentPage] = useState<number>(1); // State for current page
  const [pageSize] = useState<number>(10); // Define page size

  // State for filters
  const [userFilter, setUserFilter] = useState<string>('');
  const [auctionIdFilter, setAuctionIdFilter] = useState<string>('');
  const [productTitleFilter, setProductTitleFilter] = useState<string>('');
  const [bidTypeFilter, setBidTypeFilter] = useState<string>(''); // New state for bid type filter
  
  useEffect(() => {
    fetchBids(); // Fetch bids on component mount
    fetchUsers(); // Fetch users on component mount
    fetchProducts(); // Fetch products on component mount
    fetchAuctions(); // Fetch auctions on component mount
  }, []);

  const fetchBids = async () => {
    setLoading(true); // Set loading to true before fetching
    try {
      if (token) {
        const response = await getBids(token); // Fetch bids from the backend
        setBids(response.data); // Set bids in state
      }
    } catch (error) {
      console.error('Failed to fetch bids', error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await getUsers(token!); // Assume getUsers is a function to fetch users
      const usersData = response.data.reduce((acc: { [key: number]: string }, user: { id: number; username: string }) => {
        acc[user.id] = user.username; // Map user ID to username
        return acc;
      }, {});
      setUsers(usersData); // Set users in state
    } catch (error) {
      console.error('Failed to fetch users', error);
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await getProducts(token!); // Fetch products from the backend
      const productsData = response.data.reduce((acc: { [key: number]: string }, product: { id: number; title: string }) => {
        acc[product.id] = product.title; // Map product ID to title
        return acc;
      }, {});
      setProducts(productsData); // Set products in state
    } catch (error) {
      console.error('Failed to fetch products', error);
    }
  };

  const fetchAuctions = async () => {
    try {
      const response = await getAuctions(token!); // Fetch auctions from the backend
      const auctionsData = response.data.reduce((acc: { [key: number]: { product_id: number; start_time: string } }, auction: { id: number; product_id: number; start_time: string }) => {
        acc[auction.id] = { product_id: auction.product_id, start_time: auction.start_time }; // Map auction ID to product ID and start time
        return acc;
      }, {});
      setAuctions(auctionsData); // Set auctions in state
    } catch (error) {
      console.error('Failed to fetch auctions', error);
    }
  };

  const handleDelete = async (id: number) => {
    try {
      if (token) {
        await deleteBid(token, id);
        fetchBids(); // Refresh the bid list after deletion
      }
    } catch (error) {
      console.error('Failed to delete bid', error);
    }
  };

  // Calculate the bids to display based on current page, page size, and filters
  const filteredBids = bids.filter(bid => {
    const userMatch = users[bid.user_id]?.toLowerCase().includes(userFilter.toLowerCase()) || userFilter === '';
    const auctionIdMatch = bid.auction_id.toString().includes(auctionIdFilter) || auctionIdFilter === '';
    const productMatch = products[auctions[bid.auction_id]?.product_id]?.toLowerCase().includes(productTitleFilter.toLowerCase()) || productTitleFilter === '';
    const typeMatch = (bid.type === 'U' || bid.type === 'V') && (bidTypeFilter === '' || bid.type === bidTypeFilter); // Filter by bid type
    return userMatch && auctionIdMatch && productMatch && typeMatch;
  });

  const paginatedBids = filteredBids.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <div className={styles.pageContainer}>
      <h1 className={styles.pageTitle}>Manage Bids</h1>
      {loading ? ( // Show loading component while fetching
        <Loading />
      ) : (
        <div>
          <h2>Bid List</h2>
          <div className={styles.searchContainer}>
            <input
              type="text"
              placeholder="Filter by User"
              value={userFilter}
              onChange={(e) => setUserFilter(e.target.value)}
              className={styles.searchInput}
            />
            <input
              type="text"
              placeholder="Filter by Auction ID"
              value={auctionIdFilter}
              onChange={(e) => setAuctionIdFilter(e.target.value)}
              className={styles.searchInput}
            />
            <input
              type="text"
              placeholder="Filter by Product Title"
              value={productTitleFilter}
              onChange={(e) => setProductTitleFilter(e.target.value)}
              className={styles.searchInput}
            />
            <input
              type="text"
              placeholder="Filter by Bid Type (u or v)"
              value={bidTypeFilter}
              onChange={(e) => setBidTypeFilter(e.target.value)}
              className={styles.searchInput}
            />
          </div>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>User</th>
                <th>Auction ID</th>
                <th>Product Title</th>
                <th>Auction Start Time</th>
                <th>Bid Time</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {paginatedBids.map((bid) => (
                <tr key={bid.id}>
                  <td>{users[bid.user_id] || 'Unknown User'}</td>
                  <td>{bid.auction_id}</td>
                  <td>{products[auctions[bid.auction_id]?.product_id] || 'Unknown Product'}</td>
                  <td>{new Date(auctions[bid.auction_id]?.start_time).toLocaleString() || 'N/A'}</td>
                  <td>{new Date(bid.bid_time).toLocaleString()}</td>
                  <td>
                    <button className={styles.deleteButton} onClick={() => handleDelete(bid.id)}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            currentPage={currentPage}
            totalItems={filteredBids.length} // Total items based on filtered results
            pageSize={pageSize}
            onPageChange={(page) => setCurrentPage(page)} // Handle page change
          />
        </div>
      )}
    </div>
  );
};

export default BidsPage;
