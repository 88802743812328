import React, { useState, useEffect } from 'react';
import styles from './Banner.module.css';
import { ChevronLeft, ChevronRight } from 'lucide-react';

interface BannerSlide {
  image: string;
  title: string;
  description: string;
  link: string;
  openInNewTab?: boolean;
}

interface BannerProps {
  slides: BannerSlide[];
  autoPlayInterval?: number;
  imageFit?: 'contain' | 'cover';
  centeredTitle?: string;
  logo?: string | '/uploads/logo-circle.png';
}

const Banner: React.FC<BannerProps> = ({ slides, autoPlayInterval = 5000, imageFit = 'cover', centeredTitle, logo }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      handleNext();
    }, autoPlayInterval);

    return () => clearInterval(timer);
  }, [currentSlide, autoPlayInterval]);

  const handlePrev = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
    setTimeout(() => setIsTransitioning(false), 500);
  };

  const handleNext = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
    setTimeout(() => setIsTransitioning(false), 500);
  };

  return (
    <div className={styles.bannerContainer}>
      <div className={styles.bannerWrapper}>
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`${styles.bannerSlide} ${
              index === currentSlide ? styles.active : ''
            }`}
            style={{
              transform: `translateX(${100 * (index - currentSlide)}%)`,
            }}
          >
            <div className={styles.imageContainer}>
              <img 
                src={slide.image} 
                alt={slide.title} 
                className={styles.bannerImage} 
                style={{ objectFit: imageFit }}
              />
              {logo && (
                <img 
                  src={logo} 
                  alt="Logo" 
                  className={styles.bannerLogo}
                />
              )}
            </div>
            {centeredTitle && (
              <div className={styles.centeredTitle}>
                {centeredTitle}
              </div>
            )}
            <div className={styles.bannerContent}>
              <h2>{slide.title}</h2>
              <p>{slide.description}</p>
              <a href={slide.link} className={styles.bannerCta} target={slide.openInNewTab ? "_blank" : undefined} rel={slide.openInNewTab ? "noopener noreferrer" : undefined}>
                Learn More
              </a>
            </div>
          </div>
        ))}
      </div>

      <button className={`${styles.bannerButton} ${styles.prev}`} onClick={handlePrev}>
        <ChevronLeft size={24} />
      </button>
      <button className={`${styles.bannerButton} ${styles.next}`} onClick={handleNext}>
        <ChevronRight size={24} />
      </button>

      <div className={styles.bannerDots}>
        {slides.map((_, index) => (
          <button
            key={index}
            className={`${styles.dot} ${index === currentSlide ? styles.activeDot : ''}`}
            onClick={() => setCurrentSlide(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Banner; 