import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { activateAccount } from '../../utils/accountAPI';
import { useError } from '../../components/ErrorContext';
import Loading from '../../components/loading';

const ActivateAccountPage: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();
  const { showError } = useError();
  const [activationMessage, setActivationMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const activate = async () => {
      try {
        if (!token) {
          showError('Invalid activation link.');
          setIsLoading(false);
          return;
        }

        const response = await activateAccount(token);
        if (response.data.token) {
          // Store the token in local storage
          localStorage.setItem('token', response.data.token);
          // Set activation message
          setActivationMessage('Your account has been activated successfully! Redirecting to your account...');
          // Redirect after a short delay
          setTimeout(() => {
            navigate('/account');
          }, 2000); // 2 seconds delay
        } else {
          setActivationMessage('Account activation failed. Please try again.');
        }
      } catch (error) {
        showError('Activation failed. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    activate();
  }, [token, navigate, showError]);

  return (
    <div className="activation-container">
        <Loading message="Activating your account..." subMessage={activationMessage|| "Please wait while we activate your account."} />
    </div>
  );
};

export default ActivateAccountPage; 