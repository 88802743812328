import React, { useState, useEffect } from "react";
import { useAuctionState } from "../../components/auctionStateContext";
import styles from "./Winners.module.css";
import Loading from "../../components/loading";
import Banner from "../../components/Banner";
import { Link } from "react-router-dom";
import { getMedia } from "../../utils/coreAPI"; // Import the getMedia function
import Pagination from "../../components/pagination"; // Import the Pagination component

const WinnersPage: React.FC = () => {
  const {
    auctions,
    products,
    bids,
    reviews,
    lastBidders,
    isLoading,
    getProductImage,
  } = useAuctionState();
  const [filterType, setFilterType] = useState<
    "all" | "withReviews" | "withoutReviews"
  >("all");
  const [reviewMedia, setReviewMedia] = useState<Map<number, string>>(
    new Map()
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    const fetchReviewMedia = async () => {
      const mediaMap = new Map<number, string>();
      for (const review of reviews) {
        try {
          const response = await getMedia("review", review.id);
          if (response.data.length > 0) {
            mediaMap.set(review.id, response.data[0].url);
          }
        } catch (error) {
          console.error(
            `Failed to fetch media for review ${review.id}:`,
            error
          );
        }
      }
      setReviewMedia(mediaMap);
    };

    fetchReviewMedia();
  }, [reviews]);

  // Calculate unique bidders for each auction
  const uniqueBiddersCount = (auctionId: number) => {
    const bidders = bids.filter((bid) => bid.auction_id === auctionId);
    const uniqueBidders = new Set(bidders.map((bid) => bid.user_id));
    return uniqueBidders.size;
  };

  // Check if loading
  if (isLoading)
    return (
      <Loading
        message="Loading winners..."
        subMessage="Fetching recent auction winners"
      />
    );

  // Filter completed auctions
  const completedAuctions = auctions.filter(
    (auction) => auction.status === "completed"
  );
  const productMap = new Map(products.map((product) => [product.id, product]));

  // Filter auctions based on reviews
  const filteredAuctions = completedAuctions.filter((auction) => {
    const auctionReviews = reviews.filter(
      (review) => review.auction_id === auction.id
    );
    if (filterType === "withReviews") return auctionReviews.length > 0;
    if (filterType === "withoutReviews") return auctionReviews.length === 0;
    return true; // 'all' case
  });

  // Prepare slides for the banner
  const reviewedAuctions = completedAuctions
    .filter((auction) => {
      return reviews.some((review) => review.auction_id === auction.id);
    })
    .slice(0, 5);

  const bannerSlides = reviewedAuctions
    .map((auction) => {
      const product = productMap.get(auction.product_id);
      if (!product) return null;

      const auctionReviews = reviews.filter(
        (review) => review.auction_id === auction.id
      );
      const reviewImage =
        auctionReviews.length > 0
          ? reviewMedia.get(auctionReviews[0].id)
          : null;
      const displayImage = reviewImage || product.media[0]?.url;

      // Get the winner's username and current bid
      const lastBid = lastBidders.get(auction.id)?.[0];
      const winnerUsername = lastBid?.username;
      const currentBid = (auction.current_bid / 100).toFixed(2);
      const reviewRating =
        auctionReviews.length > 0 ? auctionReviews[0].rating : "N/A"; // Assuming rating is a property of review

      return {
        image: displayImage,
        title: product.title,
        description: `Winner: ${winnerUsername}  |  Final Bid: £${currentBid}  |  Rating: ${reviewRating}`,
        link: `/auction/${auction.id}`,
      };
    })
    .filter((slide): slide is NonNullable<typeof slide> => slide !== null);

  // Calculate pagination
  const totalFilteredItems = filteredAuctions.length;
  const paginatedAuctions = filteredAuctions.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <div className={styles.container}>
      <Banner
        slides={bannerSlides}
        imageFit="contain"
        logo="/uploads/logo-circle.png"
      />

      <div className={styles.winnersPage}>
        <h2 className={styles.sectionTitle}>Winners</h2>
        <div className={styles.filterButtons}>
          <button
            onClick={() => setFilterType("all")}
            className={`${styles.filterButton} ${
              filterType === "all" ? styles.active : ""
            }`}
          >
            All
          </button>
          <button
            onClick={() => setFilterType("withReviews")}
            className={`${styles.filterButton} ${
              filterType === "withReviews" ? styles.active : ""
            }`}
          >
            With Reviews
          </button>
          <button
            onClick={() => setFilterType("withoutReviews")}
            className={`${styles.filterButton} ${
              filterType === "withoutReviews" ? styles.active : ""
            }`}
          >
            Without Reviews
          </button>
        </div>
        <div className={styles.auctionGrid}>
          {paginatedAuctions.map((auction, index) => {
            const product = productMap.get(auction.product_id);
            if (!product) return null;

            const auctionReviews = reviews.filter(
              (review) => review.auction_id === auction.id
            );
            const lastBid = lastBidders.get(auction.id)?.[0];
            const endDate = new Date(lastBid?.bid_time || Date.now());
            const startTime = new Date(auction.start_time);

            const reviewImage =
              auctionReviews.length > 0
                ? reviewMedia.get(auctionReviews[0].id)
                : null;
            const displayImage = reviewImage || getProductImage(product);

            return (
              <Link
                to={`/auction/${auction.id}`}
                key={auction.id}
                className={styles.cardLink}
              >
                <div
                  key={auction.id}
                  className={`${styles.card} ${styles.auctionCard}`}
                  style={{ animationDelay: `${(index % 3) * 0.1}s` }}
                >
                  <div className={styles.imageContainer}>
                    {auctionReviews.length > 0 && (
                      <div className={styles.reviewTextContainer}>
                        {auctionReviews.map((review) => (
                          <p key={review.id} className={styles.reviewText}>
                            {review!.review_text!.substring(0, 100)}
                            {review!.review_text!.length > 100 ? "..." : ""}
                          </p>
                        ))}
                      </div>
                    )}
                    <img
                      className={styles.reviewLogo}
                      src="/uploads/logo-circle.png"
                      alt="Review Logo"
                    />
                    <img
                      className={styles.image}
                      src={displayImage}
                      alt={product.title}
                    />
                  </div>
                  <div className={styles.details}>
                    <div className={styles.header}>
                      <h3 className={styles.productTitle}>{product.title}</h3>
                    </div>
                    <div className="infoDetails">
                      <table>
                        <tbody>
                          <tr className="Info">
                            <td className="infoLabel">Winner:</td>
                            <td className="infoValue">{lastBid?.username}</td>
                          </tr>
                          <tr className="Info">
                            <td className="infoLabel">Final Bid:</td>
                            <td className="infoValue">
                              £{(auction.current_bid / 100).toFixed(2)}
                            </td>
                          </tr>
                          <tr className="Info">
                            <td className="infoLabel">RRP:</td>
                            <td className="infoValue">£{product.rrp}</td>
                          </tr>
                          <tr className="Info">
                            <td className="infoLabel">Start Time:</td>
                            <td className="infoValue">
                              {startTime.toLocaleString()}
                            </td>
                          </tr>
                          <tr className="Info">
                            <td className="infoLabel">End Date:</td>
                            <td className="infoValue">
                              {endDate.toLocaleString()}
                            </td>
                          </tr>
                          <tr className="Info">
                            <td className="infoLabel">Countdown:</td>
                            <td className="infoValue">
                              {auction.countdown_timer}s
                            </td>
                          </tr>
                          <tr className="Info">
                            <td className="infoLabel">Unique Bidders:</td>
                            <td className="infoValue">
                              {uniqueBiddersCount(auction.id)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
        <Pagination
          currentPage={currentPage}
          totalItems={totalFilteredItems}
          pageSize={pageSize}
          onPageChange={setCurrentPage}
          onPageSizeChange={setPageSize}
          pageSizeOptions={[10, 20, 50]}
          scrollToTop={true}
          containerRef={`.${styles.winnersPage}`}
        />
      </div>
    </div>
  );
};

export default WinnersPage;
