import React, { useState, useMemo, useEffect } from 'react';
import { Auction, Product } from '../../../../types';
import { deleteAuction, getProducts } from '../../utils/adminAPI';
import styles from './AuctionList.module.css';

interface AuctionListProps {
  auctions: Auction[];
  onEdit: (auction: Auction) => void;
  fetchAuctions: () => void;
}

const AuctionList: React.FC<AuctionListProps> = ({ auctions, onEdit, fetchAuctions }) => {
  const [searchText, setSearchText] = useState('');
  const [selectedStatus, setSelectedStatus] = useState<string[]>(['upcoming', 'active', 'completed']);
  const token = localStorage.getItem('token');
  const [sortConfig, setSortConfig] = useState<{ key: keyof Auction | null, direction: 'ascending' | 'descending' | null }>({ key: null, direction: null });
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    const fetchProducts = async () => {
      if (token) {
        const response = await getProducts(token);
        setProducts(response.data);
      }
    };
    fetchProducts();
  }, [token]);

  const handleDelete = async (id: number) => {
    if (token) {
      const confirmDelete = window.confirm('Are you sure you want to delete this auction?');
      if (confirmDelete) {
        await deleteAuction(token, id);
        fetchAuctions();
      }
    }
  };

  const getProductName = (productId: number) => {
    const product = products.find(p => p.id === productId);
    return product ? product.title : 'Unknown Product';
  };

  const filteredAuctions = useMemo(() => {
    return auctions.filter(auction => {
      const productName = getProductName(auction.product_id).toLowerCase();
      const matchesSearch = Object.values(auction).some(value => 
        value?.toString().toLowerCase().includes(searchText.toLowerCase())
      ) || productName.includes(searchText.toLowerCase());
      
      const matchesStatus = selectedStatus.includes(auction.status);
      
      return matchesSearch && matchesStatus;
    });
  }, [auctions, searchText, selectedStatus]);

  const sortedAuctions = useMemo(() => {
    let sortableAuctions = [...filteredAuctions];
    if (sortConfig.key) {
      sortableAuctions.sort((a, b) => {
        if (a[sortConfig.key!] < b[sortConfig.key!]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key!] > b[sortConfig.key!]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableAuctions;
  }, [filteredAuctions, sortConfig]);

  const requestSort = (key: keyof Auction) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleStatusChange = (status: string) => {
    setSelectedStatus(prev => 
      prev.includes(status) ? prev.filter(s => s !== status) : [...prev, status]
    );
  };


  return (
    <div className={styles.auctionList}>
      <h2>Auction List</h2>
      <div className={styles.filterControls}>
        <input
          type="text"
          placeholder="Search auctions"
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          className={styles.searchInput}
        />
        <div className={styles.statusCheckboxes}>
          {['upcoming', 'active', 'completed'].map(status => (
            <label key={status}>
              <input
                type="checkbox"
                checked={selectedStatus.includes(status)}
                onChange={() => handleStatusChange(status)}
              />
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </label>
          ))}
        </div>
      </div>
      <table className={styles.auctionTable}>
        <thead>
          <tr>
            <th onClick={() => requestSort('id')}>ID</th>
            <th onClick={() => requestSort('product_id')}>Product ID</th>
            <th>Product Name</th>
            <th onClick={() => requestSort('start_time')}>Start Time</th>
            <th onClick={() => requestSort('countdown_timer')}>Countdown Timer</th>
            <th onClick={() => requestSort('status')}>Status</th>
            <th onClick={() => requestSort('current_bid')}>Current Bid</th>
            <th onClick={() => requestSort('umin')}>Umin</th>
            <th onClick={() => requestSort('bmax')}>Bmax</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {sortedAuctions.map(auction => (
            <tr key={auction.id}>
              <td>{auction.id}</td>
              <td>{auction.product_id}</td>
              <td>{getProductName(auction.product_id)}</td>
              <td>{new Date(auction.start_time).toLocaleString()}</td>
              <td>{auction.countdown_timer} seconds</td>
              <td>{auction.status}</td>
              <td>£{auction.current_bid / 100}</td>
              <td>£{auction.umin / 100}</td>
              <td>£{auction.bmax / 100}</td>
              <td>
                <button onClick={() => onEdit(auction)} className={styles.editButton}>Edit</button>
                <button onClick={() => handleDelete(auction.id)} className={styles.deleteButton}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AuctionList;
