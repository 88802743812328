// src/services/api.ts
import axios, { AxiosResponse } from 'axios';
import {
  User,
  Product,
  Auction,
  Bid,
  Invoice,
  Notification,
  Address,
  Review,
  GovernorData,
  PublicAuctionData,
  ProductCategory,
  Media
} from '../../../types';

const API_URL = process.env.REACT_APP_API_URL|| 'http://localhost:4000'; // Replace with your backend URL

export const getMedia = async (entity_type: string, entity_id: number): Promise<AxiosResponse<Media[]>> => {
  return axios.get(`${API_URL}/core/media/${entity_type}/${entity_id}`);
};

export const getAuctionVerificationData = async (): Promise<AxiosResponse<{
  nextVerifyUids: Record<number, number>;
  verifyTimes: Record<number, number>;
  subsetSizes: Record<number, number>;
}>> => {
  return axios.get(`${API_URL}/auctions/verify`);
};

export const getAuctions = async (): Promise<AxiosResponse<Auction[]>> => {
  return axios.get(`${API_URL}/core/auctions`);
};

//emits a sync time event across all clients 
export const syncAuctionTimes = async (): Promise<any> => {
  try {
    const response = await axios.get(`${API_URL}/auctions/sync`);
    return response; // Return the data from the API response
  } catch (error) {
    console.error('Error synchronizing auction times:', error);
    throw error; // Propagate the error for handling in the component
  }
};
//verifies the time event with server boba
export const verifyAuction = async (id: number,uid:number): Promise<any> => {
  try {
    const response = await axios.get(`${API_URL}/auctions/verify/${id}/${uid}`);
    return response; // Return the data from the API response
  } catch (error) {
    console.error('Error synchronizing auction times:', error);
    throw error; // Propagate the error for handling in the component
  }
};

export const createBid = async (token:string, bidData: Omit<Bid, 'id'|'user_id'|'type'>): Promise<AxiosResponse<Bid>> => {
  return axios.post(`${API_URL}/auctions/bid`, bidData,{headers: {
    Authorization: `Bearer ${token}`
  }});
};



export const startAuction = async (id: number): Promise<AxiosResponse<Auction>> => {
  return axios.put(`${API_URL}/auctions/start/${id}`);
};
export const completeAuction = async (id: number): Promise<AxiosResponse<Auction[]>> => {
  return axios.put(`${API_URL}/auctions/completed/${id}`);
};


// Fetch all products
export const getProducts = async (): Promise<AxiosResponse<Product[]>> => {
  return axios.get(`${API_URL}/core/products`);
};

// Fetch all reviews without auth
export const getReviews = async () => {
  return axios.get(`${API_URL}/core/reviews`);
};

export const getBids = async () => {
  return axios.get(`${API_URL}/core/bids`);
};

export const getWinners = async () => {
  return axios.get(`${API_URL}/core/winners`);
};

export const postContact = async (name:string,email:string, subject:string,message:string):Promise<any> => {
  return axios.post(`${API_URL}/core/contact`, {name,email,subject,message});
};

export const getCompletedAuctions = async (): Promise<AxiosResponse<Auction[]>> => {
  return axios.get(`${API_URL}/core/completed-auctions`);
};

export const getLastBidders = async (): Promise<AxiosResponse<any>> => {
  return axios.get(`${API_URL}/core/last-bidders`);
};


// Add this new interface
// Add this new function
export const getGovernorData = async (): Promise<AxiosResponse<GovernorData>> => {
  return axios.get(`${API_URL}/auctions/governor`);
};

// Add these new API functions using the existing ProductCategory type
export const getCategories = async (): Promise<AxiosResponse<(ProductCategory & {
    item_count: number;
    active_auctions: number;
    upcoming_auctions: number;
    completed_auctions: number;
})[]>> => {
    return axios.get(`${API_URL}/core/categories`);
};

