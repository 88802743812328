import React, { useState } from 'react';
import { useAuctionState } from '../../components/auctionStateContext';
import { Link } from 'react-router-dom';
import { Calendar, Grid, List, Clock, PoundSterlingIcon } from 'lucide-react';
import styles from './upcoming.module.css';
import Loading from '../../components/loading';
import AuctionGrid from '../components/AuctionGrid';
import Banner from '../../components/Banner';
import Pagination from '../../components/pagination';
import { dir } from 'console';


interface BannerSlide {
  image: string;
  title: string;
  description: string;
  link: string;
}


const UpcomingPage: React.FC = () => {  
  const { auctions, products, lastBidders, isLoading, getCountdown, handleBid, getProductImage, timers, prevBidders } = useAuctionState();
  const [viewType, setViewType] = useState<'grid' | 'list'>('grid');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  // Filter and sort upcoming auctions by start time
  const upcomingAuctions = auctions
    .filter(auction => new Date(auction.start_time) > new Date())
    .sort((a, b) => new Date(a.start_time).getTime() - new Date(b.start_time).getTime());

  const productMap = new Map(products.map(product => [product.id, product]));

  // Calculate pagination
  const totalFilteredItems = upcomingAuctions.length;
  const paginatedAuctions = upcomingAuctions.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  if (isLoading) return <Loading message="Loading upcoming auctions..." subMessage="Fetching auction data" />;
  const createBannerSlides = (): BannerSlide[] => {
    // Filter and sort upcoming auctions by start time
    const sortedUpcomingAuctions = upcomingAuctions
      .sort((a, b) => new Date(a.start_time).getTime() - new Date(b.start_time).getTime())
      .slice(0, 3); // Select the first 3 upcoming auctions

    return sortedUpcomingAuctions.map(auction => {
      const product = productMap.get(auction.product_id);
      if (!product) return null;
      const startTime = new Date(auction.start_time);
      
      return {
        image: getProductImage(product),
        title: product.title,
        description: `Starting Bid: $${(auction.current_bid/100).toFixed(2)} • Starts: ${formatDate(startTime)} at ${formatSTime(startTime)}`,
        link: `/auction/${auction.id}`
      };
    }).filter((slide): slide is BannerSlide => slide !== null);
  };
  const formatSTime = (date: Date): string => {
    return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
};

const formatDate = (date: Date): string => {
    return date.toLocaleDateString('en-UK', { month: 'short', day: 'numeric', year: 'numeric' });
};
  return (
    <div className={styles.container}>
      <Banner slides={createBannerSlides()} imageFit='contain' logo='/uploads/logo-circle.png'/>
      <div className={styles.upcomingAuctions}>
      <h2 className={styles.sectionTitle}>Upcoming Auctions</h2>
      <div className={styles.viewToggle}>
        <button className={styles.viewToggleButton} onClick={() => setViewType('grid')}><Grid /></button>
        <button className={styles.viewToggleButton} onClick={() => setViewType('list')}><List /></button>
      </div>
      {viewType === 'grid' ? (
        <AuctionGrid 
          auctions={paginatedAuctions} 
          products={products} 
          timers={timers} 
          prevBidders={prevBidders} 
          handleBid={handleBid}
          getCountdown={getCountdown}
          getProductImage={getProductImage}
          showAuctionStatus={false}
          lastBidders={lastBidders}
        />
      
      ) : (
        <ul className={styles.listView}>
          {paginatedAuctions.map(auction => {
            const product = productMap.get(auction.product_id);
            return (
              <li key={auction.id} className={styles.listItem}>
                <Link to={`/auction/${auction.id}`} className={styles.listItemLink}>
                  <div style={watermarkStyle}>
                    <img 
                      className={styles.listItemImage} 
                      src={getProductImage(product!)} 
                      alt={product?.title} 
                    />
                    <img 
                      src="/uploads/logo-circle.png" 
                      alt="Watermark" 
                      style={watermarkImageStyle} 
                    />
                  </div>
                  <div className={styles.listItemDetails}>
                    <h3 className={styles.listItemTitle}>{product?.title}</h3>
                    <p className={styles.auctionPrice}>£{(auction.current_bid/100).toFixed(2) || 0}</p>
                    <p className={styles.auctionDate}>{formatDate(new Date(auction.start_time))} {formatSTime(new Date(auction.start_time))}</p>
                    <p className={styles.auctionCountdown}>{getCountdown(auction.start_time)}</p>
                  </div>
                </Link>
              </li>
            );
          })}
        </ul>
      )}
      <Pagination
        currentPage={currentPage}
        totalItems={totalFilteredItems}
        pageSize={pageSize}
        onPageChange={setCurrentPage}
        onPageSizeChange={setPageSize}
        pageSizeOptions={[10, 20, 50]}
        scrollToTop={true}
        containerRef={`.${styles.gridContainer}`}
      />
    </div>
    </div>
  );
};

// Define watermark styles
const watermarkStyle: React.CSSProperties = {
  position: 'relative',
  display: 'block',
};

const watermarkImageStyle: React.CSSProperties = {
  position: 'absolute',
  top: '0px',
  right: '0px',
  width: '16%',
  height: '15%',
  opacity: 1,
  zIndex: 1,
};

export default UpcomingPage;
