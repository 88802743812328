// src/pages/IndexPage.tsx

import React, { useEffect, useState } from "react";
import { useAuctionState } from "../components/auctionStateContext";
import Banner from "../components/Banner";
import FilteredGrid from "../auction/components/FilteredGrid";
import Loading from "../components/loading";
import { Link } from "react-router-dom";
import { getBids, getCompletedAuctions, getProducts } from "../utils/coreAPI";
import { Product, Auction } from "../../../types";
import "../styles/shared.css"; // Import the mobile CSS for styling
import { color } from "framer-motion";

const fadeInStyle: React.CSSProperties = {
  animation: "fadeIn 0.5s ease-in-out",
};

const fadeInKeyframes = `
    @keyframes fadeIn {
        from { opacity: 0; transform: translateY(20px); }
        to { opacity: 1; transform: translateY(0); }
    }
`;

const styleSheet = document.createElement("style");
styleSheet.textContent = fadeInKeyframes;
document.head.appendChild(styleSheet);

const watermarkStyle: React.CSSProperties = {
  position: 'relative',
  display: 'block',
  width: '70%',
  margin: '0 auto',
};

const watermarkImageStyle: React.CSSProperties = {
  position: 'absolute',
  top: '0px',
  right: '0px',
  width: '16%',
  height: '15%',
  opacity: 1,
  zIndex: 1,
};

const IndexPage: React.FC = () => {
  const {
    auctions,
    products,
    bids,
    timers,
    prevBidders,
    lastBidders,
    categories,
    isLoading,
    handleBid,
    getCountdown,
    getProductImage,
  } = useAuctionState();
  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = 12;
  const bannerSlides = [
    {
      image: `${
        process.env.REACT_APP_API_URL?.replace("/api", "") ||
        "http://127.0.0.1:4000"
      }/uploads/banner.jpg`,
      title: "Welcome to USHOP.BID",
      description: "Discover unique items and amazing deals",
      link: "/faq",
    },
    {
      image: `${
        process.env.REACT_APP_API_URL?.replace("/api", "") ||
        "http://127.0.0.1:4000"
      }/uploads/ushop_home.jpg`,
      title: "Explore Our Collections",
      description: "Find rare and exclusive items in our auctions",
      link: "/categories",
    },
    {
      image: `${
        process.env.REACT_APP_API_URL?.replace("/api", "") ||
        "http://127.0.0.1:4000"
      }/uploads/ushop_gadgets.jpg`,
      title: "Shop the latest tech",
      description: "Get amazing deals on phones, laptops, tablets and more",
      link: "/market",
    },
  ];

  const activeAuctions = auctions.filter(
    (auction) => auction.status !== "completed"
  );

  const [stats, setStats] = useState<any>({
    totalDonated: 0,
    projectsSupported: 0,
    activeInitiatives: 1,
    totalPledged: 0,
  });

  const [topProducts, setTopProducts] = useState<Product[]>([]);
  const [recentWinners, setRecentWinners] = useState<Auction[]>([]);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const completedResponse = await getCompletedAuctions();
        const totalDonated =
          completedResponse.data.reduce(
            (sum, auction) => sum + auction.current_bid,
            0
          ) / 100;

        const bidsResponse = await getBids();
        const totalPledged = bidsResponse.data.length / 200;

        setStats({
          totalDonated,
          projectsSupported: totalPledged,
          activeInitiatives: 1,
          totalPledged: totalPledged, // or fetch this dynamically if needed
        });
      } catch (error) {
        console.error("Failed to fetch stats:", error);
      }
    };

    const fetchTopProducts = async () => {
      try {
        const productsRes = await getProducts();
        const sortedProducts = productsRes.data.sort((a, b) => b.rrp - a.rrp);

        // Find product 37
        const product37 = sortedProducts.find((product) => product.id === 37);

        // Remove product 37 from the sorted list if it exists
        const filteredProducts = sortedProducts.filter(
          (product) => product.id !== 37
        );

        // Insert product 37 in the middle of the top 3 products
        const topProductsWith37 = [
          filteredProducts[0],
          product37,
          filteredProducts[1],
        ] as Product[]; // Type assertion to Product[]

        setTopProducts(topProductsWith37);
      } catch (error) {
        console.error("Failed to fetch top products:", error);
      }
    };

    const fetchRecentWinners = () => {
      const completedAuctions = auctions.filter(
        (auction) => auction.status === "completed"
      );
      const sortedAuctions = completedAuctions.sort(
        (a, b) =>
          new Date(b.start_time).getTime() - new Date(a.start_time).getTime()
      );
      setRecentWinners(sortedAuctions.slice(0, 3));
    };

    fetchStats();
    fetchTopProducts();
    fetchRecentWinners();
  }, [auctions]);

  // Calculate unique bidders for each auction
  const uniqueBiddersCount = (auctionId: number) => {
    const bidders = bids.filter((bid) => bid.auction_id === auctionId);
    const uniqueBidders = new Set(bidders.map((bid) => bid.user_id));
    return uniqueBidders.size;
  };

  return (
    <div className="container fadeIn">
                    <Banner slides={bannerSlides} logo="/uploads/logo-circle.png"/>

      {isLoading ? (
        <Loading
          message="Loading auctions..."
          subMessage="Fetching latest auction data"
        />
      ) : (
        <>
          <div className="container">

            <FilteredGrid
              auctions={activeAuctions}
              products={products}
              timers={timers}
              prevBidders={prevBidders}
              handleBid={handleBid}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              onPageChange={setCurrentPage}
              totalItems={activeAuctions.length}
              categories={categories}
              getCountdown={getCountdown}
              getProductImage={getProductImage}
              lastBidders={lastBidders}
            />
            <div className="section">
              <h2 className="sectionTitle">Recent Winners</h2>
              <div className="dynamicGrid">
                {recentWinners.map((auction) => {
                  const product = products.find(
                    (p) => p.id === auction.product_id
                  );
                  const lastBid = lastBidders.get(auction.id)?.[0];
                  return (
                    <Link
                      to={`/auction/${auction.id}`}
                      key={auction.id}
                      className="gridItem"
                    >
                      <div style={watermarkStyle}>
                        <img
                          src={getProductImage(product!)}
                          alt={product?.title}
                          className="winnerImage"
                        />
                        <img 
                          src="/uploads/logo-circle.png" 
                          alt="Watermark" 
                          style={watermarkImageStyle}
                        />
                      </div>
                      <div className="infoDetails">
                        <h3 className="infoTitle">{product?.title}</h3>
                        <div className="Info">
                          <span className="infoLabel">Final Bid:</span>
                          <span className="infoValue">
                            £{(auction.current_bid / 100).toFixed(2)}
                          </span>
                        </div>
                        <div className="Info">
                          <span className="infoLabel">Winner:</span>
                          <span className="infoValue">{lastBid?.username}</span>
                        </div>
                        <div className="Info">
                          <span className="infoLabel">Unique Bidders:</span>
                          <span className="infoValue">
                            {uniqueBiddersCount(auction.id)}
                          </span>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
              <Link to="/winners" className="animatedButton">
                See More Winners
              </Link>
            </div>
            <div className="section">
              <h2 className="sectionTitle">Top Products</h2>
              <div className="dynamicGrid">
                {topProducts.map((product) => {
                  const category = categories.find(
                    (c) => c.id === product.category_id
                  );
                  return (
                    <Link
                      to={`/market/${product.id}`}
                      key={product.id}
                      className="gridItem"
                    >
                      <div style={watermarkStyle}>
                        <img
                          src={getProductImage(product)}
                          alt={product.title}
                          className="productImage"
                        />
                        <img 
                          src="/uploads/logo-circle.png" 
                          alt="Watermark" 
                          style={watermarkImageStyle}
                        />
                      </div>
                      <div className="infoDetails">
                        <h3 className="infoTitle">{product.title}</h3>
                        <div className="Info">
                          <span className="infoLabel">RRP:</span>
                          <span className="infoValue">£{product.rrp}</span>
                        </div>
                        <div className="Info">
                          <span className="infoLabel">P&P:</span>
                          <span className="infoValue">
                            £{(product.postage / 100).toFixed(2)}
                          </span>
                        </div>
                        <div className="Info">
                          <span className="infoLabel">Category:</span>
                          <span className="infoValue">
                            {category?.name || "Unknown"}
                          </span>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
              <Link to="/market" className="animatedButton">
                See Market
              </Link>
            </div>

            <div className="section">
              <h2 className="sectionTitle">Total Donations from Auctions</h2>
              <br />
              <div
                style={{
                  width: "100%",
                  backgroundColor: "var(--light-gray)",
                  borderRadius: "20px",
                  padding: "3px",
                  marginBottom: "1rem",
                  border: "2.5px red solid",
                }}
              >
                <div
                  style={{
                    width: `${(stats.totalPledged / 5000) * 100}%`, // Calculate percentage of goal reached
                    backgroundColor: "var(--primary-red)",
                    height: "25px",
                    borderRadius: "20px",
                    transition: "width 1s ease-in-out",
                    position: "relative",
                  }}
                />
              </div>

              <div
                style={{
                  fontSize: "1.5rem",
                  fontWeight: 600,
                  color: "var(--primary-red)",
                }}
              >
                £{stats.totalPledged.toFixed(2)}
              </div>
              <div
                style={{
                  fontSize: "1rem",
                  color: "var(--dark-gray)",
                  marginTop: "0.5rem",
                }}
              >
                Help us reach our goal of £5,000
              </div>
              <br />
              <Link to="/charity" className="animatedButton">
                Learn more about our charity efforts
              </Link>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default IndexPage;
