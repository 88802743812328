import React, { useEffect, useState } from 'react';
import { Auction, Product } from '../../../../types';
import { Clock, User, Eye, ChevronDown } from 'lucide-react';
import styles from './AuctionGrid.module.css';
import { io } from 'socket.io-client';
import { startAuction } from '../../utils/coreAPI';
import { Link } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
const socketUrl = apiUrl.endsWith('/api') ? apiUrl.slice(0, -4) : apiUrl; // Remove '/api' if it exists
const socket = io(socketUrl);

interface AuctionGridProps {
    auctions: Auction[];
    products: Product[];
    timers: Map<number, number>;
    prevBidders: Map<number, { username: string; uid: number }>;
    handleBid: (auctionId: number) => Promise<void> | undefined;
    showAuctionStatus?: boolean;
    showCountdown?: boolean;
    getCountdown: (startTime: string) => string;
    getProductImage: (product: Product) => string | undefined;
    lastBidders: Map<number, { username: string; bid_time: string }[]>;
}

const AuctionGrid: React.FC<AuctionGridProps> = ({ 
    auctions, 
    products, 
    timers, 
    prevBidders, 
    handleBid,
    getCountdown,
    showAuctionStatus = false,
    showCountdown = true,
    getProductImage,
    lastBidders
}) => {
    const formatTime = (seconds: number): string => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        const timeUnits = [];
        if (hours > 0) timeUnits.push(`${hours}h`);
        if (minutes > 0) timeUnits.push(`${minutes}m`);
        if (remainingSeconds > 0 || timeUnits.length === 0) timeUnits.push(`${remainingSeconds}s`);

        return timeUnits.join(' ');
    };

    const formatSTime = (date: Date): string => {
        return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    };

    const formatDate = (date: Date): string => {
        return date.toLocaleDateString('en-UK', { month: 'short', day: 'numeric', year: 'numeric' });
    };


    const productMap = new Map(products.map(product => [product.id, product]));

    const [activeViewers, setActiveViewers] = useState<Record<string, number>>({});
    const [loadingBids, setLoadingBids] = useState<Map<number, boolean>>(new Map());

    useEffect(() => {
        socket.on('viewerUpdate', (data: { auctionId: string; activeViewers: number }) => {
            setActiveViewers(prev => ({
                ...prev,
                [data.auctionId]: data.activeViewers
            }));
        });

        return () => {
            socket.off('viewerUpdate');
        };
    }, []);

    useEffect(() => {
        const currentTime = new Date().getTime();
        auctions.forEach(async (auction) => {
          const auctionStartTime = new Date(auction.start_time).getTime();
          const elapsed = Math.floor((currentTime - auctionStartTime) / 1000);
          if (elapsed >= 0 && auction.status === 'upcoming') {
            await startAuction(auction.id);
         
            socket.emit('auctionUpdate', { auction: auction });
            
            // Refresh the page when a new auction goes active
          }
        });
      }, [auctions]);

    const handleBidClick = async (auctionId: number, event: React.MouseEvent<HTMLButtonElement>) => {
        const button = event.currentTarget;
        button.classList.add(styles.bidButtonActive);
        setLoadingBids(prev => new Map(prev).set(auctionId, true));

        try {
            await handleBid(auctionId);
        } finally {
            setLoadingBids(prev => new Map(prev).set(auctionId, false));
            button.classList.remove(styles.bidButtonActive);
        }
    };

    return (
        <div className={styles.auctionGrid}>
            {auctions.map((auction) => {
                const product = productMap.get(auction.product_id);
                const remainingTime = timers.get(auction.id) || 0;
                const totalCountdownTime = auction.countdown_timer; // Total countdown time
                const isActive = auction.status === 'active';

                const borderWidth = isActive && auction.current_bid === 0 
                    ? 100 // Full width when active but no bids
                    : (remainingTime / totalCountdownTime) * 100; // Normal calculation otherwise
                const isCompleted = auction.status === 'completed';
                const isEndingSoon = remainingTime <= 900;
                const prevBidder = lastBidders.get(auction.id) || prevBidders.get(auction.id);
                const link = `/auction/${auction.id}`;
 
                if (!product) return null;

                return (
                    <div key={auction.id} className={`${styles.auctionCard} ${isEndingSoon ? styles.endingSoon : ''}`}>
                     
                        {showAuctionStatus && (
                            
                            <div className={`${styles.marketAuctionBadge} ${
                                isActive ? styles.badgeActive : 
                                isCompleted ? styles.badgeCompleted : 
                                styles.badgeUpcoming
                            }`}>
                                {isActive ? '🔴 Live Auction' : 
                                 isCompleted ? '✓ Auction Ended' : 
                                 '⏰ Auction Soon'}
                             
                            </div>
                        )}
                        {prevBidder && (
                            <div 
                                key={`${Array.isArray(prevBidder) ? prevBidder[0].username : prevBidder.username}-${auction.current_bid}`}
                                className={styles.previousBidder}
                            >
                                <User size={16} />
                                <span>{Array.isArray(prevBidder) ? prevBidder[0].username : prevBidder.username}</span>
                            </div>
                        )}
                        <Link to={link}>
                            {product && (
                                <div className={styles.imageContainer} style={{ position: 'relative' }}>
                                    <div 
                                        style={{
                                            position: 'absolute',
                                            bottom: 0,
                                            left: 0, // Start from the left side
                                            width: `${borderWidth}%`, // Set width based on remaining time
                                            height: '3px', // Set height for the border
                                            backgroundColor: 'var(--primary-red)', // Use primary red for the border
                                            zIndex: 1, // Ensure it is above the image but below the watermark
                                        }}
                                    />
                                    <div style={watermarkStyle}>
                                        <img 
                                            src={getProductImage(product)} 
                                            alt={product.title} 
                                            className={styles.auctionImage}
                                        />
                                        <img 
                                            src="/uploads/logo-circle.png" 
                                            alt="Watermark" 
                                            style={watermarkImageStyle}
                                        />
                                    </div>
                                    <div className={styles.timerContainer}>
                                        <Clock className={styles.timerIcon} />
                                        <span className={styles.auctionCountdownRed}>
                                            {`${auction.countdown_timer}s`}
                                        </span>
                                    </div>
                                    <div className={`${styles.auctionStatus} ${
                                        isActive ? styles.statusActive : 
                                        isCompleted ? styles.statusCompleted : 
                                        styles.statusUpcoming
                                    }`}>
                                        {isActive ? 'Active' : isCompleted ? 'Completed' : 'Upcoming'}
                                    </div>
                                </div>
                            )}
                        </Link>
                        <div className={styles.auctionDetails}>
                            <h3>{product.title}</h3>
                            <p className={styles.auctionPrice}>£{(auction.current_bid/100).toFixed(2) || 0}</p>
                            <div className={styles.auctionTimer}>
                                <Clock size={24} />
                                {isActive ? (
                                    auction.current_bid > 0
                                        ? formatTime(remainingTime)
                                        : formatTime(auction.countdown_timer)
                                ) : (
                                    `BID IN ${getCountdown(auction.start_time)}`
                                )}
                            </div>
                            {isActive ? (
                                <button
                                    className={styles.bidButton}
                                    onClick={(event) => handleBidClick(auction.id, event)}
                                    disabled={loadingBids.get(auction.id)}
                                >
                                    {loadingBids.get(auction.id) ? 'Placing Bid...' : 'Place Bid Now'}
                                </button>
                            ) : (
                                <button className={styles.bidButton} disabled>
                                 {formatDate(new Date(auction.start_time))}  {formatSTime(new Date(auction.start_time))}
                                </button>
                            )}
                        </div>
                        <div className={styles.auctionInfo}>
                        {/* <span>
                                <Eye size={16} /> 
                                {activeViewers[auction.id] || Math.floor(Math.random() * 26) + 25}
                            </span> */}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

const watermarkStyle: React.CSSProperties = {
    position: 'relative',
    display: 'block',
  };
  
  const watermarkImageStyle: React.CSSProperties = {
    position: 'absolute',
    top: '0px',
    right: '0px',
    width: '16%',
    height: '16%',
    opacity: 1,
    zIndex: 1,
  };
export default AuctionGrid;