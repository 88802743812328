import React from 'react';
import Banner from '../components/Banner';

interface BannerSlide {
  image: string;
  title: string;
  description: string;
  link: string;
  openInNewTab: boolean;
}

const aboutBannerSlides: BannerSlide[] = [
  {
    image: `${process.env.REACT_APP_API_URL?.replace('/api', '') || 'http://127.0.0.1:4000'}/uploads/banner1.jpg`,
    title: 'Welcome to USHOP.BID',
    description: 'Your Premier Online Auction Marketplace',
    link: '#our-story',
    openInNewTab: false,
  },
  {
    image: `${process.env.REACT_APP_API_URL?.replace('/api', '') || 'http://127.0.0.1:4000'}/uploads/about_ushop_savings.jpg`,
    title: 'Incredible Savings',
    description: 'Up to 80% Off Retail Prices',
    link: '#why-choose-us',
    openInNewTab: false,
  },
  {
    image: `${process.env.REACT_APP_API_URL?.replace('/api', '') || 'http://127.0.0.1:4000'}/uploads/about-ushop-community.jpg`,
    title: 'Join Our Community',
    description: 'Experience the Future of Online Shopping',
    link: '#join-us',
    openInNewTab: false,
  }
];


const AboutUs: React.FC = () => {
  return (
    <div className='container' style={styles.container}>
      <Banner slides={aboutBannerSlides} autoPlayInterval={6000} logo='/uploads/logo-circle.png' />
      
      <section id="our-story"  className='section' >
        <h2 className='sectionTitle'>Our Story</h2>
        <div style={styles.content}>
          <p>Founded in 2024, USHOP.BID emerged from a vision to revolutionize the online shopping experience. We recognized that traditional retail often meant paying premium prices, so we created an innovative auction marketplace that guarantees savings of up to 80% off retail prices.</p>
          <p>Based in London, our platform combines the excitement of competitive bidding with the reliability of a trusted retailer, creating a unique shopping experience where customers can save up to 80% off retail prices.</p>
        </div>
      </section>

      <section id="why-choose-us" className='section' >
        <h2 className='sectionTitle'>Why Choose USHOP.BID</h2>
        <div style={styles.gridContainer}>
          <div style={styles.gridItem}>
            <h3 style={styles.featureTitle}>Guaranteed Savings</h3>
            <p>Save up to 80% off retail prices on every purchase through our innovative auction system.</p>
          </div>
          <div style={styles.gridItem}>
            <h3 style={styles.featureTitle}>Secure Platform</h3>
            <p>Shop with confidence knowing that all transactions are protected by state-of-the-art security measures.</p>
          </div>
          <div style={styles.gridItem}>
            <h3 style={styles.featureTitle}>Direct Sourcing</h3>
            <p>All products are carefully sourced and shipped directly by USHOP.BID, ensuring quality and authenticity.</p>
          </div>
          <div style={styles.gridItem}>
            <h3 style={styles.featureTitle}>24/7 Support</h3>
            <p>Our dedicated customer service team is always ready to assist you with any queries.</p>
          </div>
        </div>
      </section>

      <section id="join-us" className='section' >
        <h2 className='sectionTitle'>Join Our Community</h2>
        <div style={styles.content}>
          <p>At USHOP.BID, we're building more than just a marketplace - we're creating a community of smart shoppers who love great deals. Join thousands of satisfied customers who are already saving up to 80% on their purchases through our innovative auction platform.</p>
          <div style={styles.ctaContainer}>
            <button 
              onClick={() => window.location.href = '/register'} 
              style={styles.ctaButton}
              className='animatedButton'
            >
              Start Shopping
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: '#ffffff',
  } as React.CSSProperties,
  section: {
    padding: '2rem 5rem',
    margin: '1rem auto',
    maxWidth: '1200px',
  } as React.CSSProperties,
  sectionTitle: {
    fontSize: '1.75rem',
    fontWeight: 800,
    color: 'var(--primary-red)',
    textAlign: 'center',
    marginBottom: '2rem',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
    fontFamily: "'Orbitron', sans-serif",
  } as React.CSSProperties,
  content: {
    fontSize: '1.1rem',
    lineHeight: '1.8',
    color: '#4a5568',
  } as React.CSSProperties,
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    gap: '2rem',
    margin: '2rem 0',
  } as React.CSSProperties,
  gridItem: {
    padding: '2rem',
    backgroundColor: '#f7fafc',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'translateY(-5px)',
    },
  } as React.CSSProperties,
  featureTitle: {
    fontSize: '1.25rem',
    fontWeight: 600,
    color: '#2d3748',
    marginBottom: '1rem',
  } as React.CSSProperties,
  ctaContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
    marginTop: '2rem',
  } as React.CSSProperties,
  ctaButton: {
    backgroundColor: '#e53e3e',
    color: '#ffffff',
    border: 'none',
    padding: '12px 24px',
    borderRadius: '4px',
    fontSize: '1rem',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: '#c53030',
    },
  } as React.CSSProperties,
  ctaButtonOutline: {
    backgroundColor: 'transparent',
    color: '#e53e3e',
    border: '2px solid #e53e3e',
    padding: '12px 24px',
    borderRadius: '4px',
    fontSize: '1rem',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundColor: '#e53e3e',
      color: '#ffffff',
    },
  } as React.CSSProperties,
};

export default AboutUs; 