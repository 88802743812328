// src/services/api.ts
import axios, { AxiosResponse } from 'axios';
import {
    User,
    Product,
    Auction,
    Bid,
    Invoice,
    Notification,
    Address,
    Review,
    ApiResponse
} from '../../../types';

const API_URL = process.env.REACT_APP_API_URL|| 'http://localhost:4000'; // Replace with your backend URL


export interface LoginResponse {
    token: string;
    user: {
        type: number;
        id: number;
        email: string;
    };
}
interface RegisterResponse {
    id: number;
    username: string;
    first_name: string;
    last_name: string;
    email: string;
    phone?: string;
    address: Address;
    credits: number;
    reg_date?: string;
    last_login?: string;
    is_active: boolean;
    type?: number;
}
interface RegisterResponse {
    user: User;
    address: Address;
}

export const checkUserFields = async (fields: { username?: string; email?: string; phone?: string }) => {
    try {
      const response = await axios.post(`${API_URL}/auth/check-user`, fields);
      return response.data;
    } catch (error) {
      throw error;
    }
  };


export const getUserNotifs = async (token: string): Promise<Notification[]> => {
    const response = await axios.get(
        `${API_URL}/account/notifications`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }
    );
    // Assuming the backend filters invoices where credits is not null
    return response.data;
};

export const readNotification = async (token: string, notificationId: number): Promise<Notification> => {
    try {
        const response = await axios.put(
            `${API_URL}/account/notifications/`,
            { notificationId: notificationId },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        return response.data;
    } catch (error) {
        console.error('Error marking notification as read:', error);
        throw error;
    }
};


export const deleteNotification = async (token: string, notificationId: number): Promise<Notification> => {
    try {
        const response = await axios.post(
            `${API_URL}/account/notifications/`,
            { notificationId: notificationId },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        return response.data;
    } catch (error) {
        console.error('Error marking notification as read:', error);
        throw error;
    }
};


export const loginUser = async (email: string, password: string): Promise<AxiosResponse<LoginResponse>> => {
    return axios.post(`${API_URL}/auth/login`, { email, password });
};

export const registerUser = async (
    user: Omit<User, 'id' | 'is_active' | 'credits' | 'reg_date'> & { referrer_username?: string },
    address: Omit<Address, 'id'>,
    recaptcha_token: string
): Promise<AxiosResponse<RegisterResponse>> => {
    return axios.post(`${API_URL}/auth/register`, {
        user,
        address,
        recaptcha_token
    });
};

 export interface ReferralResponse extends User {
  referrer_username?: string;
  referrer_first_name?: string;
  referrer_last_name?: string;
  referrals: {
    id: number;
    username: string;
    first_name: string;
    last_name: string;
    reg_date: string;
  }[];
}

export const getAccountDetails = async (token: string): Promise<AxiosResponse<ReferralResponse>> => {
  return axios.get(`${API_URL}/account`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const updateAccountDetails = async (token: string, userData: Partial<User>): Promise<AxiosResponse<User>> => {
    return axios.put(`${API_URL}/account`, userData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};

export const getAddressDetails = async (token: string): Promise<AxiosResponse<Address[]>> => {
    return axios.get(`${API_URL}/account/addresses`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};

export const updateAddressDetails = async (token: string, address: Partial<Address>): Promise<AxiosResponse<Address>> => {
    return axios.put(`${API_URL}/account/addresses`, address, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};

export const getUserInvoices = async (token: string): Promise<Invoice[]> => {
    const response = await axios.get(
        `${API_URL}/account/invoices`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }
    );
    // Assuming the backend filters invoices where credits is not null
    return response.data;
};


export const getUserBiddingHistory = async (token: string): Promise<any[]> => {
    const response = await axios.get(
        `${API_URL}/account/bids`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }
    );
    // Assuming the backend filters invoices where credits is not null
    return response.data;
};


export const addCredits = async (token: string, credits: number): Promise<AxiosResponse<User>> => {
    return axios.post(`${API_URL}/account`, { 'credits': credits }, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};

export const submitReview = async (token: string, reviewData: Partial<Review>,entityType: string,entityId: number): Promise<Review> => {
    const response = await axios.post(`${API_URL}/account/review/${entityType}/${entityId}`, reviewData, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
};



export const sendOTP = async (contact: string, type: 'email' | 'phone'): Promise<boolean> => {
    try {
        const { data } = await axios.post(`${API_URL}/auth/send-otp`, {
            contact,
            type,
        });
        return data; // Assuming the API response has a 'success' field
    } catch (error) {
        console.error('Error sending OTP:', error);
        return false; // Return false if there was an error
    }
};

export const verifyOTP = async (contact: string, otp: string, type: 'email' | 'phone'): Promise<boolean> => {


    const { data } = await axios.post(`${API_URL}/auth/verify-otp`, {
        contact,
        otp,
        type,
    });
    return data.verified;

};




// Add these new functions to the existing file

export const sendPasswordResetEmail = async (email: string): Promise<AxiosResponse<any>> => {
    return axios.post(`${API_URL}/auth/forgot-password`, { email });
};

export const resetPassword = async (token: string, password: string): Promise<AxiosResponse<any>> => {
    return axios.post(`${API_URL}/auth/reset-password`, { token, password });
};

export const deleteUser = async (token: string): Promise<AxiosResponse<ApiResponse<null>>> => {
    return axios.delete(`${API_URL}/account`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};

export const claimOffer = async (offerId: string) => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No authentication token found');
  }

  try {
    const response = await axios.post(
      `${API_URL}/offers/claim`,
      { offerId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error claiming offer:', error);
    throw error;
  }
};

// Add these new functions for push notification

export const saveSubscription = async (subscription: PushSubscription): Promise<void> => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No authentication token found');
  }

  try {
    const response = await axios.post(
      `${API_URL}/account/save-subscription`,
      { subscription },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    console.log('Subscription save response:', response.data);
  } catch (error) {
    console.error('Error saving subscription:', error);
    throw error;
  }
};

export const getPushSubscription = async (): Promise<PushSubscription | null> => {
  if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
    console.log('Push notifications are not supported');
    return null;
  }

  try {
    const registration = await navigator.serviceWorker.ready;
    let subscription = await registration.pushManager.getSubscription();

    if (!subscription) {
      const vapidPublicKey = process.env.REACT_APP_VAPID_PUBLIC_KEY;
      if (!vapidPublicKey) {
        throw new Error('VAPID public key is not set');
      }

      subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(vapidPublicKey),
      });

      await saveSubscription(subscription);
    }

    return subscription;
  } catch (error) {
    console.error('Error getting push subscription:', error);
    return null;
  }
};

// Helper function to convert base64 to Uint8Array
function urlBase64ToUint8Array(base64String: string): Uint8Array {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export const sendPushNotificationToAllDevices = async (notificationData: {
  title: string;
  body: string;
  icon?: string;
  badge?: string;
  data?: any;
  tag?: string;
}): Promise<any> => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No authentication token found');
  }

  try {
    const response = await axios.post(
      `${API_URL}/account/send-notification-all-devices`,
      notificationData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error in sendPushNotificationToAllDevices:', error);
    throw error;
  }
};



export const removeSubscription = async (subscription: PushSubscription): Promise<void> => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No authentication token found');
  }

  try {
    await axios.post(
      `${API_URL}/account/remove-subscription`,
      { subscription },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    console.log('Subscription removed from server');
  } catch (error) {
    console.error('Error removing subscription:', error);
    throw error;
  }
};



export const updateInvoiceAddresses = async (
    token: string,
    invoiceId: number,
    shippingAddressId: number,
    billingAddressId: number
): Promise<Invoice> => {
    try {
        const response = await axios.put(
            `${API_URL}/account/invoices/addresses`,
            {
                invoiceId,
                shippingAddressId,
                billingAddressId
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error updating invoice addresses:', error);
        throw error;
    }
};



// ... existing code ...

export const testNotification = async (token: string): Promise<any> => {
  try {
      const response = await axios.get(
          `${API_URL}/account/test/notif`,
          {
              headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
              },
          }
      );
      return response.data;
  } catch (error) {
      console.error('Error testing notification:', error);
      throw error;
  }
};

// ... existing code ...

async function generateInvoice(invoice: Invoice, user: Partial<User>, product: Product | null, auction: Auction | null, shippingAddress: Address, billingAddress: Address) {
    try {
        const invoiceData = {
            invoiceNumber: `INV-${invoice.id.toString().padStart(6, '0')}`,
            date: invoice.date,
            customer: { // Ensure all customer details are included
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                phone: user.phone,
            },
            shippingAddress,
            billingAddress,
            item: {
                description: product?.title || '',
                quantity: 1,
                unitPrice: auction?.current_bid || product?.rrp||0,
            },
            postageAndPackaging: product?.postage||0,
            auction,
            amount: invoice.amount,
            status: invoice.status,
            type: invoice.type,
            product,
            user,
            quantity: 1,
            credits: invoice.credits,
            

        };

        const response = await axios.post(`${API_URL}/invoice/generate-invoice`, invoiceData);
        if (response.data.success) {
            window.open(`${API_URL}/invoice/download-invoice/${response.data.filename}`, '_blank');
        } else {
            throw new Error('Failed to generate invoice');
        }
    } catch (error) {
        console.error('Error generating invoice:', error);
        alert('Failed to generate invoice. Please try again.');
    }
}

export default generateInvoice;

// Add these new interfaces
interface PayPalOrderResponse {
  id: string;
  status: string;
  links: Array<{
    href: string;
    rel: string;
    method: string;
  }>;
}

// Add these new functions
export const createPayPalOrder = async (
  amount: number,
  currency: string = 'GBP',
  invoiceId?: number,
  product_id?: number,
  credits?: number,
  invoice_type: 'auction' | 'credits' | 'market' = 'auction'
): Promise<PayPalOrderResponse> => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${API_URL}/paypal/create-order`,
      {
        amount,
        currency,
        invoiceId: invoiceId?.toString() || '-1',
        invoice_type,
        product_id,
        credits,
        paymentSource: 'paypal'
      },
      {
        headers: {
          'Authorization': token ? `Bearer ${token}` : undefined,
          'Content-Type': 'application/json',
        },
      }
    );
    
    if (!response.data || response.data.error) {
      throw new Error(response.data?.error || 'Failed to create PayPal order');
    }
    
    return response.data;
  } catch (error) {
    console.error('Error creating PayPal order:', error);
    throw error;
  }
};

export const capturePayPalOrder = async (orderId: string): Promise<any> => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${API_URL}/paypal/capture-order/${orderId}`,
      {},  // empty body
      {
        headers: {
          'Authorization': token ? `Bearer ${token}` : undefined,
          'Content-Type': 'application/json',
        },
      }
    );
    
    if (!response.data || response.data.error) {
      throw new Error(response.data?.error || 'Failed to capture PayPal order');
    }
    
    return response.data;
  } catch (error) {
    console.error('Error capturing PayPal order:', error);
    throw error;
  }
};


export const uploadMedia = async (
  token: string, 
  formData: FormData, 
  entityType: string, 
  entityId: number
) => {
  const response = await fetch(
    `${API_URL}/account/review/media/${entityType}/${entityId}`, // Ensure this URL is correct
    {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: formData
    }
  );

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.error || 'Failed to upload media');
  }

  return response.json();
};

export const activateAccount = async (token: string): Promise<AxiosResponse<any>> => {
  return axios.post(`${API_URL}/auth/activate-account`, { token });
};
