import React from 'react';
import styles from './ReferralInfo.module.css';

interface ReferralProps {
  currentUsername?: string;
  referrer?: {
    username: string;
    first_name: string;
    last_name: string;
  };
  referrals: {
    id: number;
    username: string;
    first_name: string;
    last_name: string;
    reg_date: string;
  }[];
}

const ReferralInfo: React.FC<ReferralProps> = ({ currentUsername, referrer, referrals }) => {
  const referralLink = `${window.location.origin}/register?ref=${currentUsername}`;

  return (
    <div className={styles.referralContainer}>
      <h2>Referral Information</h2>
      
      <div className={styles.referralExplanation}>
        <h3>How Referrals Work</h3>
        <p>Share your referral link with friends and you'll both receive 5 free credits when they sign up!</p>
        <p> You must have bought at least 1 item to be eligible for referrals</p>
        <div className={styles.referralLinkBox}>
          <p><strong>Your Referral Link</strong></p>
          <div className={styles.linkContainer}>
            <code className={styles.referralLink}>
              {referralLink}
            </code>
            <button 
              onClick={() => navigator.clipboard.writeText(referralLink)}
              className={styles.copyButton}
            >
              Copy
            </button>
          </div>
        </div>
      </div>

      <div className={styles.referrerSection}>
        <h3>Your Referrer</h3>
        {referrer ? (
          <div className={styles.referrerInfo}>
            <p>
              <strong>{referrer.first_name} {referrer.last_name}</strong>
              <span className={styles.username}>@{referrer.username}</span>
            </p>
          </div>
        ) : (
          <p>No referrer</p>
        )}
      </div>

      <div className={styles.referralsSection}>
        <h3>People You've Referred</h3>
        {referrals.length > 0 ? (
          <div className={styles.referralsList}>
            {referrals.map(referral => (
              <div key={referral.id} className={styles.referralItem}>
                <div className={styles.referralInfo}>
                  <strong>{referral.first_name} {referral.last_name}</strong>
                  <span className={styles.username}>@{referral.username}</span>
                </div>
                <div className={styles.referralDate}>
                  Joined: {new Date(referral.reg_date).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit'
                  })}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>You haven't referred anyone yet</p>
        )}
      </div>
    </div>
  );
};

export default ReferralInfo; 