import React, { useState } from 'react';
import { submitReview } from '../../utils/accountAPI';
import { Star } from 'lucide-react';
import styles from './ReviewForm.module.css';
import { uploadMedia } from '../../utils/adminAPI';
import { useAuctionState } from '../../components/auctionStateContext';

interface ReviewFormProps {
  auctionId: number;
  productId: number;
  userId: number;
  onSubmitSuccess: (data: { auctionId: number; review: any }) => void;
  token: string;
}

export const ReviewForm: React.FC<ReviewFormProps> = ({ auctionId, productId, userId, onSubmitSuccess, token }) => {
  const { auctions, products } = useAuctionState();
  const auction = auctions.find((a) => a.id === auctionId);
  const product = products.find((p) => p.id === productId);
  const [rating, setRating] = useState<number | null>(0);
  const [comment, setComment] = useState('');
  const [files, setFiles] = useState<File[]>([]);
  const [previews, setPreviews] = useState<string[]>([]);
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.target.files || []);
    if (selectedFiles.length > 5) {
      setError('Maximum 5 images allowed');
      return;
    }

    setFiles(selectedFiles);
    const newPreviews = selectedFiles.map(file => URL.createObjectURL(file));
    setPreviews(newPreviews);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    if (!rating || !comment) {
      setError('Please fill in all fields before submitting.');
      return;
    }

    setIsSubmitting(true);

    try {
      const reviewData = {
        auctionId,
        productId,
        userId,
        rating,
        comment
      };

      const response = await submitReview(token, reviewData, 'review', auctionId);
      console.log('Review submitted successfully:', response);

      if (files.length > 0) {
        const formData = new FormData();
        files.forEach((file) => {
          formData.append('media', file);
        });
        formData.append('entity_id', response.id.toString());
        formData.append('entity_type', 'review');
        await uploadMedia(token, formData, 'review', response.id);
        console.log('Media uploaded successfully');
      }

      onSubmitSuccess({ auctionId, review: response });
      setRating(0);
      setComment('');
      setFiles([]);
      setPreviews([]);
    } catch (err: any) {
      console.error('Error submitting review:', err);
      setError(err.message || 'Failed to submit review');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.reviewFormContainer}>
      <form onSubmit={handleSubmit} className={styles.reviewForm}>
        <div className={styles.ratingContainer}>
          {[1, 2, 3, 4, 5].map((star) => (
            <button
              key={star}
              type="button"
              onClick={() => setRating(star)}
              className={styles.starButton}
            >
              <Star
                className={styles.star}
                fill={rating && star <= rating ? 'gold' : 'none'}
                stroke={rating && star <= rating ? 'gold' : 'currentColor'}
              />
            </button>
          ))}
        </div>

        <textarea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Share your experience..."
          className={styles.commentInput}
          rows={4}
        />

        <div className={styles.fileUpload}>
          <input
            type="file"
            onChange={handleFileChange}
            multiple
            accept="image/*"
            id="review-images"
            className={styles.fileInput}
          />
          <label htmlFor="review-images" className={styles.fileLabel}>
            Add Photos (Max 5)
          </label>
        </div>

        {previews.length > 0 && (
          <div className={styles.previewContainer}>
            {previews.map((preview, index) => (
              <img 
                key={index} 
                src={preview} 
                alt={`Preview ${index + 1}`} 
                className={styles.previewImage}
              />
            ))}
          </div>
        )}

        {error && <div className={styles.error}>{error}</div>}

        <button 
          type="submit" 
          disabled={isSubmitting} 
          className={styles.submitButton}
        >
          {isSubmitting ? 'Submitting...' : 'Submit Review'}
        </button>
      </form>
    </div>
  );
};
