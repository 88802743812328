import React, { useEffect, useState } from 'react';
import { Heart, Users, DollarSign, Award } from 'lucide-react';
import styles from './CharityPage.module.css';
import * as coreAPI from '../utils/coreAPI';
import Banner from '../components/Banner';
import Loading from '../components/loading';

interface BannerSlide {
  image: string;
  title: string;
  description: string;
  link: string;
}
interface CharityStats {
  totalDonated: number;
  projectsSupported: number;
  peopleHelped: number;
  activeInitiatives: number;
}

interface CharityProject {
  id: number;
  name: string;
  description: string;
  amountRaised: number;
  goal: number;
  imageUrl: string;
  category: string;
}

interface PastInitiative {
  id: number;
  name: string;
  description: string;
  totalRaised: number;
  completionDate: string;
  category: string;
}

const fadeInStyle: React.CSSProperties = {
  animation: 'fadeIn 0.5s ease-in-out'
};

const fadeInKeyframes = `
  @keyframes fadeIn {
      from { opacity: 0; transform: translateY(20px); }
      to { opacity: 1; transform: translateY(0); }
  }
`;

const styleSheet = document.createElement('style');
styleSheet.textContent = fadeInKeyframes;
document.head.appendChild(styleSheet);

const charityBannerSlides: BannerSlide[] = [
  {
    // image: 'https://images.unsplash.com/photo-1497633762265-9d179a990aa6',
    image: `${process.env.REACT_APP_API_URL?.replace('/api', '') || 'http://127.0.0.1:4000'}/uploads/meem_madaat_ushop.jpeg`,
    title: 'Meem Madaat Medical Fund',
    description: 'Supporting medical initiatives for underprivileged people in our community',
    link: '#current-initiatives'
  },
  {
    // image: 'https://images.unsplash.com/photo-1488521787991-ed7bbaae773c',
    image:`${process.env.REACT_APP_API_URL?.replace('/api', '') || 'http://127.0.0.1:4000'}/uploads/ushop_bid_donation.jpeg`,
    title: 'Our Impact',
    description: '50p is pledged for every 100 bids placed on UShop.bid to support Meem Madaat charitable initiatives',
    link: '#current-stats'
  },
  {
    // image: 'https://images.unsplash.com/photo-1559027615-cd4628902d4a',
    image:`${process.env.REACT_APP_API_URL?.replace('/api', '') || 'http://127.0.0.1:4000'}/uploads/ushop_charity.jpeg`,

    title: 'Community Support',
    description: 'Building stronger communities through sustainable charitable programs',
    link: '#our-impact'
  }
];

const CharityPage: React.FC = () => {
  const [stats, setStats] = useState<CharityStats | null>(null);
  const [projects, setProjects] = useState<CharityProject[]>([
    { 
      id: 1,
      name: "Meem Madaat Medical Fund",
      description: "Supporting medical initiatives and providing resources for underprivileged communities.",
      amountRaised: 2,
      goal: 5000,
      imageUrl: "/images/charity/meem-education.jpg",
      category: "Education"
    },
    // {
    //   id: 2,
    //   name: "Community Support Program",
    //   description: "Providing essential support and resources to families in need within our local community.",
    //   amountRaised: 6200,
    //   goal: 7500,
    //   imageUrl: "/images/charity/community-support.jpg",
    //   category: "Community"
    // },
    // {
    //   id: 3,
    //   name: "Youth Development Initiative",
    //   description: "Empowering young people through mentorship, skills training, and leadership development.",
    //   amountRaised: 4800,
    //   goal: 5000,
    //   imageUrl: "/images/charity/youth-development.jpg",
    //   category: "Youth"
    // }
  ]);

  const [pastInitiatives] = useState<PastInitiative[]>([
    {
      id: 1,
      name: "Meem Madaat Clinic Initiative",
      description: "Provided essential medical services and resources to underprivileged communities through the Meem Madaat Clinic.",
      totalRaised: 3500,
      completionDate: "December 2023",
      category: "Healthcare Support"},
    {
      id: 2,
      name: "Clothing Distribution Program",
      description: "Provided warm clothing and essential garments to underprivileged families and individuals in our community.",
      totalRaised: 2800,
      completionDate: "September 2023",
      category: "Clothing Support"
    },
    {
      id: 3,
      name: "Ramadan Food Package Initiative",
      description: "Supplied food packages to families during Ramadan, helping them maintain proper nutrition during the holy month.",
      totalRaised: 4200,
      completionDate: "April 2023",
      category: "Food Support"
    }
  ]);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const completedResponse = await coreAPI.getCompletedAuctions();
        const totalDonated = completedResponse.data.reduce((sum, auction) => 
          sum + auction.current_bid, 0) / 100;

        const bidsResponse = await coreAPI.getBids();
        const totalPledged = (bidsResponse.data.length / 200);

        setStats({
          totalDonated,
          projectsSupported: totalPledged,
          peopleHelped: 200,
          activeInitiatives: 1
        });
      } catch (error) {
        console.error('Failed to fetch stats:', error);
      }
    };

    fetchStats();
  }, []);

  if (!stats) {
    return <Loading message="Calculating stats..." subMessage="Please wait while we gather the latest data" />;
  }

  return (
    <div className={styles.charityPage} style={fadeInStyle}>
      <Banner slides={charityBannerSlides} autoPlayInterval={6000} logo='/uploads/logo-circle.png' />
      <h2 id="current-stats" className={styles.sectionTitle} style={{margin: ' 0 auto', textAlign: 'center'}}>Current Stats</h2>

      <div className={styles.statsGrid}>

        <div className={styles.statCard}>
          <DollarSign size={24} />
          <h3>Total Donated</h3>
          <p>£{stats.totalDonated.toLocaleString()}</p>
        </div>
        <div className={styles.statCard}>
          <Award size={24} />
          <h3>Pledged Donations</h3>
          <p>£{stats.projectsSupported.toLocaleString()}</p>
        </div>
        {/* <div className={styles.statCard}>
          <Users size={24} />
          <h3>People Helped</h3>
          <p>{stats.peopleHelped.toLocaleString()}</p>
        </div> */}
        <div className={styles.statCard}>
          <Heart size={24} />
          <h3>Active Initiatives</h3>
          <p>{stats.activeInitiatives}</p>
        </div>
      </div>

      <section id="current-initiatives" className={styles.currentProjects}>
        <h2 className={styles.sectionTitle}>Current Initiatives</h2>
        <div className={styles.projectsGrid}>
          {projects.map(project => (
            <div key={project.id} className={styles.projectCard}>
              {/* <div className={styles.projectImage}>
                <img src={project.imageUrl} alt={project.name} />
                <span className={styles.category}>{project.category}</span>
              </div> */}
              <div className={styles.projectContent}>
                <h3>{project.name}</h3>
                <p>{project.description}</p>
                <div className={styles.progressBar}>
                  <div 
                    className={styles.progress} 
                    style={{ width: `${(stats.totalDonated / project.goal) * 100}%` }}
                  />
                </div>
                <div className={styles.projectStats}>
                  <span>£{stats.totalDonated.toLocaleString()} raised</span>
                  <span>of £{project.goal.toLocaleString()}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className={styles.currentProjects}>
        <h2 className={styles.sectionTitle}>Past Initiatives</h2>
        <div className={styles.projectsGrid}>
          {pastInitiatives.map(initiative => (
            <div key={initiative.id} className={styles.projectCard}>
              <div className={styles.projectContent}>
                <h3>{initiative.name}</h3>
                <p>{initiative.description}</p>
                <div className={styles.projectStats}>
                  <span>
                    <strong>Total Raised: </strong>
                    <span style={{ color: 'var(--primary-red)' }}>£{initiative.totalRaised.toLocaleString()}</span>
                  </span>
                  <span>Completed: {initiative.completionDate}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section id="our-impact" className={styles.impactSection}>
        <h2 className={styles.sectionTitle}>Our Impact</h2>
        <p>Every bid on UShop.bid contributes to these worthy causes. We're committed to transparency and regularly update our charity contributions and impact metrics.</p>
        {/* <button className={styles.impactButton}>View Detailed Impact Report</button> */}
      </section>
    </div>
  );
};

export default CharityPage; 