import React, { useState, useEffect } from "react";
import { Product, Invoice, Auction, ProductCategory } from "../../../types";
import {
  ShoppingCart,
  Package,
  Clock,
  Euro,
  PoundSterling,
  Eye,
  ChevronRight,
  ChevronDown,
} from "lucide-react";
import styles from "./MarketPage.module.css";
import { getProducts, getCategories } from "../utils/coreAPI";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useError } from "../components/ErrorContext";
import Banner from "../components/Banner";
import CategoryFilterPanel from "../components/CategoryFilterPanel";
import Pagination from "../components/pagination";
import AuctionGrid from "../auction/components/AuctionGrid";
import Loading from "../components/loading";
import { useAuctionState } from "../components/auctionStateContext";

type SortType = "rrp-asc" | "rrp-desc" | "newest" | "popular";

const sampleBannerSlides = [
  {
    // image: 'https://images.unsplash.com/photo-1603791440384-56cd371ee9a7',
    image: `${
      process.env.REACT_APP_API_URL?.replace("/api", "") ||
      "http://127.0.0.1:4000"
    }/uploads/ushop_market_tech.jpeg`,

    title: "Latest Electronics",
    description: "Discover cutting-edge technology at unbeatable prices",
    link: "market?category=1",
  },
  {
    // image: 'https://images.unsplash.com/photo-1526170375885-4d8ecf77b99f',
    image: `${
      process.env.REACT_APP_API_URL?.replace("/api", "") ||
      "http://127.0.0.1:4000"
    }/uploads/fashion_ushop_market.jpeg`,

    title: "Fashion Essentials",
    description: "Style meets comfort in our new collection",
    link: "market?category=4",
  },
  {
    // image: 'https://images.unsplash.com/photo-1600585154340-be6161a56a0c',
    image: `${
      process.env.REACT_APP_API_URL?.replace("/api", "") ||
      "http://127.0.0.1:4000"
    }/uploads/home_ushop_market.jpeg`,

    title: "Home & Living",
    description: "Transform your space with our premium selection",
    link: "market?category=3",
  },
];

const MarketPage: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialCategory = queryParams.get("category");

  const [availableProducts, setAvailableProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { showError } = useError();
  const [selectedCategory, setSelectedCategory] = useState<number | null>(
    initialCategory ? parseInt(initialCategory) : null
  );
  const [sortType, setSortType] = useState<SortType>("rrp-asc");
  const [categories, setCategories] = useState<ProductCategory[]>([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  const {
    auctions,
    timers,
    prevBidders,
    isLoading: auctionLoading,
    handleBid,
    getCountdown,
    getProductImage,
  } = useAuctionState();

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productsRes = await getProducts();

        const availableForPurchase = productsRes.data.filter((product) => {
          const reservedForAuctions = auctions.filter(
            (a) =>
              a.product_id === product.id &&
              (a.status === "upcoming" || a.status === "active")
          ).length;
          const availableStock = product.stock - reservedForAuctions;
          return availableStock >= 1;
        });

        setAvailableProducts(availableForPurchase);
        setLoading(false);
      } catch (err) {
        setError("Failed to load products. Please try again later.");
        showError("Error loading market data");
      }
    };

    fetchData();
  }, [auctions, showError]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getCategories();
        setCategories(response.data);
      } catch (err) {
        showError("Error loading categories");
      }
    };
    fetchCategories();
  }, []);

  const filteredProducts = availableProducts
    .filter(
      (product) =>
        (!selectedCategory || product.category_id === selectedCategory) &&
        product.title.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => {
      switch (sortType) {
        case "rrp-asc":
          return a.rrp - b.rrp;
        case "rrp-desc":
          return b.rrp - a.rrp;
        case "newest":
          return new Date(b.id).getTime() - new Date(a.id).getTime();
        case "popular":
          return b.stock - a.stock;
        default:
          return 0;
      }
    });

  const handlePurchase = async (product: Product) => {
    try {
      const invoice: Partial<Invoice> = {
        amount: product.rrp,
        credits: 0,
        status: "awaiting payment",
        date: new Date().toISOString(),
      };
    } catch (err) {
      setError("Failed to process purchase. Please try again.");
      showError("Payment processing failed");
    }
  };

  const handleUnauthorizedClick = (e: React.MouseEvent) => {
    e.preventDefault();
    showError(
      "Please sign in or register to view product details and make purchases"
    );
    navigate("/login");
  };

  const handlePageChange = (page: number) => {
    const marketContent = document.querySelector(`.${styles.mainContent}`);
    if (marketContent) {
      marketContent.scrollIntoView({ behavior: "smooth" });
    }

    setCurrentPage(page);
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    setCurrentPage(1);
  };

  const indexOfLastItem = currentPage * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const currentProducts = filteredProducts.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredProducts.length / pageSize);

  if (loading || auctionLoading) {
    return (
      <div>
      <Banner slides={sampleBannerSlides} autoPlayInterval={6000} />

      <Loading
        message="Loading market products..."
        subMessage="Fetching available items for purchase "
      />
      </div>
    );
  }

  if (error) {
    return <div className={styles.errorMessage}>{error}</div>;
  }

  return (
    <div className={styles.container}>
      
      <Banner slides={sampleBannerSlides} autoPlayInterval={6000} logo='/uploads/logo-circle.png' />
      <div className={styles.marketContainer}>
        <div className={styles.marketHeader}>
          <h2 className={styles.sectionTitle}>Market</h2>
          <p>Purchase products directly at retail price</p>
        </div>

        <div className={styles.marketContent}>
          <aside className={styles.filterSidebar}>
            <CategoryFilterPanel
              categories={categories}
              selectedCategory={selectedCategory}
              onCategoryChange={setSelectedCategory}
            />
          </aside>

          <main className={styles.mainContent}>
            <div className={styles.titleContainer}>
              <div className={styles.filterSortContainer}>
                <div className={styles.searchContainer}>
                  <input
                    type="text"
                    placeholder="Search products..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className={styles.searchInput}
                  />
                </div>

                <div className={styles.sortControls}>
                  <select
                    className={styles.sortSelect}
                    value={sortType}
                    onChange={(e) => setSortType(e.target.value as SortType)}
                  >
                    <option value="rrp-asc">Price: Low to High</option>
                    <option value="rrp-desc">Price: High to Low</option>
                    <option value="newest">Newest First</option>
                    <option value="popular">Most Popular</option>
                  </select>
                  {/* <ChevronDown className={styles.sortIcon} size={16} /> */}
                </div>
              </div>
            </div>

            <div className={styles.productGrid}>
              {currentProducts.map((product) => {
                const category = categories.find(
                  (c) => c.id === product.category_id
                );
                const firstImage =
                  product.media?.length > 0
                    ? product.media.sort((a, b) => a.order - b.order)[0].url
                    : "/default-product.jpg";

                return (
                  <Link
                    to={`/market/${product.id}`}
                    className={styles.productCard}
                    key={product.id}
                    onClick={!isLoggedIn ? handleUnauthorizedClick : undefined}
                  >
                    <div className={styles.imageContainer}>
                      <div style={watermarkStyle}>
                        <img
                          src={getProductImage(product)}
                          alt={product.title}
                          className={styles.productImage}
                        />
                        <img
                          src="/uploads/logo-circle.png"
                          alt="Watermark"
                          style={watermarkImageStyle}
                        />
                      </div>
                      {category && (
                        <div className={styles.productCategory}>
                          {category.name}
                        </div>
                      )}
                    </div>

                    <div className={styles.productDetails}>
                      <h3 className={styles.productTitle}>{product.title}</h3>
                      <p className={styles.productDescription}>
                        {product.description}
                      </p>

                      <div className={styles.productPrice}>
                        £{product.rrp}
                        <span className={styles.productPostage}>
                          + £{product.postage / 100} shipping
                        </span>
                      </div>

                      <div className={styles.productActions}>
                        <button
                          className={styles.viewDetailsButton}
                          onClick={(e) => {
                            e.preventDefault();
                            if (!isLoggedIn) {
                              handleUnauthorizedClick(e);
                            } else {
                              navigate(`/market/${product.id}`);
                            }
                          }}
                        >
                          <Eye className={styles.icon} />
                          {isLoggedIn ? "BUY NOW!" : "SIGN IN TO BUY"}
                        </button>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>

            {totalPages > 1 && (
              <Pagination
                currentPage={currentPage}
                totalItems={filteredProducts.length}
                pageSize={pageSize}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                pageSizeOptions={[10, 20, 50]}
                scrollToTop={false}
              />
            )}
          </main>
        </div>
      </div>
    </div>
  );
};

// Define watermark styles
const watermarkStyle: React.CSSProperties = {
  display: 'block',
};

const watermarkImageStyle: React.CSSProperties = {
  position: 'absolute',
  top: '0px',
  right: '0px',
  width: '16%',
  height: '16%',
  opacity: 1,
  zIndex: 1,
};

export default MarketPage;
