import React, { useState } from 'react';
import axios from 'axios';
import {postContact} from '../utils/coreAPI'
import Banner from '../components/Banner';
import ReCAPTCHA from 'react-google-recaptcha';

interface BannerSlide {
  image: string;
  title: string;
  description: string;
  link: string;
  openInNewTab: boolean;
}
const fadeInStyle: React.CSSProperties = {
  animation: 'fadeIn 0.5s ease-in-out'
};

const fadeInKeyframes = `
  @keyframes fadeIn {
      from { opacity: 0; transform: translateY(20px); }
      to { opacity: 1; transform: translateY(0); }
  }
`;

const styleSheet = document.createElement('style');
styleSheet.textContent = fadeInKeyframes;
document.head.appendChild(styleSheet);

const contactBannerSlides: BannerSlide[] = [
  {
    // image: 'https://images.unsplash.com/photo-1423666639041-f56000c27a9a',
    image:`${process.env.REACT_APP_API_URL?.replace('/api', '') || 'http://127.0.0.1:4000'}/uploads/ushopbid_contact.jpeg`,

    title: 'Get in Touch', 
    description: 'We aim to respond to all inquiries within 24 hours',
    link: '#contact-section',
    openInNewTab: false,
  },
  {
    // image: 'https://images.unsplash.com/photo-1577563908411-5077b6dc7624',
    image:`${process.env.REACT_APP_API_URL?.replace('/api', '') || 'http://127.0.0.1:4000'}/uploads/ushop_email.jpeg`,

    title: 'Email Us',
    description: 'info@ushop.bid',
    link: 'mailto:info@ushop.bid',
    openInNewTab: false,
  },
  {
    // image: 'https://images.unsplash.com/photo-1486406146926-c627a92ad1ab',
    image:`${process.env.REACT_APP_API_URL?.replace('/api', '') || 'http://127.0.0.1:4000'}/uploads/ushop_office.jpeg`,

    title: 'Head Office',
    description: '27 Old Gloucester St, London, WC1N 3AX',
    link: 'https://maps.app.goo.gl/reigrLWCroA881WJ8',
    openInNewTab: true,
  }
];

const ContactForm: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [status, setStatus] = useState<'idle' | 'submitting' | 'success' | 'error'>('idle');
  const [captchaValue, setCaptchaValue] = useState<string | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleCaptchaChange = (value: string | null) => {
    setCaptchaValue(value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!captchaValue) {
      alert('Please complete the CAPTCHA');
      return;
    }
    setStatus('submitting');

    try {
      // Replace with your actual API endpoint
      await postContact(formData.name,formData.email,formData.subject,formData.message);
      setStatus('success');
      setFormData({ name: '', email: '', subject: '', message: '' });
      setCaptchaValue(null); // Reset CAPTCHA
    } catch (error) {
      console.error('Error sending email:', error);
      setStatus('error');
    }
  };

  return (
    <div className='container' style={fadeInStyle}>
      <Banner slides={contactBannerSlides} autoPlayInterval={6000} logo='/uploads/logo-circle.png' />
      <div id="contact-section" style={styles.container}>

        <h2 className="sectionTitle">Contact Us</h2>
        <form onSubmit={handleSubmit} style={styles.form}>
          <div style={styles.inputGroup}>
            <label htmlFor="name" style={styles.label}>Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              style={styles.input}
            />
          </div>
          <div style={styles.inputGroup}>
            <label htmlFor="email" style={styles.label}>Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              style={styles.input}
            />
          </div>
          <div style={styles.inputGroup}>
            <label htmlFor="subject" style={styles.label}>Subject</label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              required
              style={styles.input}
            />
          </div>
          <div style={styles.inputGroup}>
            <label htmlFor="message" style={styles.label}>Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              style={styles.textarea}
            />
          </div>
          <ReCAPTCHA
            sitekey="6LfVGKQqAAAAALGZfIJBbRwZxScqV2rRTRaHZ5HW"
            onChange={handleCaptchaChange}
            style={styles.captcha}
          />
          <button type="submit" disabled={status === 'submitting'} style={styles.button}>
            {status === 'submitting' ? 'Sending...' : 'Send Message'}
          </button>
        </form>
        {status === 'success' && <p style={styles.successMessage}>Thank you for your message. We'll get back to you soon!</p>}
        {status === 'error' && <p style={styles.errorMessage}>There was an error sending your message. Please try again later.</p>}
        </div>
        </div>
    
  );
};

const styles = {
  container: {
    maxWidth: '95vw',
    margin: '0 auto',
    // padding: '20px',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  } as React.CSSProperties,
  title: {
    fontSize: '24px',
    color: '#e53e3e',
    marginBottom: '20px',
    textAlign: 'center',
  } as React.CSSProperties,
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: '2rem auto',
    padding: '1rem 5rem',
    paddingBottom: '2.5rem',
  } as React.CSSProperties,
  inputGroup: {
    marginBottom: '20px',
    position: 'relative',
    transition: 'transform 0.2s ease',
    '&:focus-within': {
      transform: 'translateY(-2px)',
    },
  } as React.CSSProperties,
  label: {
    display: 'block',
    marginBottom: '8px',
    color: '#4a5568',
    fontSize: '0.95rem',
    fontWeight: '500',
    transition: 'color 0.2s ease',
  } as React.CSSProperties,
  input: {
    width: '100%',
    padding: '12px 16px',
    border: '2px solid #e2e8f0',
    borderRadius: '8px',
    fontSize: '1rem',
    backgroundColor: '#fff',
    transition: 'all 0.2s ease',
    '&:hover': {
      borderColor: '#cbd5e0',
    },
    '&:focus': {
      outline: 'none',
      borderColor: '#e53e3e',
      boxShadow: '0 0 0 3px rgba(229, 62, 62, 0.1)',
    },
  } as React.CSSProperties,
  textarea: {
    width: '100%',
    padding: '12px 16px',
    border: '2px solid #e2e8f0',
    borderRadius: '8px',
    fontSize: '1rem',
    backgroundColor: '#fff',
    minHeight: '150px',
    resize: 'vertical',
    transition: 'all 0.2s ease',
    '&:hover': {
      borderColor: '#cbd5e0',
    },
    '&:focus': {
      outline: 'none',
      borderColor: '#e53e3e',
      boxShadow: '0 0 0 3px rgba(229, 62, 62, 0.1)',
    },
  } as React.CSSProperties,
  button: {
    backgroundColor: '#e53e3e',
    color: '#ffffff',
    border: 'none',
    padding: '12px 20px',
    borderRadius: '4px',
    fontSize: '16px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  } as React.CSSProperties,
  successMessage: {
    color: '#48bb78',
    marginTop: '20px',
    textAlign: 'center',
  } as React.CSSProperties,
  errorMessage: {
    color: '#e53e3e',
    marginTop: '20px',
    textAlign: 'center',
  } as React.CSSProperties,
  captcha: {
    margin: '20px 0',
    display: 'flex',
    justifyContent: 'center',
  } as React.CSSProperties,
  sectionTitle: {
    fontSize: '1.75rem',
    fontWeight: 800,
    color: 'var(--primary-red)',
    textAlign: 'center',
    marginBottom: '1.5rem',
    paddingTop: '1.5rem',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
    position: 'relative',
    animation: 'slideInFromBottom 0.8s ease-out',
    fontFamily: "'Orbitron', sans-serif",
  } as React.CSSProperties,
};

export default ContactForm;