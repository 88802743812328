import React, { useState, useEffect, useMemo } from 'react';
import { Invoice, User, Product, Auction } from '../../../../types';
import { getInvoices, createInvoice, updateInvoice, deleteInvoice, getUsers, getProducts, getAuctions } from '../../utils/adminAPI';
import styles from './Management.module.css';

const token = localStorage.getItem('token'); // or however you're storing the token

const InvoicesManagement: React.FC = () => {
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
  const [formData, setFormData] = useState<Invoice>({
    id: 0,
    user_id: undefined,
    amount: 0,
    auction_id: null,
    credits: 0,
    status: 'awaiting payment',
    date: new Date().toISOString().split('T')[0],
    type: 'credits',
  });
  const [users, setUsers] = useState<User[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState<Partial<Invoice>>({});
  const [sortConfig, setSortConfig] = useState<{ key: keyof Invoice | null, direction: 'ascending' | 'descending' }>({ key: null, direction: 'ascending' });
  const [products, setProducts] = useState<Product[]>([]);
  const [auctions, setAuctions] = useState<Auction[]>([]);
  const [visibleColumns, setVisibleColumns] = useState<string[]>([
    'id', 'user_id', 'username', 'productTitle', 'amount', 'credits', 'status', 'date', 'type'
  ]);

  useEffect(() => {
    fetchInvoices();
    fetchUsers();
    fetchProducts();
    fetchAuctions();
  }, []);

  const fetchInvoices = async () => {
    if (token) {
      const response = await getInvoices(token);
      setInvoices(response.data);
    }
  };

  const fetchUsers = async () => {
    try {
      if (token) {
        const response = await getUsers(token);
        setUsers(response.data);
      }
    } catch (error) {
      console.error('Failed to fetch users', error);
    }
  };

  const fetchProducts = async () => {
    if (token) {
      const response = await getProducts(token);
      setProducts(response.data);
    }
  };

  const fetchAuctions = async () => {
    if (token) {
      const response = await getAuctions(token);
      setAuctions(response.data);
    }
  };

  useEffect(() => {
    if (selectedInvoice) {
      setFormData({ ...selectedInvoice, date: selectedInvoice.date.split('T')[0] });
    } else {
      setFormData({
        id: 0,
        user_id: undefined,
        amount: 0,
        auction_id: null,
        credits: 0,
        status: 'awaiting payment',
        date: new Date().toISOString().split('T')[0],
        type: 'credits',
      });
    }
  }, [selectedInvoice]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'number' ? Number(value) : value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (token) {
      if (selectedInvoice) {
        await updateInvoice(token, selectedInvoice.id, formData);
      } else {
        await createInvoice(token, formData);
      }
      fetchInvoices();
      setSelectedInvoice(null);
      setIsModalOpen(false);
    }
  };

  const handleEdit = (invoice: Invoice) => {
    setSelectedInvoice(invoice);
    setIsModalOpen(true);
  };

  const handleDelete = async (id: number) => {
    if (token) {
      const confirmDelete = window.confirm('Are you sure you want to delete this invoice?');
      if (confirmDelete) {
        await deleteInvoice(token, id);
        fetchInvoices();
      }
    }
  };

  const getUsername = (userId: number | undefined) => {
    const user = users.find(user => user.id === userId);
    return user ? user.username : 'Unknown';
  };

  const handleFilterChange = (key: keyof Invoice, value: string | number | undefined) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const handleSort = (key: keyof Invoice) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedInvoices = useMemo(() => {
    let sortableInvoices = [...invoices];
    if (sortConfig.key !== null) {
      sortableInvoices.sort((a, b) => {
        const aValue = a[sortConfig.key as keyof Invoice];
        const bValue = b[sortConfig.key as keyof Invoice];
        if (aValue! < bValue!) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue! > bValue!) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableInvoices;
  }, [invoices, sortConfig]);

  const getProductTitle = (productId: number | undefined) => {
    const product = products.find(product => product.id === productId);
    return product ? product.title : 'Unknown Product';
  };

  const getProductTitleFromAuction = (auctionId: number | undefined) => {
    const auction = auctions.find(auction => auction.id === auctionId);
    if (auction) {
      const product = products.find(product => product.id === auction.product_id);
      return product ? product.title : 'Unknown Product';
    }
    return 'Unknown Auction';
  };

  const filteredInvoices = useMemo(() => {
    return sortedInvoices.filter(invoice => {
      const matchesSearchText = Object.values(invoice).some(value =>
        value?.toString().toLowerCase().includes(searchText.toLowerCase())
      ) || getUsername(invoice.user_id).toLowerCase().includes(searchText.toLowerCase()) ||
        (invoice.type === 'market' && getProductTitle(invoice.product_id!).toLowerCase().includes(searchText.toLowerCase())) ||
        (invoice.type === 'auction' && getProductTitleFromAuction(invoice.auction_id!).toLowerCase().includes(searchText.toLowerCase()));

      const matchesFilters = Object.entries(filters).every(([key, value]) => {
        if (!value) return true;
        return invoice[key as keyof Invoice]?.toString().toLowerCase().includes(value.toString().toLowerCase());
      });

      return matchesSearchText && matchesFilters;
    });
  }, [sortedInvoices, searchText, filters]);

  const toggleColumn = (column: string) => {
    setVisibleColumns(prev => 
      prev.includes(column) ? prev.filter(col => col !== column) : [...prev, column]
    );
  };

  return (
    <div className={styles.pageContainer}>
      <h2 className={styles.pageTitle}>Invoice Management</h2>
      
      <button onClick={() => setIsModalOpen(true)} className={styles.addButton}>Add Invoice</button>

      {isModalOpen && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <span className={styles.close} onClick={() => setIsModalOpen(false)}>&times;</span>
            <form onSubmit={handleSubmit} className={styles.form}>
              <div className={styles.formGroup}>
                <label htmlFor="user_id" className={styles.formLabel}>User ID:</label>
                <input
                  type="number"
                  id="user_id"
                  name="user_id"
                  value={formData.user_id || ''}
                  onChange={handleInputChange}
                  placeholder="User ID"
                  className={styles.formInput}
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="amount" className={styles.formLabel}>Amount:</label>
                <input
                  type="number"
                  id="amount"
                  name="amount"
                  value={formData.amount}
                  onChange={handleInputChange}
                  placeholder="Amount"
                  required
                  className={styles.formInput}
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="auction_id" className={styles.formLabel}>Auction ID:</label>
                <input
                  type="number"
                  id="auction_id"
                  name="auction_id"
                  value={formData.auction_id || ''}
                  onChange={handleInputChange}
                  placeholder="Auction ID"
                  className={styles.formInput}
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="credits" className={styles.formLabel}>Credits:</label>
                <input
                  type="number"
                  id="credits"
                  name="credits"
                  value={formData.credits || 0}
                  onChange={handleInputChange}
                  placeholder="Credits"
                  className={styles.formInput}
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="status" className={styles.formLabel}>Status:</label>
                <select 
                  id="status"
                  name="status" 
                  value={formData.status} 
                  onChange={handleInputChange}
                  className={styles.formSelect}
                >
                  <option value="awaiting payment">Awaiting Payment</option>
                  <option value="paid">Paid</option>
                  <option value="shipped">Shipped</option>
                  <option value="delivered">Delivered</option>
                  <option value="completed">Completed</option>
                  <option value="cancelled">Cancelled</option>
                </select>
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="date" className={styles.formLabel}>Date:</label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  value={formData.date}
                  onChange={handleInputChange}
                  required
                  className={styles.formInput}
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="type" className={styles.formLabel}>Type:</label>
                <select 
                  id="type"
                  name="type" 
                  value={formData.type} 
                  onChange={handleInputChange}
                  className={styles.formSelect}
                >
                  <option value="credits">Credits</option>
                  <option value="market">Market</option>
                  <option value="auction">Auction</option>
                </select>
              </div>

              <button type="submit" className={styles.submitButton}>
                {selectedInvoice ? 'Update Invoice' : 'Create Invoice'}
              </button>
            </form>
          </div>
        </div>
      )}

      <div className={styles.filterControls}>
        <input
          type="text"
          placeholder="Search invoices"
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          className={styles.searchInput}
        />
        <div className={styles.typeCheckboxes}>
          <input
            type="number"
            placeholder="Filter by ID"
            onChange={e => handleFilterChange('id', e.target.value ? Number(e.target.value) : undefined)}
            className={styles.formInput}
          />
          <input
            type="number"
            placeholder="Filter by User ID"
            onChange={e => handleFilterChange('user_id', e.target.value ? Number(e.target.value) : undefined)}
            className={styles.formInput}
          />
          <input
            type="number"
            placeholder="Filter by Amount"
            onChange={e => handleFilterChange('amount', e.target.value ? Number(e.target.value) : undefined)}
            className={styles.formInput}
          />
          <input
            type="number"
            placeholder="Filter by Credits"
            onChange={e => handleFilterChange('credits', e.target.value ? Number(e.target.value) : undefined)}
            className={styles.formInput}
          />
          <input
            type="text"
            placeholder="Filter by Status"
            onChange={e => handleFilterChange('status', e.target.value)}
            className={styles.formInput}
          />
          <input
            type="date"
            placeholder="Filter by Date"
            onChange={e => handleFilterChange('date', e.target.value)}
            className={styles.formInput}
          />
          <input
            type="text"
            placeholder="Filter by Type"
            onChange={e => handleFilterChange('type', e.target.value)}
            className={styles.formInput}
          />
        </div>
      </div>

      <div className={styles.columnSelector}>
        {['id', 'user_id', 'username', 'productTitle', 'amount', 'credits', 'status', 'date', 'type'].map(column => (
          <label key={column}>
            <input
              type="checkbox"
              checked={visibleColumns.includes(column)}
              onChange={() => toggleColumn(column)}
            />
            {column.replace('_', ' ').toUpperCase()}
          </label>
        ))}
      </div>

      <table className={styles.table}>
        <thead>
          <tr>
            {visibleColumns.includes('id') && <th onClick={() => handleSort('id')}>ID</th>}
            {visibleColumns.includes('user_id') && <th onClick={() => handleSort('user_id')}>User ID</th>}
            {visibleColumns.includes('username') && <th>Username</th>}
            {visibleColumns.includes('productTitle') && <th>Product Title</th>}
            {visibleColumns.includes('amount') && <th onClick={() => handleSort('amount')}>Amount</th>}
            {visibleColumns.includes('credits') && <th onClick={() => handleSort('credits')}>Credits</th>}
            {visibleColumns.includes('status') && <th onClick={() => handleSort('status')}>Status</th>}
            {visibleColumns.includes('date') && <th onClick={() => handleSort('date')}>Date</th>}
            {visibleColumns.includes('type') && <th onClick={() => handleSort('type')}>Type</th>}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredInvoices.map((invoice) => (
            <tr key={invoice.id}>
              {visibleColumns.includes('id') && <td>{invoice.id}</td>}
              {visibleColumns.includes('user_id') && <td>{invoice.user_id}</td>}
              {visibleColumns.includes('username') && <td>{getUsername(invoice.user_id)}</td>}
              {visibleColumns.includes('productTitle') && <td>
                {invoice.type === 'market' ? getProductTitle(invoice.product_id!) : 
                 invoice.type === 'auction' ? getProductTitleFromAuction(invoice.auction_id!) : 'N/A'}
              </td>}
              {visibleColumns.includes('amount') && <td>{invoice.amount}</td>}
              {visibleColumns.includes('credits') && <td>{invoice.credits}</td>}
              {visibleColumns.includes('status') && <td>{invoice.status}</td>}
              {visibleColumns.includes('date') && <td>{invoice.date}</td>}
              {visibleColumns.includes('type') && <td>{invoice.type}</td>}
              <td>
                <button onClick={() => handleEdit(invoice)} className={styles.editButton}>Edit</button>
                <button onClick={() => handleDelete(invoice.id)} className={styles.deleteButton}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InvoicesManagement; 