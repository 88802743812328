import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
// import { FiSearch, FiPackage, FiClock } from 'react-icons/fi';
import { useAuctionState } from '../components/auctionStateContext';
import styles from './CategoriesPage.module.css';

const CategoriesPage = () => {
  const { categories, isLoading } = useAuctionState();
  const [searchTerm, setSearchTerm] = useState('');
  const [mode, setMode] = useState<'market' | 'auction'>('market');
  const navigate = useNavigate();

  const filteredCategories = categories.filter(category =>
    category.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCategoryClick = (categoryId: number) => {
    if (mode === 'market') {
      navigate(`/market?category=${categoryId}`);
    } else {
      navigate(`/?category=${categoryId}`);
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const cardVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.loader}></div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <motion.div
        className={styles.infoBanner}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <p>
          In <strong>Market Mode</strong>, you can filter between all products available for purchase at retail price (RRP).<br />
          <br />
          In <strong>Auction Mode</strong>, you will only see auctions that are currently active or upcoming for the selected category.
        </p>
        <button onClick={() => setMode(mode === 'market' ? 'auction' : 'market')} className={styles.switchModeButton}>
          Switch to {mode === 'market' ? 'Auction' : 'Market'} Mode
        </button>
      </motion.div>
      <motion.div
        className={styles.searchContainer}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        {/* <FiSearch className={styles.searchIcon} /> */}
        <input
          type="text"
          placeholder="Search categories"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className={styles.searchInput}
        />
    
      </motion.div>
      <motion.div
        className={styles.categoriesContainer}
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {filteredCategories.map(category => (
          <motion.div
            key={category.id}
            className={styles.categoryCard}
            variants={cardVariants}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => handleCategoryClick(category.id)}
          >
            <img src={category.image_url} alt={category.name} className={styles.categoryImage} />
            <h3 className={styles.categoryName}>{category.name}</h3>
            <p className={styles.categoryDescription}>{category.description}</p>
            <div className={styles.categoryDetails}>
              {mode === 'market' ? (
                <div className={styles.categoryItemCount}>
                  <span>{category.item_count}</span>
                </div>
              ) : (
                <div className={styles.auctionStats}>
                  <div className={styles.auctionStat}>
                    <span className={`${styles.statValue} ${styles.activeValue}`}>
                      {category.active_auctions}
                    </span>
                    <span className={styles.statLabel}> Active </span>
                  </div>
                  <div className={styles.auctionStat}>
                    <span className={`${styles.statValue} ${styles.upcomingValue}`}>
                      {category.upcoming_auctions}
                    </span>
                    <span className={styles.statLabel}> Upcoming</span>
                  </div>
                  <div className={styles.auctionStat}>
                    <span className={`${styles.statValue} ${styles.completedValue}`}>
                      {category.completed_auctions}
                    </span>
                    <span className={styles.statLabel}> Completed</span>
                  </div>
                </div>
              )}
            </div>
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

export default CategoriesPage;
