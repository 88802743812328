import React, { useState, useEffect } from 'react';
import { ProductCategory } from '../../../../types';
import { getCategories, createCategory, updateCategory, deleteCategory, uploadMedia, deleteMedia } from '../../utils/adminAPI';
import styles from './Management.module.css';

interface CategoryFormData {
  name: string;
  image_url: string;
  description: string;
}

const CategoryManager: React.FC = () => {
  const [categories, setCategories] = useState<ProductCategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<ProductCategory | null>(null);
  const [formData, setFormData] = useState<CategoryFormData>({
    name: '',
    image_url: '',
    description: '',
  });
  const [mediaFiles, setMediaFiles] = useState<File[]>([]);
  const [notification, setNotification] = useState<{ message: string; type: 'success' | 'error' } | null>(null);
  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      if (token) {
        const response = await getCategories(token);
        setCategories(response.data);
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (!token) return;

      if (selectedCategory) {
        await updateCategory(token, selectedCategory.id, { name: formData.name, image_url: formData.image_url, description: formData.description });
      } else {
        await createCategory(token, { name: formData.name, image_url: formData.image_url, description: formData.description });
      }
      
      fetchCategories();
      resetForm();
    } catch (error) {
      console.error('Error saving category:', error);
    }
  };

  const handleDelete = async (categoryId: number) => {
    if (!window.confirm('Are you sure you want to delete this category?')) return;
    
    try {
      if (token) {
        await deleteCategory(token, categoryId);
        fetchCategories();
      }
    } catch (error) {
      console.error('Error deleting category:', error);
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setMediaFiles(Array.from(e.target.files));
    }
  };

  const handleUpload = async () => {
    if (!mediaFiles.length || !selectedCategory) return;

    try {
      const formData = new FormData();
      mediaFiles.forEach(file => formData.append('media', file));
      formData.append('entity_type', 'category');
      formData.append('entity_id', selectedCategory.id.toString());

      await uploadMedia(token || '', formData, 'category', selectedCategory.id);
      setMediaFiles([]);
      showNotification('Media uploaded successfully', 'success');
      fetchCategories();
    } catch (error) {
      console.error('Error uploading media:', error);
      showNotification('Error uploading media', 'error');
    }
  };

  const handleMediaDelete = async (mediaId: number) => {
    try {
      await deleteMedia(token || '', mediaId);
      showNotification('Media deleted successfully', 'success');
      fetchCategories();
    } catch (error) {
      console.error('Error deleting media:', error);
      showNotification('Error deleting media', 'error');
    }
  };

  const showNotification = (message: string, type: 'success' | 'error') => {
    setNotification({ message, type });
    setTimeout(() => setNotification(null), 3000);
  };

  const resetForm = () => {
    setFormData({ 
      name: '', 
      image_url: '',
      description: '',
    });
    setSelectedCategory(null);
  };

  const handleEdit = (category: ProductCategory) => {
    setSelectedCategory(category);
    setFormData({
      name: category.name,
      image_url: category.image_url,
      description: category.description,
    });
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.form}>
        <h2>{selectedCategory ? 'Edit Category' : 'Add New Category'}</h2>
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.formGroup}>
            <label htmlFor="name" className={styles.formLabel}>Name:</label>
            <input
              type="text"
              id="name"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              required
              className={styles.formInput}
            />
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="media" className={styles.formLabel}>Upload Media:</label>
            <input type="file" multiple onChange={handleFileSelect} className={styles.formInput} />
            <button type="button" onClick={handleUpload} disabled={!mediaFiles.length || !selectedCategory} className={styles.submitButton}>
              Upload Media
            </button>
          </div>

          <div className={styles.buttonGroup}>
            <button type="submit" className={styles.submitButton}>
              {selectedCategory ? 'Update Category' : 'Create Category'}
            </button>
            {selectedCategory && (
              <button type="button" onClick={resetForm} className={styles.submitButton}>
                Cancel
              </button>
            )}
          </div>
        </form>
      </div>

      <div className={styles.categoriesList}>
        <h2>Categories</h2>
        {categories.map((category) => (
          <div key={category.id} className={styles.categoryCard}>
            <div className={styles.categoryHeader}>
              <h3>{category.name}</h3>
              <div className={styles.categoryActions}>
                <button 
                  className={styles.editButton}
                  onClick={() => handleEdit(category)}
                >
                  Edit
                </button>
                <button 
                  className={styles.deleteButton}
                  onClick={() => handleDelete(category.id)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {notification && (
        <div className={`${styles.notification} ${styles[notification.type]}`}>
          {notification.message}
        </div>
      )}
    </div>
  );
};

export default CategoryManager; 