import React, { useState, useEffect } from 'react';
import { Review, Auction, Invoice, Product, User, Media } from '../../../../types';
import { getReviews, createReview, updateReview, deleteReview, uploadMedia, getAuctions, getInvoices, getProducts, getUsers, getMedia } from '../../utils/adminAPI';
import styles from './Management.module.css';

const token = localStorage.getItem('token'); // or however you're storing the token

const AdminReviewComponent: React.FC = () => {
  const [reviews, setReviews] = useState<Review[]>([]);
  const [selectedReview, setSelectedReview] = useState<Review | null>(null);
  const [formData, setFormData] = useState<Partial<Review>>({
    user_id: undefined,
    auction_id: undefined,
    product_id: undefined,
    rating: 5,
    review_text: '',
    created_at: new Date().toISOString().split('T')[0],
  });
  const [mediaFiles, setMediaFiles] = useState<File[]>([]);
  const [auctions, setAuctions] = useState<Auction[]>([]);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [media, setMedia] = useState<Media[]>([]);
  const [selectedAuction, setSelectedAuction] = useState<Auction | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [visibleColumns, setVisibleColumns] = useState<string[]>([
    'id', 'auction_id', 'user', 'product', 'rating', 'review_text', 'image', 'created_at'
  ]);

  useEffect(() => {
    fetchReviews();
    fetchCompletedAuctions();
    fetchInvoices();
    fetchProducts();
    fetchUsers();
    fetchMedia();
  }, []);

  const fetchReviews = async () => {
    try {
      if (token) {
        const response = await getReviews(token);
        setReviews(response.data);
      }
    } catch (error) {
      console.error('Failed to fetch reviews:', error);
    }
  };

  const fetchCompletedAuctions = async () => {
    try {
      if (token) {
        const response = await getAuctions(token);
        const completedAuctions = response.data.filter(auction => auction.status === 'completed');
        setAuctions(completedAuctions);
      }
    } catch (error) {
      console.error('Failed to fetch completed auctions:', error);
    }
  };

  const fetchInvoices = async () => {
    try {
      if (token) {
        const response = await getInvoices(token);
        setInvoices(response.data);
      }
    } catch (error) {
      console.error('Failed to fetch invoices:', error);
    }
  };

  const fetchProducts = async () => {
    try {
      if (token) {
        const response = await getProducts(token);
        setProducts(response.data);
      }
    } catch (error) {
      console.error('Failed to fetch products:', error);
    }
  };

  const fetchUsers = async () => {
    try {
      if (token) {
        const response = await getUsers(token);
        setUsers(response.data);
      }
    } catch (error) {
      console.error('Failed to fetch users:', error);
    }
  };

  const fetchMedia = async () => {
    try {
      if (token) {
        const response = await getMedia(token);
        setMedia(response.data);
      }
    } catch (error) {
      console.error('Failed to fetch media:', error);
    }
  };

  useEffect(() => {
    if (selectedReview) {
      setFormData({ ...selectedReview });
    } else {
      setFormData({
        user_id: undefined,
        auction_id: undefined,
        product_id: undefined,
        rating: 5,
        review_text: '',
        created_at: new Date().toISOString().split('T')[0],
      });
    }
  }, [selectedReview]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleMediaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setMediaFiles(Array.from(e.target.files));
    }
  };

  const handleAuctionSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const auctionId = parseInt(e.target.value);
    const auction = auctions.find(a => a.id === auctionId) || null;
    const invoice = invoices.find(i => i.auction_id === auctionId) || null;
    const product = products.find(p => p.id === auction?.product_id) || null;

    setSelectedAuction(auction);
    if (auction && invoice && product) {
      setFormData({
        user_id: invoice.user_id,
        auction_id: auction.id,
        product_id: product.id,
        rating: 5,
        review_text: '',
        created_at: new Date().toISOString().split('T')[0],
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (token) {
        let reviewId;
        if (selectedReview) {
          await updateReview(token, selectedReview.id, formData as Review);
          reviewId = selectedReview.id;
        } else {
          const response = await createReview(token, formData as Review);
          reviewId = response.data.id;
        }

        if (mediaFiles.length > 0) {
          const formData = new FormData();
          mediaFiles.forEach((file, index) => {
            formData.append('media', file);
            formData.append(`order[${index}]`, index.toString());
          });
          formData.append('entity_type', 'review');
          formData.append('entity_id', reviewId.toString());

          await uploadMedia(token, formData, 'review', reviewId);
        }

        fetchReviews();
        setSelectedReview(null);
        setFormData({
          user_id: undefined,
          auction_id: undefined,
          product_id: undefined,
          rating: 5,
          review_text: '',
          created_at: new Date().toISOString().split('T')[0],
        });
        setMediaFiles([]);
        setIsModalOpen(false);
      }
    } catch (error) {
      console.error('Failed to submit review:', error);
    }
  };

  const handleEdit = (review: Review) => {
    setSelectedReview(review);
    setIsModalOpen(true);
  };

  const handleDelete = async (id: number) => {
    try {
      if (token) {
        const confirmDelete = window.confirm('Are you sure you want to delete this review?');
        if (confirmDelete) {
          await deleteReview(token, id);
          fetchReviews();
        }
      }
    } catch (error) {
      console.error('Failed to delete review:', error);
    }
  };

  const filteredReviews = reviews.filter(review => {
    const user = users.find(u => u.id === review.user_id);
    const product = products.find(p => p.id === review.product_id);
    return (
      (user?.username.toLowerCase().includes(searchText.toLowerCase()) || '') ||
      (product?.title.toLowerCase().includes(searchText.toLowerCase()) || '') ||
      (review.review_text?.toLowerCase().includes(searchText.toLowerCase()) || '')
    );
  });

  const toggleColumn = (column: string) => {
    setVisibleColumns(prev => 
      prev.includes(column) ? prev.filter(col => col !== column) : [...prev, column]
    );
  };

  return (
    <div className={styles.pageContainer}>
      <h2 className={styles.pageTitle}>Review Management</h2>
      
      <button onClick={() => setIsModalOpen(true)} className={styles.addButton}>Add Review</button>

      <input
        type="text"
        placeholder="Search by username, product title, or review text"
        value={searchText}
        onChange={e => setSearchText(e.target.value)}
        className={styles.searchInput}
      />

      {/* Column selection controls */}
      <div className={styles.columnSelector}>
        {['id', 'auction_id', 'user', 'product', 'rating', 'review_text', 'image', 'created_at'].map(column => (
          <label key={column}>
            <input
              type="checkbox"
              checked={visibleColumns.includes(column)}
              onChange={() => toggleColumn(column)}
            />
            {column.replace('_', ' ').toUpperCase()}
          </label>
        ))}
      </div>

      {isModalOpen && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <span className={styles.close} onClick={() => setIsModalOpen(false)}>&times;</span>
            <form onSubmit={handleSubmit} className={styles.form}>
              <select onChange={handleAuctionSelect} value={selectedAuction?.id || ''} className={styles.formInput}>
                <option value="">Select Completed Auction</option>
                {auctions
                  .filter(auction => !reviews.some(review => review.auction_id === auction.id))
                  .map(auction => {
                    const invoice = invoices.find(i => i.auction_id === auction.id);
                    const product = products.find(p => p.id === auction.product_id);
                    return (
                      <option key={auction.id} value={auction.id}>
                        {`Auction #${auction.id} - ${product?.title || 'Unknown Product'} - User #${invoice?.user_id || 'Unknown User'}`}
                      </option>
                    );
                  })}
              </select>
              <input
                type="number"
                name="rating"
                value={formData.rating}
                onChange={handleInputChange}
                min="1"
                max="5"
                placeholder="Rating (1-5)"
                className={styles.formInput}
              />
              <textarea
                name="review_text"
                value={formData.review_text}
                onChange={handleInputChange}
                placeholder="Review Text"
                className={styles.formInput}
              />

              <input
                type="file"
                multiple
                accept="image/*,video/*"
                onChange={handleMediaChange}
                className={styles.fileInput}
              />
              <input
                type="date"
                name="created_at"
                value={formData.created_at}
                onChange={handleInputChange}
                className={styles.formInput}
              />
              <button type="submit" className={styles.submitButton}>
                {selectedReview ? 'Update Review' : 'Create Review'}
              </button>
            </form>
          </div>
        </div>
      )}

      <table className={styles.table}>
        <thead>
          <tr>
            {visibleColumns.includes('id') && <th>ID</th>}
            {visibleColumns.includes('auction_id') && <th>Auction ID</th>}
            {visibleColumns.includes('user') && <th>User</th>}
            {visibleColumns.includes('product') && <th>Product</th>}
            {visibleColumns.includes('rating') && <th>Rating</th>}
            {visibleColumns.includes('review_text') && <th>Review Text</th>}
            {visibleColumns.includes('image') && <th>Image</th>}
            {visibleColumns.includes('created_at') && <th>Created At</th>}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredReviews.map((review) => {
            const user = users.find(u => u.id === review.user_id);
            const product = products.find(p => p.id === review.product_id);
            const reviewMedia = media.find(m => m.entity_id === review.id && m.entity_type === 'review');
            return (
              <tr key={review.id}>
                {visibleColumns.includes('id') && <td>{review.id}</td>}
                {visibleColumns.includes('auction_id') && <td>{review.auction_id}</td>}
                {visibleColumns.includes('user') && <td>{user?.username || 'Unknown User'}</td>}
                {visibleColumns.includes('product') && <td>{product?.title || 'Unknown Product'}</td>}
                {visibleColumns.includes('rating') && <td>{review.rating}</td>}
                {visibleColumns.includes('review_text') && <td>{review.review_text}</td>}
                {visibleColumns.includes('image') && <td>
                  {reviewMedia && (
                    <img src={reviewMedia.url} alt="Review" width="50" height="50" />
                  )}
                </td>}
                {visibleColumns.includes('created_at') && <td>{review.created_at}</td>}
                <td>
                  <button onClick={() => handleEdit(review)} className={styles.editButton}>Edit</button>
                  <button onClick={() => handleDelete(review.id)} className={styles.deleteButton}>Delete</button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AdminReviewComponent;