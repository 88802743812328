import React, { useState, useEffect } from 'react';
import { getWinners, deleteWinner, createWinner, updateWinner, getUsers, getAuctions, getProducts } from '../../utils/adminAPI';
import styles from './Management.module.css';
import { Auction, User, Product, Winner } from '../../../../types';


const WinnersManagement: React.FC = () => {
  const [winners, setWinners] = useState<Winner[]>([]);
  const [visibleColumns, setVisibleColumns] = useState<string[]>([
    'id', 'username', 'auction_id', 'product', 'bids_spent', 'date_selected'
  ]);
  const token = localStorage.getItem('token');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedWinner, setSelectedWinner] = useState<Winner | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [auctions, setAuctions] = useState<Auction[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [formData, setFormData] = useState<Omit<Winner, 'id' | 'users' | 'auctions'>>({
    user_id: 0,
    auction_id: 0,
    bids_spent: 0,
    date_selected: new Date().toISOString(),
  });

  useEffect(() => {
    fetchWinners();
    fetchUsers();
    fetchAuctions();
    fetchProducts();
  }, []);

  const fetchWinners = async () => {
    try {
      if (token) {
        const response = await getWinners(token);
        setWinners(response.data);
      }
    } catch (error) {
      console.error('Failed to fetch winners', error);
    }
  };

  const fetchUsers = async () => {
    try {
      if (token) {
        const response = await getUsers(token);
        setUsers(response.data);
      }
    } catch (error) {
      console.error('Failed to fetch users', error);
    }
  };

  const fetchAuctions = async () => {
    try {
      if (token) {
        const response = await getAuctions(token);
        const filteredAuctions = response.data.filter(
          auction => auction.status === 'active' || auction.status === 'upcoming'
        );
        setAuctions(filteredAuctions);
      }
    } catch (error) {
      console.error('Failed to fetch auctions', error);
    }
  };

  const fetchProducts = async () => {
    try {
      if (token) {
        const response = await getProducts(token);
        setProducts(response.data);
      }
    } catch (error) {
      console.error('Failed to fetch products', error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: name === 'bids_spent' ? Number(value) : value,
    }));
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (token) {
        if (selectedWinner) {
          await updateWinner(token, selectedWinner.id, {
            ...formData,
            user_id: formData.user_id || undefined,
            auction_id: formData.auction_id || undefined,
          });
        } else {
          await createWinner(token, {
            ...formData,
            user_id: formData.user_id,
            auction_id: formData.auction_id,
          });
        }
        fetchWinners();
        setIsModalOpen(false);
        setSelectedWinner(null);
        setFormData({
          user_id: 0,
          auction_id: 0,
          bids_spent: 0,
          date_selected: new Date().toISOString(),
        });
      }
    } catch (error) {
      console.error('Failed to submit winner', error);
    }
  };

  const handleEdit = (winner: Winner) => {
    setSelectedWinner(winner);
    // const { id, users, auctions, ...winnerData } = winner;
    setFormData(winner);
    setIsModalOpen(true);
  };

  const handleAddNewWinner = () => {
    setSelectedWinner(null);
    setFormData({
      user_id: 0,
      auction_id: 0,
      bids_spent: 0,
      date_selected: new Date().toISOString(),
    });
    setIsModalOpen(true);
  };

  const handleDelete = async (id: number) => {
    try {
      if (token) {
        const confirmDelete = window.confirm('Are you sure you want to delete this winner?');
        if (confirmDelete) {
          await deleteWinner(token, id);
          fetchWinners();
        }
      }
    } catch (error) {
      console.error('Failed to delete winner', error);
    }
  };

  const toggleColumn = (column: string) => {
    setVisibleColumns(prev => 
      prev.includes(column) ? prev.filter(col => col !== column) : [...prev, column]
    );
  };

  return (
    <div className={styles.pageContainer}>
      <h1 className={styles.pageTitle}>Manage Winners</h1>
      <button onClick={handleAddNewWinner} className={styles.addButton}>Add New Winner</button>

      <div className={styles.columnSelector}>
        {['id', 'username', 'product', 'bids_spent', 'date_selected'].map(column => (
          <label key={column}>
            <input
              type="checkbox"
              checked={visibleColumns.includes(column)}
              onChange={() => toggleColumn(column)}
            />
            {column.replace('_', ' ').toUpperCase()}
          </label>
        ))}
      </div>

      {isModalOpen && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <span className={styles.close} onClick={() => setIsModalOpen(false)}>&times;</span>
            <form onSubmit={handleFormSubmit} className={styles.form}>
              <label htmlFor="user_id" className={styles.formLabel}>User:</label>
              <select
                name="user_id"
                value={formData.user_id || ''}
                onChange={handleInputChange}
                required
                className={styles.formInput}
              >
                <option value="">Select a user</option>
                {users.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.username}
                  </option>
                ))}
              </select>

              <label htmlFor="auction_id" className={styles.formLabel}>Auction:</label>
              <select
                name="auction_id"
                value={formData.auction_id || ''}
                onChange={handleInputChange}
                required
                className={styles.formInput}
              >
                <option value="">Select an auction</option>
                {auctions
                  .sort((a, b) => new Date(a.start_time).getTime() - new Date(b.start_time).getTime())
                  .map((auction) => {
                    const product = products.find(p => p.id === auction.product_id);
                    return (
                      <option key={auction.id} value={auction.id}>
                        {`#${auction.id} - ${product?.title || 'Untitled'} (${auction.status}) - ${new Date(auction.start_time).toLocaleDateString()}`}
                      </option>
                    );
                  })}
              </select>

              <label htmlFor="bids_spent" className={styles.formLabel}>Bids Spent:</label>
              <input
                type="number"
                name="bids_spent"
                value={formData.bids_spent}
                onChange={handleInputChange}
                required
                min="0"
                className={styles.formInput}
              />

              <label htmlFor="date_selected" className={styles.formLabel}>Date Selected:</label>
              <input
                type="datetime-local"
                name="date_selected"
                value={formData.date_selected.slice(0, 16)}
                onChange={handleInputChange}
                required
                className={styles.formInput}
              />

              <button type="submit" className={styles.submitButton}>
                {selectedWinner ? 'Update Winner' : 'Create Winner'}
              </button>
            </form>
          </div>
        </div>
      )}

      <table className={styles.table}>
        <thead>
          <tr>
            {visibleColumns.includes('id') && <th>ID</th>}
            {visibleColumns.includes('auction_id') && <th>Auction ID</th>}
            {visibleColumns.includes('username') && <th>Username</th>}
            {visibleColumns.includes('product') && <th>Product</th>}
            {visibleColumns.includes('bids_spent') && <th>Bids Spent</th>}
            {visibleColumns.includes('date_selected') && <th>Date Selected</th>}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {winners.map((winner) => (
            <tr key={winner.id}>
              {visibleColumns.includes('id') && <td>{winner.id}</td>}
              {visibleColumns.includes('auction_id') && <td>{winner.auction_id}</td>}
              {visibleColumns.includes('username') && <td>{users.find(user => user.id === winner.user_id)?.username || 'Unknown'}</td>}
              {visibleColumns.includes('product') && <td>{products.find(product => product.id === auctions.find(auction => auction.id === winner.auction_id)?.product_id)?.title || 'Untitled'}</td>}
              {visibleColumns.includes('bids_spent') && <td>{winner.bids_spent}</td>}
              {visibleColumns.includes('date_selected') && <td>{new Date(winner.date_selected).toLocaleString()}</td>}
              <td>
                <button onClick={() => handleEdit(winner)} className={styles.editButton}>Edit</button>
                <button onClick={() => handleDelete(winner.id)} className={styles.deleteButton}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default WinnersManagement; 