// src/pages/ProductsPage.tsx
import React, { useState, useEffect } from 'react';
import { getProducts, createProduct, updateProduct, deleteProduct, uploadMedia, getCategories, deleteMedia, getMedia } from '../../utils/adminAPI';
import { Product, ProductCategory, Media } from '../../../../types';
import styles from './Management.module.css';
import Papa from 'papaparse';

const ProductsPage: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [categories, setCategories] = useState<ProductCategory[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState<Omit<Product, 'id' | 'media'>>({
    title: '',
    description: '',
    rrp: 0,
    postage: 0,
    stock: 0,
    category_id: 0,
  });
  const [mediaFiles, setMediaFiles] = useState<File[]>([]);
  const [media, setMedia] = useState<Media[]>([]);
  const token = localStorage.getItem('token');
  const [visibleColumns, setVisibleColumns] = useState<string[]>([
    'id', 'image', 'title', 'description', 'rrp', 'postage', 'stock', 'category'
  ]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<number | 'all'>('all');

  useEffect(() => {
    fetchProducts();
    fetchCategories();
    if (selectedProduct) {
      fetchMediaForProduct(selectedProduct.id);
    }
  }, [selectedProduct]);

  const fetchProducts = async () => {
    try {
      if (token) {
        const response = await getProducts(token);
        setProducts(response.data);
      }
    } catch (error) {
      console.error('Failed to fetch products', error);
    }
  };

  const fetchCategories = async () => {
    try {
      if (token) {
        const response = await getCategories(token);
        setCategories(response.data || []);
      }
    } catch (error) {
      console.error('Failed to fetch categories', error);
    }
  };

  const fetchMediaForProduct = async (productId: number) => {
    try {
      if (token) {
        const response = await getMedia(token);
        const filteredMedia = response.data.filter(m => m.entity_type === 'product' && m.entity_id === productId);
        setMedia(filteredMedia);
      }
    } catch (error) {
      console.error('Failed to fetch media for product', error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: name === 'rrp' || name === 'postage' || name === 'stock' ? Number(value) : value,
    }));
  };

  const handleMediaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setMediaFiles(Array.from(e.target.files));
    }
  };

  const handleMediaDelete = async (mediaId: number) => {
    try {
      if (token) {
        await deleteMedia(token, mediaId);
        setMedia(media.filter(media => media.id !== mediaId));
      }
    } catch (error) {
      console.error('Error deleting media', error);
    }
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (token) {
        let productId;
        if (selectedProduct) {
          const { title, description, rrp, postage, stock, category_id } = formData;
          const updateData = { title, description, rrp, postage, stock, category_id };
          await updateProduct(token, selectedProduct.id, updateData);
          productId = selectedProduct.id;
        } else {
          const response = await createProduct(token, formData);
          productId = response.data.id;
        }

        if (mediaFiles.length > 0) {
          const mediaFormData = new FormData();
          mediaFiles.forEach((file, index) => {
            mediaFormData.append('media', file);
            mediaFormData.append(`order[${index}]`, index.toString());
          });
          mediaFormData.append('entity_type', 'product');
          mediaFormData.append('entity_id', productId.toString());

          await uploadMedia(token, mediaFormData, 'product', productId);
        }

        fetchProducts();
        setSelectedProduct(null);
        setFormData({ title: '', description: '', rrp: 0, postage: 0, stock: 0, category_id: 0 });
        setMediaFiles([]);
        setIsModalOpen(false);
      }
    } catch (error) {
      console.error('Failed to submit product', error);
    }
  };

  const getProductImages = (product: Product) => {
    if (product.media && product.media.length > 0) {
      const sortedMedia = [...product.media].sort((a, b) => a.order - b.order);
      return sortedMedia.map(media => media.url);
    }
    return [`https://ushop.bid/uploads/product/${product.id}/product_${product.id}_1.jpeg`];
  };

  const handleEdit = (product: Product) => {
    console.log('Editing product:', product);
    setSelectedProduct(product);
    const { id, media, ...productWithoutIdAndMedia } = product;
    setFormData(productWithoutIdAndMedia);
    setIsModalOpen(true);
  };

  const handleDelete = async (id: number) => {
    try {
      if (token) {
        const confirmDelete = window.confirm('Are you sure you want to delete this product?');
        if (confirmDelete) {
          await deleteProduct(token, id);
          fetchProducts();
        }
      }
    } catch (error) {
      console.error('Failed to delete product', error);
    }
  };

  const handleAddNewProduct = () => {
    setSelectedProduct(null);
    setFormData({ title: '', description: '', rrp: 0, postage: 0, stock: 0, category_id: 0 });
    setMedia([]);
    setMediaFiles([]);
    setIsModalOpen(true);
  };

  const toggleColumn = (column: string) => {
    setVisibleColumns(prev => 
      prev.includes(column) ? prev.filter(col => col !== column) : [...prev, column]
    );
  };

  const processCSVRow = async (row: any, categories: ProductCategory[]) => {
    try {
      // Validate required fields
      const requiredFields = ['title', 'description', 'rrp', 'postage', 'stock', 'category_name'];
      const missingFields = requiredFields.filter(field => !row[field]);
      if (missingFields.length > 0) {
        throw new Error(`Missing required fields: ${missingFields.join(', ')}`);
      }

      // Validate numeric fields
      const numericFields = ['rrp', 'postage', 'stock'];
      numericFields.forEach(field => {
        if (isNaN(Number(row[field]))) {
          throw new Error(`Invalid numeric value for ${field}: ${row[field]}`);
        }
      });

      // Find category_id based on category_name
      const category = categories.find(c => c.name.toLowerCase() === row.category_name.toLowerCase());
      if (!category) {
        throw new Error(`Category not found: ${row.category_name}`);
      }

      const productData = {
        title: row.title,
        description: row.description,
        rrp: Number(row.rrp),
        postage: Number(row.postage),
        stock: Number(row.stock),
        category_id: category.id
      };

      // Check if product already exists by title
      const existingProduct = products.find(p => 
        p.title.toLowerCase() === row.title.toLowerCase()
      );

      let productId;
      if (existingProduct) {
        console.log(`Updating existing product: ${row.title}`);
        await updateProduct(token!, existingProduct.id, productData);
        productId = existingProduct.id;
        console.log(`Successfully updated product ID ${productId}`);
      } else {
        console.log(`Creating new product: ${row.title}`);
        const response = await createProduct(token!, productData);
        productId = response.data.id;
        console.log(`Successfully created product ID ${productId}`);
      }

      // Handle media upload if URL is provided
      if (row.media_url) {
        try {
          console.log(`Fetching media from URL: ${row.media_url}`);
          const imageResponse = await fetch(row.media_url);
          if (!imageResponse.ok) {
            throw new Error(`Failed to fetch image: ${imageResponse.statusText}`);
          }

          const blob = await imageResponse.blob();
          const file = new File([blob], `product_${productId}_image.jpg`, { type: 'image/jpeg' });

          const mediaFormData = new FormData();
          mediaFormData.append('media', file);
          mediaFormData.append('order[0]', '0');
          mediaFormData.append('entity_type', 'product');
          mediaFormData.append('entity_id', productId.toString());

          console.log(`Uploading media for product ID ${productId}`);
          await uploadMedia(token!, mediaFormData, 'product', productId);
          console.log(`Successfully uploaded media for product ID ${productId}`);
        } catch (mediaError) {
          console.error(`Failed to process media for product ${row.title}:`, mediaError);
          // Continue processing other rows even if media upload fails
        }
      }

      return true;
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Unknown error';
      console.error(`Error processing row for product "${row.title}":`, {
        error: errorMessage,
        rowData: row
      });
      return false;
    }
  };

  const handleCSVUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file || !token) return;

    setIsUploading(true);
    setUploadProgress(0);

    let successCount = 0;
    let errorCount = 0;

    Papa.parse(file, {
      header: true,
      complete: async (results) => {
        const totalRows = results.data.length;
        let processed = 0;

        console.log(`Starting CSV upload process. Total rows: ${totalRows}`);

        for (const row of results.data) {
          const success = await processCSVRow(row, categories);
          processed++;
          if (success) {
            successCount++;
          } else {
            errorCount++;
          }
          setUploadProgress((processed / totalRows) * 100);
        }

        setIsUploading(false);
        fetchProducts();
        
        const message = `CSV upload completed!\n` +
          `Successfully processed: ${successCount} products\n` +
          `Failed to process: ${errorCount} products\n` +
          `Please check the console for detailed error messages.`;
        
        alert(message);
      },
      error: (error) => {
        console.error('CSV parsing error:', error);
        setIsUploading(false);
        alert(`Error parsing CSV file: ${error.message}`);
      }
    });
  };

  const filteredProducts = products.filter(product => {
    const matchesSearch = product.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.description!.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCategory = selectedCategory === 'all' || product.category_id === selectedCategory;
    return matchesSearch && matchesCategory;
  });

  return (
    <div className={styles.pageContainer}>
      <h1 className={styles.pageTitle}>Manage Products</h1>
      <p className={styles.productCount}>Total Products: {filteredProducts.length}</p>
    
      <div className={styles.actionButtons}>
        <button onClick={handleAddNewProduct} className={styles.addButton}>
          Add New Product
        </button>
        <div className={styles.csvUpload}>
          <input
            type="file"
            accept=".csv"
            onChange={handleCSVUpload}
            id="csvUpload"
            style={{ display: 'none' }}
          />
          <label htmlFor="csvUpload" className={styles.csvUploadLabel}>
            Upload CSV
          </label>
          {isUploading && (
            <div className={styles.uploadProgress}>
              Uploading: {uploadProgress.toFixed(1)}%
            </div>
          )}
        </div>
      </div>
      <div className={styles.filterContainer}>
        <div className={styles.searchContainer}>
          <input
            type="text"
            placeholder="Search products..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className={styles.searchInput}
          />
        </div>
        <div className={styles.categoryFilter}>
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value === 'all' ? 'all' : Number(e.target.value))}
            className={styles.categorySelect}
          >
            <option value="all">All Categories</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      
      <div className={styles.columnSelector}>
        {['id', 'image', 'title', 'description', 'rrp', 'postage', 'stock', 'category'].map(column => (
          <label key={column}>
            <input
              type="checkbox"
              checked={visibleColumns.includes(column)}
              onChange={() => toggleColumn(column)}
            />
            {column.replace('_', ' ').toUpperCase()}
          </label>
        ))}
      </div>

      {isModalOpen && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <span className={styles.close} onClick={() => setIsModalOpen(false)}>&times;</span>
            <form onSubmit={handleFormSubmit} className={styles.form}>
              <label htmlFor="title" className={styles.formLabel}>Title:</label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                placeholder="Title"
                required
                className={styles.formInput}
              />
              
              <label htmlFor="description" className={styles.formLabel}>Description:</label>
              <textarea
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                placeholder="Description"
                required
                className={styles.formInput}
              />
              
              <label htmlFor="rrp" className={styles.formLabel}>RRP (pounds):</label>
              <input
                type="number"
                name="rrp"
                value={formData.rrp}
                onChange={handleInputChange}
                placeholder="Price (RRP)"
                step="0.01"
                required
                className={styles.formInput}
              />
              
              <label htmlFor="postage" className={styles.formLabel}>P&P (pennies):</label>
              <input
                type="number"
                name="postage"
                value={formData.postage}
                onChange={handleInputChange}
                placeholder="Postage Cost"
                step="0.01"
                required
                className={styles.formInput}
              />
              
              <label htmlFor="stock" className={styles.formLabel}>Stock:</label>
              <input
                type="number"
                name="stock"
                value={formData.stock}
                onChange={handleInputChange}
                placeholder="Stock"
                required
                className={styles.formInput}
              />
              
              <label htmlFor="category_id" className={styles.formLabel}>Category:</label>
              <select
                name="category_id"
                value={formData.category_id}
                onChange={handleInputChange}
                required
                className={styles.formInput}
              >
                <option value="">Select a category</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
              
              <div className={styles.formGroup}>
                <label htmlFor="media" className={styles.formLabel}>Product Images:</label>
                <input type="file" multiple onChange={handleMediaChange} className={styles.formInput} />
                <div className={styles.existingMedia}>
                  {media.map((mediaItem, index) => (
                    <div key={mediaItem.id} className={styles.mediaItem}>
                      <img src={mediaItem.url} alt={`Media ${index}`} style={{ width: '50px', height: '50px' }} />
                      <button type="button" onClick={() => handleMediaDelete(mediaItem.id)} className={styles.deleteButton}>Delete</button>
                    </div>
                  ))}
                </div>
              </div>

              <button type="submit" className={styles.submitButton}>
                {selectedProduct ? 'Update Product' : 'Create Product'}
              </button>
            </form>
          </div>
        </div>
      )}

      <table className={styles.table}>
        <thead>
          <tr>
            {visibleColumns.includes('id') && <th>ID</th>}
            {visibleColumns.includes('image') && <th>Image</th>}
            {visibleColumns.includes('title') && <th>Title</th>}
            {visibleColumns.includes('description') && <th>Description</th>}
            {visibleColumns.includes('rrp') && <th>RRP</th>}
            {visibleColumns.includes('postage') && <th>Postage</th>}
            {visibleColumns.includes('stock') && <th>Stock</th>}
            {visibleColumns.includes('category') && <th>Category</th>}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredProducts.map((product) => (
            <tr key={product.id}>
              {visibleColumns.includes('id') && <td>{product.id}</td>}
              {visibleColumns.includes('image') && <td>
                <img 
                  src={media.find(m => m.entity_id === product.id && m.entity_type === 'product')?.url || ''}
                  alt={`Product ${product.id}`} 
                  style={{ width: '50px', height: '50px' }} 
                />
              </td>}
              {visibleColumns.includes('title') && <td>{product.title}</td>}
              {visibleColumns.includes('description') && <td>{product.description}</td>}
              {visibleColumns.includes('rrp') && <td>{product.rrp}</td>}
              {visibleColumns.includes('postage') && <td>{product.postage}</td>}
              {visibleColumns.includes('stock') && <td>{product.stock}</td>}
              {visibleColumns.includes('category') && <td>{categories.find(cat => cat.id === product.category_id)?.name || 'Unknown'}</td>}
              <td>
                <button onClick={() => handleEdit(product)} className={styles.editButton}>Edit</button>
                <button onClick={() => handleDelete(product.id)} className={styles.deleteButton}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductsPage;
