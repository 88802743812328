import React, { useState, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useAuctionState } from "../../components/auctionStateContext";
import Loading from "../../components/loading";
import { Clock } from "lucide-react";
import styles from "./details.module.css";
import { getMedia } from "../../utils/coreAPI";

const AuctionDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const {
    auctions,
    products,
    bids,
    reviews,
    timers,
    lastBidders,
    isLoading,
    getCountdown,
    handleBid,
    getProductImage,
  } = useAuctionState();

  const auction = auctions.find((a) => a.id === parseInt(id!));
  const product = products.find((p) => p.id === auction?.product_id);
  const prodreviews = reviews.filter(
    (r) => r.product_id === product?.id || r.auction_id === auction?.id
  );
  const remainingTime = timers.get(parseInt(id!)) || 0;
  const isActive = auction?.status === "active";
  const isCompleted = auction?.status === "completed";

  const [reviewImages, setReviewImages] = useState<Map<number, string[]>>(
    new Map()
  );
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [isBidLoading, setIsBidLoading] = useState(false);

  useEffect(() => {
    // Close navbar when component mounts
    if (window.innerWidth > 768) { // Only for desktop
      localStorage.setItem('menuState', 'closed');
    }
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchReviewImages = async () => {
      if (product) {
        const imagesMap = new Map<number, string[]>();
        for (const review of prodreviews) {
          try {
            const response = await getMedia("review", review.id);
            imagesMap.set(
              review.id,
              response.data.map((media) => media.url)
            );
          } catch (error) {
            console.error(
              `Error fetching images for review ${review.id}:`,
              error
            );
          }
        }
        setReviewImages(imagesMap);
      }
    };

    fetchReviewImages();
  }, [ ]);

  const handleBidClick = async () => {
    setIsBidLoading(true);

    try {
      await handleBid(auction!.id);
    } finally {
      setIsBidLoading(false);
    }
  };

  // Function to calculate unique bidders for the auction
  const uniqueBiddersCount = (auctionId: number) => {
    const auctionBids = bids.filter((bid) => bid.auction_id === auctionId);
    const uniqueBidders = new Set(auctionBids.map((bid) => bid.user_id));
    return uniqueBidders.size;
  };

  // Fetch related auctions based on the product category
  const relatedAuctions = useMemo(() => {
    if (!product) return [];
    
    // Get the category ID of the current product
    const currentCategoryId = product.category_id;

    return auctions
      .filter((a) => {
        // Find the product associated with the auction
        const auctionProduct = products.find((p) => p.id === a.product_id);
        return (
          auctionProduct && // Ensure the product exists
          a.id !== auction?.id && // Exclude the current auction
          a.status !== "completed" && // Exclude completed auctions
          auctionProduct.category_id === currentCategoryId // Filter by the same category
        );
      })
      .sort((a, b) => new Date(a.start_time).getTime() - new Date(b.start_time).getTime()) // Sort by start time
      .slice(0, 3); // Get top 3
  }, [auctions, product, auction, products]);

  // Function to get product title by product ID
  const getProductTitleById = (productId: number | undefined) => {
    const relatedProduct = products.find((p) => p.id === productId);
    return relatedProduct ? relatedProduct.title : "Unknown Product";
  };

  if (isLoading) {
    return <Loading message="Loading auction details..." />;
  }

  if (!auction || !product) {
    return <div>Auction not found.</div>;
  }

  const lastFiveBidders = lastBidders.get(auction.id)?.slice(-5) || [];
  const formatTime = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const timeUnits = [];
    if (hours > 0) timeUnits.push(`${hours}h`);
    if (minutes > 0) timeUnits.push(`${minutes}m`);
    if (remainingSeconds > 0 || timeUnits.length === 0)
      timeUnits.push(`${remainingSeconds}s`);

    return timeUnits.join(" ");
  };

  const formatSTime = (date: Date): string => {
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const formatDate = (date: Date): string => {
    return date.toLocaleDateString("en-UK", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  const calculateSaving = (rrp: number, currentBid: number): string => {
    return `£${(rrp - currentBid / 100).toFixed(2)}`;
  };

  const handleThumbnailClick = (index: number) => {
    setSelectedImageIndex(index);
  };

  // Define watermark styles
  const watermarkStyle: React.CSSProperties = {
    position: 'relative',
    display: 'block',
  };

  const watermarkImageStyle: React.CSSProperties = {
    position: 'absolute',
    top: '0px',
    right: '0px',
    width: '16%',
    height: '16%',
    opacity: 1,
    zIndex: 1,
  };

  return (
    <div className={styles.auctionDetailsPage}>
      <div className={styles.auctionDetailsHeader}>
        <h1>Auction Details</h1>
        <div className={styles.auctionDetailsStatusContainer}>
          <div className={styles.auctionDetailsCountdown}>
            <span style={{ display: 'flex', alignItems: 'center' }}><Clock size={16} style={{ marginRight: '0.5rem' }} />{ formatTime(auction.countdown_timer)}</span>
          </div>
          <div 
            className={`${styles.auctionDetailsStatus} 
                        ${isActive ? styles.active : isCompleted ? styles.completed : styles.upcoming}`}>
            {isActive && "Active"}
            {isCompleted && "Completed"}
            {!isActive && !isCompleted && "Upcoming"}
          </div>
        </div>
      </div>
      <div className={styles.auctionDetailsContent}>
        <div className={styles.auctionDetailsImageContainer}>
          {product?.media &&
            product.media.length > 0 &&
            (product.media.length === 1 ? (
              <div style={watermarkStyle}>
                <img
                  src={product.media[0].url}
                  alt={`${product.title} - Main Image`}
                  className={styles.carouselMainImage}
                />
                <img
                  src="/uploads/logo-circle.png"
                  alt="Watermark"
                  style={watermarkImageStyle}
                />
              </div>
            ) : (
              <div className={styles.carouselContainer}>
                <div style={watermarkStyle}>
                  <img
                    src={product.media[selectedImageIndex].url}
                    alt={`${product.title} - Main Image`}
                    className={styles.carouselMainImage}
                  />
                  <img
                    src="/uploads/logo-circle.png"
                    alt="Watermark"
                    style={watermarkImageStyle}
                  />
                </div>
                <div className={styles.thumbnailContainer}>
                  {product.media.map((media, index) => (
                    <div
                      key={media.id}
                      className={`${styles.thumbnailItem} ${
                        index === selectedImageIndex ? "active" : ""
                      }`}
                      onClick={() => handleThumbnailClick(index)}
                    >
                      <img
                        src={media.url}
                        alt={`${product.title} - Thumbnail ${index + 1}`}
                        className={styles.thumbnailImage}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
        <div className={styles.auctionDetailsInfo}>
          <div className={styles.auctionDetailsHeader}>
            <h1>{product.title}</h1>
          </div>
          <p>{product.description}</p>
          <br />
          <div className={styles.auctionDetailsPrice}>
            <p>RRP: £{product.rrp}</p>
            <p>Postage: £{(product.postage / 100).toFixed(2)}</p>
            <br />
            <p>Savings: {calculateSaving(product.rrp, auction.current_bid)}</p>
            <p className={styles.currentBid}>
              Current Bid:{" "}
              <span>£{(auction.current_bid / 100).toFixed(2)}</span>
            </p>
          </div>
          <div className={styles.auctionTimer}>
            <Clock size={24} />
            {isActive
              ? auction.current_bid > 0
                ? formatTime(remainingTime)
                : formatTime(auction.countdown_timer)
              : isCompleted
              ? `Ended - Winner: ${
                  lastBidders.get(auction.id)?.at(0)?.username
                } on ${new Date(
                  lastBidders.get(auction.id)?.at(0)?.bid_time || ""
                ).toLocaleDateString("en-UK", {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                })} at ${new Date(
                  lastBidders.get(auction.id)?.at(0)?.bid_time || ""
                ).toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}`
              : `BID IN ${getCountdown(auction.start_time)}`}
          </div>
          {isActive ? (
            <button
              className={`${styles.auctionDetailsBidButton} animatedButton`}
              onClick={handleBidClick}
              disabled={isBidLoading}
            >
              {isBidLoading ? "Placing Bid..." : "Place Bid Now"}
            </button>
          ) : (
            <button className={`${styles.auctionDetailsBidButton} animatedButton`} disabled>
              {isCompleted ? "Started" : "Starts"}{" "}
              {formatDate(new Date(auction.start_time))}{" "}
              {formatSTime(new Date(auction.start_time))}
            </button>
          )}
          <div className={styles.auctionDetailsStartDate}>
            <p>Start Date: {formatDate(new Date(auction.start_time))}</p>
          </div>
        </div>
      </div>
      {lastFiveBidders.length > 0 && (
        <>
          <h2 className={styles.sectionTitle}>
            Last 5 Bidders of{" "}
            <span style={{ color: "var(--primary-red)", fontSize: "1.5rem", fontWeight: "bold" }}>
              {uniqueBiddersCount(auction.id)}
            </span>
          </h2>
          <ul className={styles.auctionDetailsBidHistory}>
            {lastFiveBidders.map((bidder, index) => (
              <li key={index} className={styles.auctionDetailsBidItem}>
                <span style={{ color: "red", float: "left" }}>
                  {bidder.username}
                </span>
                <span style={{ float: "right" }}>
                  <span style={{ color: "darkgray" }}>
                    {new Date(bidder.bid_time).toLocaleDateString()}
                  </span>{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {new Date(bidder.bid_time).toLocaleTimeString()}
                  </span>
                </span>
              </li>
            ))}
          </ul>
        </>
      )}

      {prodreviews.length > 0 && (
        <>
          <h2 className={styles.sectionTitle}>Reviews</h2>
          <ul className={styles.auctionDetailsReviews}>
            {prodreviews.map((review, index) => (
              <li key={index} className={styles.auctionDetailsReviewItem}>
                <div className={styles.auctionDetailsReviewImageContainer}>
                  {reviewImages.get(review.id)?.map((imageUrl, imgIndex) => (
                    <img
                      key={imgIndex}
                      src={imageUrl}
                      alt={`Review ${review.id} image`}
                      className={styles.auctionDetailsReviewImage}
                    />
                  ))}
                </div>
                <div className={styles.auctionDetailsReviewContent}>
                  <div className={styles.auctionDetailsReviewHeader}>
                    <span className={styles.auctionDetailsReviewRating}>
                      {"★".repeat(review.rating)}
                      {"☆".repeat(5 - review.rating)}
                    </span>
                    <span className={styles.auctionDetailsReviewTime}>
                      <span style={{ color: "darkgray" }}>
                        {new Date(review.created_at).toLocaleDateString()}
                      </span>{" "}
                      <span style={{ fontWeight: "bold" ,color: "black"}}>
                        {new Date(review.created_at).toLocaleTimeString()}
                      </span>
                    </span>
                  </div>
                  <div className={styles.auctionDetailsReviewText}>
                    {review.review_text}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </>
      )}

      {/* Related Auctions Section */}
      {relatedAuctions.length > 0 && (
        <div className={styles.relatedAuctionsSection}>
          <h2 className={styles.sectionTitle}>Related Auctions</h2>
          <div className='dynamicGrid'>
            {relatedAuctions.map((relatedAuction) => (
              <div key={relatedAuction.id} className='gridItem'>
                <Link 
                  to={`/auction/${relatedAuction.id}`} 
                  className={styles.relatedAuctionLink}
                >
                  <div style={watermarkStyle}>
                    <img 
                      src={getProductImage(products.find((p) => p.id === relatedAuction.product_id)!)}
                      alt={`Product ${relatedAuction.product_id}`}
                      className={styles.relatedAuctionImage}
                    />
                    <img
                      src="/uploads/logo-circle.png"
                      alt="Watermark"
                      style={watermarkImageStyle}
                    />
                  </div>
                  <h3 className={styles.relatedAuctionTitle} style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginBottom: '1rem'}}>
                    {getProductTitleById(relatedAuction.product_id)}
                  </h3>
                  <div className="Info">
                    <span className="infoLabel">Status:</span>
                    <span className="infoValue">{relatedAuction.status}</span>
                  </div>
                  <div className="Info">
                    <span className="infoLabel">Current Bid:</span>
                    <span className="infoValue">£{(relatedAuction.current_bid / 100).toFixed(2)}</span>
                  </div>
                  <div className="Info">
                    <span className="infoLabel">Starts on:</span>
                    <span className="infoValue">{new Date(relatedAuction.start_time).toLocaleString()}</span>
                  </div>
                  
                  <Link 
                    to={`/auction/${relatedAuction.id}`} 
                    className={`${styles.viewAuctionButton} animatedButton`}
                  >
                    View Auction
                  </Link>
                </Link>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
    
  );
};

export default AuctionDetailsPage;
