import React, { useState, useEffect } from 'react';
import { getUsers, getBids, getAuctions, getProducts } from '../../utils/adminAPI';
import styles from './Management.module.css';
import { User, Bid, Auction } from '../../../../types';
// import { FaSearch, FaSortAmountDown, FaSortAmountUp } from 'react-icons/fa';

interface AuctionWithProduct extends Auction {
  products?: {
    title: string;
  };
}

interface UserStats {
  user: User;
  totalBids: number;
  totalCreditsSpent: number;
  auctionsParticipated: AuctionWithProduct[];
  winRate: number;
}

const UsageManagement: React.FC = () => {
  const [userStats, setUserStats] = useState<UserStats[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState<{
    key: keyof UserStats | 'username';
    direction: 'asc' | 'desc';
  }>({ key: 'totalBids', direction: 'desc' });

  const [token] = useState(localStorage.getItem('token') || '');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const [usersResponse, bidsResponse, auctionsResponse, productsResponse] = await Promise.all([
        getUsers(token),
        getBids(token),
        getAuctions(token),
        getProducts(token)
      ]);

      const users = usersResponse.data.filter(user => user.type !== 5);
      const bids = bidsResponse.data;
      const auctions = auctionsResponse.data;
      const products = productsResponse.data;

      // Create a map of product data for quick lookup
      const productMap = products.reduce((acc, product) => {
        acc[product.id] = product;
        return acc;
      }, {} as { [key: number]: typeof products[0] });

      const stats: UserStats[] = users.map(user => {
        const userBids = bids.filter(bid => bid.user_id === user.id);
        const participatedAuctions = auctions
          .filter(auction => userBids.some(bid => bid.auction_id === auction.id))
          .map(auction => ({
            ...auction,
            products: productMap[auction.product_id || 0]
          }));
        
        const wonAuctions = participatedAuctions.filter(auction => 
          auction.status === 'completed'  
        //   auction.winner_id === user.id
        );

        return {
          user,
          totalBids: userBids.length,
          totalCreditsSpent: user.credits || 0,
          auctionsParticipated: participatedAuctions,
          winRate: participatedAuctions.length ? 
            (wonAuctions.length / participatedAuctions.length) * 100 : 0
        };
      });

      setUserStats(stats);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const handleSort = (key: keyof UserStats | 'username') => {
    setSortConfig({
      key,
      direction: 
        sortConfig.key === key && sortConfig.direction === 'asc' 
          ? 'desc' 
          : 'asc'
    });
  };

  const sortedStats = [...userStats].sort((a, b) => {
    if (sortConfig.key === 'username') {
      const valueA = a.user.username.toLowerCase();
      const valueB = b.user.username.toLowerCase();
      return sortConfig.direction === 'asc' 
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    }

    const valueA = sortConfig.key === 'auctionsParticipated' 
      ? a[sortConfig.key].length 
      : a[sortConfig.key];
    const valueB = sortConfig.key === 'auctionsParticipated' 
      ? b[sortConfig.key].length 
      : b[sortConfig.key];

    return sortConfig.direction === 'asc' 
      ? Number(valueA) - Number(valueB)
      : Number(valueB) - Number(valueA);
  });

  const filteredStats = sortedStats.filter(stat => 
    stat.user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
    stat.user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const SortIcon = ({ active }: { active: boolean }) => (
    <span className={styles.sortIcon}>
      {sortConfig.direction === 'asc' ? '▲' : '▼'}
    </span>
  );

  if (loading) return <div>Loading...</div>;

  return (
    <div className={styles.pageContainer}>
      <h1 className={styles.pageTitle}>Usage Statistics</h1>

      <div className={styles.searchContainer}>
        <input
          type="text"
          placeholder="Search by username or email..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className={styles.searchInput}
        />
      </div>

      <table className={styles.table}>
        <thead>
          <tr>
            <th onClick={() => handleSort('username')}>
              Username {sortConfig.key === 'username' && <SortIcon active={true} />}
            </th>
            <th onClick={() => handleSort('totalBids')}>
              Total Bids {sortConfig.key === 'totalBids' && <SortIcon active={true} />}
            </th>
            <th onClick={() => handleSort('totalCreditsSpent')}>
              Credits Spent {sortConfig.key === 'totalCreditsSpent' && <SortIcon active={true} />}
            </th>
            <th onClick={() => handleSort('auctionsParticipated')}>
              Auctions Participated {sortConfig.key === 'auctionsParticipated' && <SortIcon active={true} />}
            </th>
            <th onClick={() => handleSort('winRate')}>
              Win Rate {sortConfig.key === 'winRate' && <SortIcon active={true} />}
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredStats.map((stat) => (
            <tr key={stat.user.id}>
              <td>{stat.user.username}</td>
              <td>{stat.totalBids}</td>
              <td>{stat.totalCreditsSpent}</td>
              <td>
                {stat.auctionsParticipated.length}
                <div className={styles.auctionsList}>
                  {stat.auctionsParticipated.map(auction => (
                    <div key={auction.id} className={styles.auctionItem}>
                      ID: {auction.id} - {auction.products?.title || 'Unknown Product'}
                      <br />
                      Status: {auction.status}
                    </div>
                  ))}
                </div>
              </td>
              <td>{stat.winRate.toFixed(2)}%</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UsageManagement;
