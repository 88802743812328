import React, { useEffect, useState, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAuctionState } from '../../components/auctionStateContext';
import { PayPalButtons } from "@paypal/react-paypal-js";
import { useError } from '../../components/ErrorContext';
import styles from './details.module.css';
import { Product, Review } from '../../../../types';
import { createPayPalOrder } from '../../utils/accountAPI';
import { getMedia } from '../../utils/coreAPI';

const MarketDetailsPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { products, reviews, isLoading,getProductImage } = useAuctionState();
    const { showError } = useError();
    const [product, setProduct] = useState<Product | null>(null);
    const [productReviews, setProductReviews] = useState<Review[]>([]);
    const [reviewImages, setReviewImages] = useState<Map<number, string[]>>(new Map());
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    useEffect(() => {
        const productData = products.find(p => p.id === parseInt(id!));
        const reviewsData = reviews.filter(r => r.product_id === parseInt(id!));
        setProduct(productData || null);
        setProductReviews(reviewsData);
    }, [id, products, reviews]);

    useEffect(() => {
        const fetchReviewImages = async () => {
            if (product) {
                const imagesMap = new Map<number, string[]>();
                for (const review of productReviews) {
                    try {
                        const response = await getMedia('review', review.id);
                        imagesMap.set(review.id, response.data.map(media => media.url));
                    } catch (error) {
                        console.error(`Error fetching images for review ${review.id}:`, error);
                    }
                }
                setReviewImages(imagesMap);
            }
        };

        fetchReviewImages();
    }, [productReviews]);

    const handleThumbnailClick = (index: number) => {
        setSelectedImageIndex(index);
    };

    const handlePayPalPurchase = async () => {
        try {
            const totalAmount = Number(product?.rrp || 0) + Number((product?.postage || 0) / 100);
            const formattedTotal = totalAmount.toFixed(2);
            const response = await createPayPalOrder(
                Number(formattedTotal),
                'GBP',
                undefined,
                product?.id,
                undefined,
                'market'
            );
            return response.id;
        } catch (err) {
            console.error('Error creating PayPal order:', err);
            showError('Failed to create order. Please try again.', 'error');
            throw err;
        }
    };

    // Fetch related auctions based on the product category
    const relatedAuctions = useMemo(() => {
        if (!product) return [];
        
        // Get the category ID of the current product
        const currentCategoryId = product.category_id;

        return products
            .filter((p) => p.category_id === currentCategoryId && p.id !== product.id) // Exclude current product
            .slice(0, 3); // Get top 3
    }, [products, product]);

    if (isLoading) return <div className={styles.auctionDetailsLoading}>Loading...</div>;
    if (!product) return <div className={styles.auctionDetailsError}>Product not found</div>;

    return (
        <div className={styles.auctionDetailsPage}>
            <div className={styles.auctionDetailsHeader}>
                <h1>{product.title}</h1>
            </div>
            <div className={styles.auctionDetailsContent}>
                <div className={styles.auctionDetailsImageContainer}>
                    {product?.media && product.media.length > 0 && (
                        product.media.length === 1 ? (
                            <div style={watermarkStyle}>
                                <img
                                    src={product.media[0].url}
                                    alt={`${product.title} - Main Image`}
                                    className={styles.carouselMainImage}
                                />
                                <img
                                    src="/uploads/logo-circle.png"
                                    alt="Watermark"
                                    style={watermarkImageStyle}
                                />
                            </div>
                        ) : (
                            <div className={styles.carouselContainer}>
                                <div style={watermarkStyle}>
                                    <img
                                        src={product.media[selectedImageIndex].url}
                                        alt={`${product.title} - Main Image`}
                                        className={styles.carouselMainImage}
                                    />
                                    <img
                                        src="/uploads/logo-circle.png"
                                        alt="Watermark"
                                        style={watermarkImageStyle}
                                    />
                                </div>
                                <div className={styles.thumbnailContainer}>
                                    {product.media.map((media, index) => (
                                        <div
                                            key={media.id}
                                            className={`${styles.thumbnailItem} ${index === selectedImageIndex ? 'active' : ''}`}
                                            onClick={() => handleThumbnailClick(index)}
                                        >
                                            <img
                                                src={media.url}
                                                alt={`${product.title} - Thumbnail ${index + 1}`}
                                                className={styles.thumbnailImage}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )
                    )}
                </div>
                <div className={styles.auctionDetailsInfo}>
                    <div className={styles.auctionDetailsReviews}>
                        <h2>Product Description</h2>
                        <p>{product.description}</p>
                    </div>
                    <div className={styles.auctionDetailsReviews}>
                        <div className={styles.auctionDetailsPrice}>
                            <span>Price: £{(product.rrp)}</span> <br />
                            <span>Postage: £{(product.postage / 100)}</span>
                        </div>
                    </div>
                    <div className={styles.paypalContainer}>
                        <PayPalButtons
                            createOrder={handlePayPalPurchase}
                            onApprove={(data, actions) => {
                                return handlePayPalPurchase().then(() => {
                                    return;
                                });
                            }}
                            onError={(err) => {
                                console.error('PayPal error:', err);
                                showError('Payment failed. Please try again.', 'error');
                            }}
                        />
                    </div>
                </div>

                {productReviews.length > 0 && (
                    <>
                        <h2>Product Reviews</h2>
                        <ul className={styles.auctionDetailsReviews}>
                            {productReviews.map((review, index) => (
                                <li key={index} className={styles.auctionDetailsReviewItem}>
                                    <div className={styles.auctionDetailsReviewImageContainer}>
                                        {reviewImages.get(review.id)?.map((imageUrl, imgIndex) => (
                                            <img key={imgIndex} src={imageUrl} alt={`Review ${review.id} image`} className={styles.auctionDetailsReviewImage} />
                                        ))}
                                    </div>
                                    <div className={styles.auctionDetailsReviewContent}>
                                        <div className={styles.auctionDetailsReviewHeader}>
                                            <span className={styles.auctionDetailsReviewRating}>
                                                {'★'.repeat(review.rating)}{'☆'.repeat(5 - review.rating)}
                                            </span>
                                            <span className={styles.auctionDetailsReviewTime}>
                                                {new Date(review.created_at).toLocaleString()}
                                            </span>
                                        </div>
                                        <div className={styles.auctionDetailsReviewText}>
                                            {review.review_text}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </>
                )}

               
            </div>
             {/* Related Auctions Section */}
             {relatedAuctions.length > 0 && (
                    <div className={styles.relatedAuctionsSection}>
                        <h2 className={styles.sectionTitle}>Related Products</h2>
                        <div className='dynamicGrid'>
                            {relatedAuctions.map((relatedAuction) => (
                                <div key={relatedAuction.id} className='gridItem'>
                                    <a 
                                        href={`/auction/${relatedAuction.id}`} 
                                        className={styles.relatedAuctionLink} // Add a class for styling
                                    >
                                        <img 
                                            src={getProductImage(products.find((p) => p.id === relatedAuction.id)!)}
                                            alt={`Product ${relatedAuction.id}`}
                                            className={styles.relatedAuctionImage}
                                        />
                                        <h3 className={styles.relatedAuctionTitle} style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginBottom: '1rem'}}>{(relatedAuction.title)}</h3>
                                        <div className="Info">
                                            <span className="infoLabel">RRP:</span>
                                            <span className="infoValue">{relatedAuction.rrp}</span>
                                        </div>
                                        <div className="Info">
                                            <span className="infoLabel">P&P:</span>
                                            <span className="infoValue">£{(relatedAuction.postage / 100).toFixed(2)}</span>
                                        </div>
                           
                                        
                                        <Link 
                                            to={`/market/${relatedAuction.id}`} 
                                            className={styles.viewAuctionButton}
                                        >
                                            View Product
                                        </Link>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
        </div>
    );
};

// Define watermark styles
const watermarkStyle: React.CSSProperties = {
    position: 'relative',
    display: 'block',
};

const watermarkImageStyle: React.CSSProperties = {
    position: 'absolute',
    top: '0px',
    right: '0px',
    width: '16%',
    height: '16%',
    opacity: 1,
    zIndex: 1,
};

export default MarketDetailsPage;
