import React from 'react';
import { ProductCategory } from '../../../types';
import { ChevronRight, Tag, Search } from 'lucide-react';
import styles from './CategoryFilterPanel.module.css';

interface CategoryFilterPanelProps {
  categories: ProductCategory[];
  selectedCategory: number | null;
  onCategoryChange: (categoryId: number | null) => void;
}

const CategoryFilterPanel: React.FC<CategoryFilterPanelProps> = ({
  categories,
  selectedCategory,
  onCategoryChange,
}) => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  
  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className={styles.filterPanel}>
      <div 
        className={styles.filterHeader} 
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <Tag size={20} />
        <h3>Categories</h3>
        <ChevronRight size={20} className={`${styles.arrow} ${isCollapsed ? styles.collapsed : ''}`} />
      </div>
      
      {!isCollapsed && (
        <>
          <div className={styles.searchContainer}>
            {/* <Search size={16} className={styles.searchIcon} /> */}
            <input
              type="text"
              placeholder="Search categories..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className={styles.searchInput}
            />
          </div>

          <div className={styles.categoryList}>
            <button
              className={`${styles.categoryItem} ${!selectedCategory ? styles.active : ''}`}
              onClick={() => onCategoryChange(null)}
            >
              <span>All Products</span>
              <ChevronRight size={16} className={styles.arrow} />
            </button>
            
            {filteredCategories.map((category) => (
              <button
                key={category.id}
                className={`${styles.categoryItem} ${selectedCategory === category.id ? styles.active : ''}`}
                onClick={() => onCategoryChange(category.id)}
              >
                <div className={styles.categoryContent}>
                  {category.image_url && (
                    <img 
                      src={category.image_url} 
                      alt={category.name} 
                      className={styles.categoryIcon}
                    />
                  )}
                  <span>{category.name}</span>
                </div>
                <ChevronRight size={16} className={styles.arrow} />
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default CategoryFilterPanel; 