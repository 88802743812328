import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import AuctionList from '../components/AuctionList';
import AuctionForm from '../components/AuctionForm';
import BatchAuctionScheduler from '../components/BatchAuctionScheduler';
import { getAuctions, createBatchAuctions } from '../../utils/adminAPI';
import { Auction } from '../../../../types';

Modal.setAppElement('#root'); // Set the app element for accessibility

const AuctionsPage: React.FC = () => {
  const [auctions, setAuctions] = useState<Auction[]>([]);
  const [selectedAuction, setSelectedAuction] = useState<Auction | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchAuctions();
  }, []);

  const fetchAuctions = async () => {
    try {
      if (token) {
        const response = await getAuctions(token);
        setAuctions(response.data);
      }
    } catch (error) {
      console.error('Failed to fetch auctions', error);
    }
  };

  const handleEdit = (auction: Auction) => {
    setSelectedAuction(auction);
    setIsModalOpen(true);
  };

  const handleFormSubmit = () => {
    fetchAuctions();
    setSelectedAuction(null);
    setIsModalOpen(false);
  };

  const handleBatchSchedule = async (scheduledAuctions: Partial<Auction>[]) => {
    try {
      if (token) {
        const response = await createBatchAuctions(token, scheduledAuctions);
        console.log('Batch auctions created:', response.data);
        fetchAuctions();
      }
    } catch (error) {
      console.error('Failed to create batch auctions', error);
    }
  };

  return (
    <div>
      <h1>Manage Auctions</h1>
      <button onClick={() => setIsModalOpen(true)}>Create New Auction</button>
      <BatchAuctionScheduler onSubmit={handleBatchSchedule} />
      <AuctionList auctions={auctions} onEdit={handleEdit} fetchAuctions={fetchAuctions} />

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Auction Form"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: '600px',
          },
        }}
      >
        <AuctionForm selectedAuction={selectedAuction} onSubmit={handleFormSubmit} />
      </Modal>
    </div>
  );
};

export default AuctionsPage;