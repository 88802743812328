import React, { useState, useEffect } from 'react';
import { Auction, Product, ProductCategory } from '../../../../types';
import { createAuction, updateAuction, getProducts, createProduct, createCategory, uploadMedia } from '../../utils/adminAPI';
// import './inv.css'; // Ensure the path is correct based on your directory structure

interface AuctionFormProps {
  selectedAuction: Auction | null;
  onSubmit: () => void;
}

const AuctionForm: React.FC<AuctionFormProps> = ({ selectedAuction, onSubmit }) => {
  const [formData, setFormData] = useState<Omit<Auction, 'id'>>({
    product_id: 0,
    umin: 0,
    start_time: '',
    countdown_timer: 30,
    current_bid: 0,
    status: 'upcoming',
    bmax: 0,
    views: 0
  });

  const [products, setProducts] = useState<Product[]>([]);
  const token = localStorage.getItem('token'); // or however you're storing the token

  const [newProduct, setNewProduct] = useState<Omit<Product, 'id'>>({
    title: '',
    description: '',
    rrp: 0,
    category_id: 0,
    stock: 1,
    media: [],
    postage: 0
  });

  const [newCategory, setNewCategory] = useState<Omit<ProductCategory, 'id'>>({
    name: '',
    description: '',
    image_url: ''
  });

  const [mediaFiles, setMediaFiles] = useState<File[]>([]);

  useEffect(() => {
    fetchProducts();

    if (selectedAuction) {
      setFormData({
        ...selectedAuction,
        start_time: selectedAuction.start_time.slice(0, 16) || getCurrentDateTime()
      });
    } else {
      setFormData({
        product_id: 0,
        umin: 0,
        start_time: getCurrentDateTime(),
        countdown_timer: 30,
        current_bid: 0,
        status: 'upcoming',
        bmax: 0,
        views: 0
      });
    }
  }, [selectedAuction]);

  const fetchProducts = async () => {
    try {
      if (token) {
        const response = await getProducts(token);
        setProducts(response.data);
      }
    } catch (error) {
      console.error('Error fetching products', error);
    }
  };

  const handleProductSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedProductId = parseInt(e.target.value, 10);
    const selectedProduct = products.find(product => product.id === selectedProductId);

    if (selectedProduct) {
      const defaultUmin = Math.round((selectedProduct.rrp * 0.03)*100);
      const defaultBmax = Math.round((selectedProduct.rrp * 0.10)*100);

      setFormData(prevData => ({
        ...prevData,
        product_id: selectedProductId,
        umin: defaultUmin,
        bmax: defaultBmax
      }));
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    const parsedValue = ['product_id', 'umin', 'countdown_timer', 'current_bid', 'bmax'].includes(name)
      ? parseInt(value, 10)
      : value;
    
    setFormData(prevData => ({
      ...prevData,
      [name]: parsedValue,
    }));
  };

  const handleNewProductChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setNewProduct(prev => ({ ...prev, [name]: value }));
  };

  const handleNewCategoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewCategory(prev => ({ ...prev, [name]: value }));
  };

  const handleMediaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setMediaFiles(Array.from(e.target.files));
    }
  };

  const handleAddProduct = async () => {
    try {
      const response = await createProduct(token!, newProduct);
      setProducts([...products, response.data]);

      if (mediaFiles.length > 0) {
        const formData = new FormData();
        mediaFiles.forEach(file => formData.append('files', file));

        await uploadMedia(token!, formData, 'product', response.data.id);
      }

      setNewProduct({ title: '', description: '', rrp: 0, category_id: 0, stock: 1, media: [], postage: 0 });
      setMediaFiles([]);
    } catch (error) {
      console.error('Error adding product', error);
    }
  };

  const handleAddCategory = async () => {
    try {
      const response = await createCategory(token!, newCategory);
      setNewCategory({ name: '', description: '', image_url: '' });
    } catch (error) {
      console.error('Error adding category', error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (token) {
        if (selectedAuction) {
          await updateAuction(token, selectedAuction.id, formData as Auction);
        } else {
          await createAuction(token, formData);
        }
        onSubmit();
      }
    } catch (error) {
      console.error('Error saving auction', error);
    }
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    return now.toISOString().slice(0, 16);
  };

  return (
    <form onSubmit={handleSubmit} style={{paddingTop: '20%', maxHeight: 'fit-content', margin: '0 auto', padding: '20px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: '#f9f9f9' }}>
      <div style={{ marginBottom: '15px' }}>
        <h3 style={{ textAlign: 'center' }}>{selectedAuction ? 'Update Auction' : 'Create Auction'}</h3>
        <label htmlFor="product_id" style={{ display: 'block', marginBottom: '5px' }}>Product:</label>
        <select
          id="product_id"
          name="product_id"
          value={formData.product_id}
          onChange={handleProductSelect}
          required
          style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
        >
          <option value="">Select a product</option>
          {products.map((product) => (
            <option key={product.id} value={product.id}>
              {product.id} - {product.title} - £x{product.rrp}
            </option>
          ))}
        </select>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <label htmlFor="umin" style={{ display: 'block', marginBottom: '5px' }}>Minimum Bids(pence):</label>
        <input
          type="number"
          id="umin"
          name="umin"
          value={formData.umin}
          onChange={handleChange}
          placeholder="Minimum Bids"
          required
          style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
        />
      </div>
      <div style={{ marginBottom: '15px' }}>
        <label htmlFor="bmax" style={{ display: 'block', marginBottom: '5px' }}>Bmax(pence):</label>
        <input
          type="number"
          id="bmax"
          name="bmax"
          value={formData.bmax}
          onChange={handleChange}
          placeholder="Max B Bids"
          required
          style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
        />
      </div>
      <div style={{ marginBottom: '15px' }}>
        <label htmlFor="start_time" style={{ display: 'block', marginBottom: '5px' }}>Start Time:</label>
        <input
          type="datetime-local"
          id="start_time"
          name="start_time"
          value={formData.start_time}
          onChange={handleChange}
          placeholder={formData.start_time}
          required
          style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
        />
      </div>
      <div style={{ marginBottom: '15px' }}>
        <label htmlFor="countdown_timer" style={{ display: 'block', marginBottom: '5px' }}>Countdown Timer (seconds):</label>
        <input
          type="number"
          id="countdown_timer"
          name="countdown_timer"
          value={formData.countdown_timer}
          onChange={handleChange}
          placeholder="Countdown Timer"
          required
          style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
        />
      </div>
      <div style={{ marginBottom: '15px' }}>
        <label htmlFor="current_bid" style={{ display: 'block', marginBottom: '5px' }}>Current Bid(pennce):</label>
        <input
          type="number"
          id="current_bid"
          name="current_bid"
          value={formData.current_bid}
          onChange={handleChange}
          placeholder="Current Bid"
          required
          style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
        />
      </div>
      <div style={{ marginBottom: '15px' }}>
        <label htmlFor="status" style={{ display: 'block', marginBottom: '5px' }}>Status:</label>
        <select
          id="status"
          name="status"
          value={formData.status}
          onChange={handleChange}
          style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
        >
          <option value="upcoming">Upcoming</option>
          <option value="active">Active</option>
          <option value="completed">Completed</option>
        </select>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <h4>Add New Product</h4>
        <input type="text" name="title" value={newProduct.title} onChange={handleNewProductChange} placeholder="Product Title" style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', marginBottom: '5px' }} />
        <input type="text" name="description" value={newProduct.description} onChange={handleNewProductChange} placeholder="Description" style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', marginBottom: '5px' }} />
        <input type="number" name="rrp" value={newProduct.rrp} onChange={handleNewProductChange} placeholder="RRP" style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', marginBottom: '5px' }} />
        <input type="number" name="postage" value={newProduct.postage} onChange={handleNewProductChange} placeholder="Postage" style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', marginBottom: '5px' }} />
        <input type="file" multiple onChange={handleMediaChange} style={{ marginBottom: '5px' }} />
        <button type="button" onClick={handleAddProduct} style={{ padding: '10px 20px', borderRadius: '4px', border: 'none', backgroundColor: '#007bff', color: '#fff', cursor: 'pointer' }}>Add Product</button>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <h4>Add New Category</h4>
        <input type="text" name="name" value={newCategory.name} onChange={handleNewCategoryChange} placeholder="Category Name" style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', marginBottom: '5px' }} />
        <button type="button" onClick={handleAddCategory} style={{ padding: '10px 20px', borderRadius: '4px', border: 'none', backgroundColor: '#007bff', color: '#fff', cursor: 'pointer' }}>Add Category</button>
      </div>
      <button type="submit" style={{ width: '100%', padding: '10px 20px', borderRadius: '4px', border: 'none', backgroundColor: '#28a745', color: '#fff', cursor: 'pointer' }}>{selectedAuction ? 'Update Auction' : 'Create Auction'}</button>
    </form>
  );
};

export default AuctionForm;
