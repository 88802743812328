import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import { io } from 'socket.io-client';
import Footer from './components/footer';
import Navbar from './components/NavBar';
import IndexPage from './pages/IndexPage';
import AccountPage from './accounts/pages/AccountPage';
import LoginPage from './accounts/pages/LoginPage';
import RegistrationForm from './accounts/pages/RegisterPage';
import WinnersPage from './auction/pages/Winners';
import AuctionGovernor from './admin/pages/AuctionGovernor';
import HomePage from './admin/pages/HomePage';
import ProductsPage from './admin/pages/ProductsManagement';
import AuctionsPage from './admin/pages/AuctionsPage';
import UsersPage from './admin/pages/UsersManagement';
import BidsPage from './admin/pages/BidsPage';
import InvoicesPage from './admin/pages/InvoicesManagement';
import ReviewsPage from './admin/pages/ReviewManagement';
import MediaManager from './admin/components/MediaManager';
import AuctionDetails from './auction/pages/details';
import CookiePopup from './components/CookiePopup';
import { ErrorProvider } from './components/ErrorContext';
import UpcomingAuctions from './auction/pages/upcoming';
import ContactForm from './pages/ContactForm';
import FAQPage from './pages/FAQ';
import LegalInfoPage from './pages/Legal';
import ForgotPasswordPage from './accounts/pages/ForgotPasswordPage';
import ResetPasswordPage from './accounts/pages/ResetPasswordPage';
import FirstTimeOfferPage from './accounts/pages/FirstTimeOfferPage';
import CharityPage from './pages/CharityPage';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import CategoryManager from './admin/pages/CategoryManager';
import CategoriesPage from './pages/CategoriesPage';
import MarketPage from './pages/MarketPage';
import { AuctionStateProvider } from './components/auctionStateContext';
import MarketDetails from './auction/pages/marketDetails';
import ActivateAccountPage from './accounts/pages/ActivateAccountPage';
import AboutUs from './pages/AboutUs';
import WinnersManagement from './admin/pages/WinnersManagement';
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
const socketUrl = apiUrl.endsWith('/api') ? apiUrl.slice(0, -4) : apiUrl;
const socket = io(socketUrl);

const paypalOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID!,
    currency: "GBP",
    intent: "capture",
};

const App: React.FC = () => {
  useEffect(() => {
    const currentHost = window.location.host;
    const currentProtocol = window.location.protocol; 
    if (currentHost !== 'localhost:3000' && currentHost !== 'localhost:4000') {
      const targetHost = 'ushop.bid';
      const targetProtocol = 'https:';
      
      if (currentHost !== targetHost || currentProtocol !== targetProtocol) {
        const newUrl = `${targetProtocol}//${targetHost}${window.location.pathname}${window.location.search}`;
        window.location.href = newUrl;
      }
    }
  }, []);

  return (
    <PayPalScriptProvider options={{...paypalOptions, "clientId": process.env.REACT_APP_PAYPAL_CLIENT_ID!}}>
      <Router>
        <div>
          <Navbar />
          <ErrorProvider>
            <AuctionStateProvider>
              <Routes>
                <Route path="/" element={<IndexPage />} />
                <Route path="/register" element={<RegistrationForm />} />
                <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
                <Route path="/first-time-offer" element={<FirstTimeOfferPage />} />
                <Route path="/activate-account/:token" element={<ActivateAccountPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/account" element={<AccountPage />} />
                <Route path="/upcoming" element={<UpcomingAuctions />} />
                <Route path="/winners" element={<WinnersPage />} />
                <Route path="/auction/:id" element={<AuctionDetails />} />
                <Route path="/market/:id" element={<MarketDetails />} />
                <Route path="/charity" element={<CharityPage />} />
                <Route path="/categories" element={<CategoriesPage />} />
                <Route path="/market" element={<MarketPage />} /> 
                <Route path="/contact/" element={<ContactForm />} />
                <Route path='/about/' element={<AboutUs/>}/>
                <Route path="/faq/" element={<FAQPage />} />
                <Route path="/info/" element={<LegalInfoPage />} />
                <Route path="/gov" element={<AuctionGovernor />} />
                <Route path="/admin" element={<HomePage />} />
                <Route path="/admin/media" element={<MediaManager />} />
                <Route path="/admin/auctions" element={<AuctionsPage />} />
                <Route path="/admin/products" element={<ProductsPage />} />
                <Route path="/admin/users" element={<UsersPage />} />
                <Route path="/admin/bids" element={<BidsPage />} />
                <Route path="/admin/invoices" element={<InvoicesPage />} />
                <Route path="/admin/reviews" element={<ReviewsPage />} />
                <Route path="/admin/categories" element={<CategoryManager />} />
                <Route path="/admin/winners" element={<WinnersManagement />} />

              </Routes>
            </AuctionStateProvider>
          </ErrorProvider>
          <Footer />
          <CookiePopup />
        </div>
      </Router>
    </PayPalScriptProvider>
  );
};

export default App;
