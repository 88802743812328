import React, { useState, useEffect, useMemo } from 'react';
import { User, Address } from '../../../../types';
import { getUsers, getAddresses, deleteUser, deleteAddress, updateUser, createUser, updateAddress } from '../../utils/adminAPI';
import { Mail, Phone, Calendar, Clock, CreditCard, UserCheck, UserX } from 'lucide-react';
import styles from './UsersManagement.module.css';
import AddressForm from '../components/AddressForm';

// UserForm Component
const UserForm: React.FC<{ selectedUser: User | null; onSubmit: () => void; onClose: () => void }> = ({ selectedUser, onSubmit, onClose }) => {
  const [formData, setFormData] = useState<Omit<User, 'id'>>({
    username: '',
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    password: '',
    credits: 0,
    reg_date: new Date().toISOString(),
    last_login: new Date().toISOString(),
    is_active: true,
    type: 0,
    push_notifications: false,
  });
  const [error, setError] = useState<string | null>(null);
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (selectedUser) {
      setFormData({
        ...selectedUser,
        password: '',
      });
    } else {
      setFormData({
        username: '',
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        password: '',
        credits: 0,
        reg_date: new Date().toISOString(),
        last_login: new Date().toISOString(),
        is_active: true,
        type: 0,
        push_notifications: false,
      });
    }
  }, [selectedUser]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'number' ? Number(value) : 
              type === 'checkbox' ? (e.target as HTMLInputElement).checked : 
              value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    try {
      if (token) {
        if (selectedUser) {
          await updateUser(token, selectedUser.id, formData);
        } else {
          await createUser(token, formData);
        }
        onSubmit();
        onClose();
      }
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.detail) {
        setError(err.response.data.detail);
      } else if (err.response && err.response.status === 409) {
        setError('A user with this username or email already exists. Please choose a different one.');
      } else {
        setError('An error occurred while saving the user. Please try again.');
      }
      console.error('Error saving user', err);
    }
  };

  const userTypes = [
    { value: 0, label: 'Admin' },
    { value: 1, label: 'New User' },
    { value: 2, label: 'Topped Up' },
    { value: 3, label: 'Auction Winner' },
    { value: 4, label: 'VIP' },
    { value: 5, label: 'Suspicious User' },
    { value: 6, label: 'Banned' },
    { value: 7, label: 'Deleted' }
  ];

  const formatDateForInput = (dateString: string | undefined) => {
    if (!dateString) return '';
    return new Date(dateString).toISOString().slice(0, 16);
  };

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <span className={styles.close} onClick={onClose}>&times;</span>
        <form onSubmit={handleSubmit} className={styles.form}>
          {error && <div className={styles.errorMessage}>{error}</div>}
          <div className={styles.formGroup}>
            <label htmlFor="username" className={styles.formLabel}>Username</label>
            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
              className={styles.formInput}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="first_name" className={styles.formLabel}>First Name</label>
            <input
              type="text"
              id="first_name"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              required
              className={styles.formInput}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="last_name" className={styles.formLabel}>Last Name</label>
            <input
              type="text"
              id="last_name"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              required
              className={styles.formInput}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="phone" className={styles.formLabel}>Phone</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone || ''}
              onChange={handleChange}
              className={styles.formInput}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="email" className={styles.formLabel}>Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className={styles.formInput}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="password" className={styles.formLabel}>Password</label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required={!selectedUser}
              className={styles.formInput}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="credits" className={styles.formLabel}>Credits</label>
            <input
              type="number"
              id="credits"
              name="credits"
              value={formData.credits}
              onChange={handleChange}
              required
              className={styles.formInput}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="reg_date" className={styles.formLabel}>Registration Date</label>
            <input
              type="datetime-local"
              id="reg_date"
              name="reg_date"
              value={formatDateForInput(formData.reg_date)}
              onChange={handleChange}
              className={styles.formInput}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="last_login" className={styles.formLabel}>Last Login</label>
            <input
              type="datetime-local"
              id="last_login"
              name="last_login"
              value={formatDateForInput(formData.last_login)}
              onChange={handleChange}
              className={styles.formInput}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="is_active" className={styles.formLabel}>Active</label>
            <input
              type="checkbox"
              id="is_active"
              name="is_active"
              checked={formData.is_active}
              onChange={handleChange}
              className={styles.formCheckbox}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="type" className={styles.formLabel}>User Type</label>
            <select
              id="type"
              name="type"
              value={formData.type}
              onChange={handleChange}
              className={styles.formSelect}
            >
              {userTypes.map(type => (
                <option key={type.value} value={type.value}>
                  {type.label}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.formActions}>
            <button type="submit" className={styles.submitButton}>
              {selectedUser ? 'Update User' : 'Create User'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

// UserList Component
const UserList: React.FC<{
  users: User[];
  addresses: Address[];
  onEdit: (user: User) => void;
  onEditAddress: (address: Address) => void;
  onAddAddress: (userId: number) => void;
  fetchUsers: () => void;
  fetchAddresses: () => void;
}> = ({ users, addresses, onEdit, onEditAddress, onAddAddress, fetchUsers, fetchAddresses }) => {
  const [searchText, setSearchText] = useState('');
  const [selectedTypes, setSelectedTypes] = useState<string[]>(['0', '1', '2', '3', '4', '5']);
  const [editingUser, setEditingUser] = useState<User | null>(null);
  const [editingAddress, setEditingAddress] = useState<Address | null>(null);
  const [editingUserId, setEditingUserId] = useState<number | null>(null);
  const token = localStorage.getItem('token');

  const handleDelete = async (id: number) => {
    if (token) {
      const confirmDelete = window.confirm('Are you sure you want to delete this user?');
      if (confirmDelete) {
        await deleteUser(token, id);
        fetchUsers();
      }
    }
  };

  const handleDeleteAddress = async (id: number) => {
    if (token) {
      const confirmDelete = window.confirm('Are you sure you want to delete this address?');
      if (confirmDelete) {
        await deleteAddress(token, id);
        fetchAddresses();
      }
    }
  };

  const handleUpdateUser = async (updatedUser: User) => {
    if (token && updatedUser) {
      await updateUser(token, updatedUser.id, updatedUser);
      fetchUsers();
      setEditingUser(null);
    }
  };

  const handleUpdateAddress = async (updatedAddress: Address) => {
    if (token && updatedAddress) {
      await updateAddress(token, updatedAddress.id, updatedAddress);
      fetchAddresses();
      setEditingAddress(null);
    }
  };

  const filteredUsers = useMemo(() => {
    return users.filter(user => {
      const matchesSearch = Object.values(user).some(value => 
        value?.toString().toLowerCase().includes(searchText.toLowerCase())
      );
      
      const matchesType = selectedTypes.includes(user.type.toString());
      
      return matchesSearch && matchesType;
    });
  }, [users, searchText, selectedTypes]);

  const getUserTypeLabel = (type: number) => {
    switch (type) {
      case 0: return 'Admin';
      case 1: return 'New User';
      case 2: return 'Topped Up';
      case 3: return 'Auction Winner';
      case 4: return 'VIP';
      case 5: return 'Suspicious User';
      case 6: return 'Banned';
      case 7: return 'Deleted';
      default: return 'Unknown';
    }
  };

  const handleTypeChange = (type: string) => {
    setSelectedTypes(prev => 
      prev.includes(type) ? prev.filter(t => t !== type) : [...prev, type]
    );
  };

  const handleCancelEditUser = () => {
    setEditingUser(null);
  };

  const handleCancelEditAddress = () => {
    setEditingAddress(null);
    setEditingUserId(null);
  };

  return (
    <div className={styles.userList}>
      <h2>User List</h2>
      <div className={styles.filterControls}>
        <input
          type="text"
          placeholder="Search users"
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          className={styles.searchInput}
        />
        <div className={styles.typeCheckboxes}>
          {[0, 1, 2, 3, 4, 5, 6, 7].map(type => (
            <label key={type}>
              <input
                type="checkbox"
                checked={selectedTypes.includes(type.toString())}
                onChange={() => handleTypeChange(type.toString())}
              />
              {getUserTypeLabel(type)}
            </label>
          ))}
        </div>
      </div>
      {filteredUsers.map(user => (
        <div key={user.id} className={styles.userRow}>
          <div className={styles.userCard}>
            {editingUser && editingUser.id === user.id ? (
              <UserForm
                selectedUser={editingUser}
                onSubmit={() => {
                  fetchUsers();
                  handleCancelEditUser();
                }}
                onClose={handleCancelEditUser}
              />
            ) : (
              <>
                <h3>{user.username}</h3>
                <p>{user.first_name} {user.last_name}</p>
                <div className={styles.userInfo}>
                  <span><Mail size={16} /> {user.email}</span>
                  <span><Phone size={16} /> {user.phone || 'N/A'}</span>
                  <span><Calendar size={16} /> Registered: {new Date(user.reg_date || '').toLocaleDateString()}</span>
                  <span><Clock size={16} /> Last Login: {user.last_login ? new Date(user.last_login).toLocaleDateString() : 'N/A'}</span>
                  <span><CreditCard size={16} /> Credits: {user.credits}</span>
                  <span>{user.is_active ? <UserCheck size={16} /> : <UserX size={16} />} {user.is_active ? 'Active' : 'Inactive'}</span>
                  <span>Type: {getUserTypeLabel(user.type)}</span>
                </div>
                <div className={styles.userActions}>
                  <button onClick={() => setEditingUser(user)} className={styles.editButton}>Edit</button>
                  <button onClick={() => handleDelete(user.id)} className={styles.deleteButton}>Delete</button>
                </div>
              </>
            )}
          </div>
          <div className={styles.addressManagement}>
            <h4>Addresses</h4>
            <button onClick={() => setEditingUserId(user.id)} className={styles.addAddressButton}>Add Address</button>
            {editingUserId === user.id && (
              <AddressForm
                selectedAddress={null}
                userId={user.id}
                onSubmit={() => {
                  fetchAddresses();
                  handleCancelEditAddress();
                }}
                onCancel={handleCancelEditAddress}
              />
            )}
            <table className={styles.addressTable}>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Address</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {addresses.filter(address => address.user_id === user.id).map(address => (
                  <tr key={address.id}>
                    <td>{address.address_type}</td>
                    <td>{`${address.street_address}, ${address.city}, ${address.state_or_province}, ${address.postal_code}, ${address.country}`}</td>
                    <td>
                      {editingAddress && editingAddress.id === address.id ? (
                        <AddressForm
                          selectedAddress={editingAddress}
                          userId={user.id}
                          onSubmit={() => {
                            fetchAddresses();
                            handleCancelEditAddress();
                          }}
                          onCancel={handleCancelEditAddress}
                        />
                      ) : (
                        <>
                          <button onClick={() => setEditingAddress(address)} className={styles.editButton}>Edit</button>
                          <button onClick={() => handleDeleteAddress(address.id)} className={styles.deleteButton}>Delete</button>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  );
};

// UsersPage Component
const UsersPage: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);
  const [isAddingAddress, setIsAddingAddress] = useState(false);
  const [addressUserId, setAddressUserId] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchUsers();
    fetchAddresses();
  }, []);

  const fetchUsers = async () => {
    try {
      if (token) {
        const response = await getUsers(token);
        setUsers(response.data);
      }
    } catch (error) {
      console.error('Failed to fetch users', error);
    }
  };

  const fetchAddresses = async () => {
    try {
      if (token) {
        const response = await getAddresses(token);
        setAddresses(response.data);
      }
    } catch (error) {
      console.error('Failed to fetch addresses', error);
    }
  };

  const handleEdit = (user: User) => {
    setSelectedUser(user);
    setSelectedAddress(null);
    setIsAddingAddress(false);
    setIsModalOpen(true);
  };

  const handleEditAddress = (address: Address) => {
    setSelectedAddress(address);
    setSelectedUser(null);
    setIsAddingAddress(false);
  };

  const handleAddAddress = (userId: number) => {
    setAddressUserId(userId);
    setSelectedAddress(null);
    setSelectedUser(null);
    setIsAddingAddress(true);
  };

  const handleFormSubmit = () => {
    fetchUsers();
    fetchAddresses();
    setSelectedUser(null);
    setSelectedAddress(null);
    setIsAddingAddress(false);
    setIsModalOpen(false);
  };

  return (
    <div>
      <h1>User Management</h1>
      <button onClick={() => setIsModalOpen(true)} className={styles.addButton}>Create User</button>
      {isModalOpen && (
        <UserForm
          selectedUser={selectedUser}
          onSubmit={handleFormSubmit}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      <UserList
        users={users}
        addresses={addresses}
        onEdit={handleEdit}
        onEditAddress={handleEditAddress}
        onAddAddress={handleAddAddress}
        fetchUsers={fetchUsers}
        fetchAddresses={fetchAddresses}
      />
    </div>
  );
};

export default UsersPage;