import React, { useEffect, useState } from 'react';
import { Address, Invoice, Product, User } from '../../../../types';
import generateInvoice, { getAddressDetails, getUserInvoices } from '../../utils/accountAPI';
import { getProducts } from '../../utils/coreAPI';
import styles from './MarketOrders.module.css';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import Loading from '../../components/loading';

interface MarketOrdersProps {
  token: string;
  userId: number;
  user: Partial<User>;
}

interface EnrichedMarketOrder extends Invoice {
  product?: Product;
  shipping_address_id?: number;
  billing_address_id?: number;
}

const ORDERS_PER_PAGE = 5;

const MarketOrders: React.FC<MarketOrdersProps> = ({ token, userId, user }) => {
  const [orders, setOrders] = useState<EnrichedMarketOrder[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [filter, setFilter] = useState<string>('all');
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'ascending' | 'descending' } | null>(null);

  useEffect(() => {
    fetchMarketOrders();
  }, [token, userId]);

  const fetchMarketOrders = async () => {
    setLoading(true);
    setError('');
    try {
      const [invoicesResponse, productsResponse] = await Promise.all([
        getUserInvoices(token),
        getProducts()
      ]);

      const marketOrders = invoicesResponse
        .filter((invoice: Invoice) => 
          invoice.type === 'market' && 
          invoice.user_id === userId
        )
        .map((invoice: Invoice) => ({
          ...invoice,
          product: productsResponse.data.find((p: Product) => p.id === invoice.product_id)
        }));

      setOrders(marketOrders);
    } catch (err) {
      setError('Failed to fetch market orders');
    } finally {
      setLoading(false);
    }
  };

  const handleSort = (key: string) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedOrders = [...orders].sort((a, b) => {
    if (!sortConfig) return 0;
    let aValue, bValue;
    switch (sortConfig.key) {
      case 'product':
        aValue = a.product?.title || '';
        bValue = b.product?.title || '';
        break;
      case 'amount':
        aValue = a.amount;
        bValue = b.amount;
        break;
      case 'status':
        aValue = a.status || '';
        bValue = b.status || '';
        break;
      case 'date':
        aValue = new Date(a.date).getTime();
        bValue = new Date(b.date).getTime();
        break;
      default:
        return 0;
    }

    if (aValue < bValue) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (aValue > bValue) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  const filteredOrders = sortedOrders.filter(order => {
    return filter === 'all' || order.status === filter;
  });

  const handleGenerateInvoice = async (order: EnrichedMarketOrder) => {
    console.log('Attempting to generate invoice for order:', order);

    if (!order?.id) {
      console.error('Order ID is missing:', order);
      setError('Invalid order data: Missing order ID');
      return;
    }

    if (!user?.id) {
      console.error('User ID is missing:', user);
      setError('User information is missing. Please try logging in again.');
      return;
    }

    if (!order.product?.id) {
      console.error('Product information is missing:', order.product);
      setError('Product information is missing for this order.');
      return;
    }

    try {
      const addressResponse = await getAddressDetails(token);
      console.log('Address response:', addressResponse);

      if (!addressResponse?.data) {
        setError('Failed to fetch address details');
        return;
      }

      const addresses = addressResponse.data;
      const reversedAddresses = [...addressResponse.data].reverse();
      const shippingAddress = reversedAddresses.find(addr => addr.address_type === 'SHIPPING');
      const billingAddress = reversedAddresses.find(addr => addr.address_type === 'BILLING');


      if (!shippingAddress || !billingAddress) {
        console.error('Address validation failed:', {
          order_id: order.id,
          shipping_address_id: order.shipping_address_id,
          billing_address_id: order.billing_address_id,
        });
        setError('Missing required information to generate invoice.');
        return;
      }

      await generateInvoice(order, user, order.product, null, shippingAddress!, billingAddress!);
    } catch (error) {
      console.error('Error generating invoice:', error);
      alert('Failed to generate invoice. Please try again.');
    }
  };

  const getStatusClassName = (status: string) => {
    switch (status) {
      case 'awaiting payment':
        return styles.awaitingPayment;
      case 'paid':
        return styles.paid;
      case 'shipped':
        return styles.shipped;
      case 'delivered':
        return styles.delivered;
      case 'completed':
        return styles.completed;
      case 'cancelled':
        return styles.cancelled;
      default:
        return '';
    }
  };

  if (loading) return <Loading message="Loading your market orders..." subMessage="Fetching your order details" />;
  if (error) return <div className={styles.error}>{error}</div>;

  return (
    <div className={styles.container}>
      <h2 className={styles.header}>Market Orders</h2>
      <div>
        <label htmlFor="statusFilter">Filter by Status: </label>
        <select id="statusFilter" value={filter} onChange={(e) => setFilter(e.target.value)}>
          <option value="all">All</option>
          <option value="awaiting payment">Awaiting Payment</option>
          <option value="paid">Paid</option>
          <option value="shipped">Shipped</option>
          <option value="delivered">Delivered</option>
          <option value="completed">Completed</option>
          <option value="cancelled">Cancelled</option>
        </select>
      </div>
      {filteredOrders.length === 0 ? (
        <p>No market orders found for the selected status.</p>
      ) : (
        <table className={styles.orderTable}>
          <thead>
            <tr>
              <th onClick={() => handleSort('date')}>Date</th>
              <th>Invoice Number</th>
              <th onClick={() => handleSort('product')}>Product</th>
              <th onClick={() => handleSort('amount')}>Amount</th>
              <th onClick={() => handleSort('status')}>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredOrders.map(order => (
              <tr key={order.id}>
                <td>{new Date(order.date).toLocaleDateString()}</td>
                <td>{order.id || 'N/A'}</td>
                <td>{order.product?.title || 'Unknown Product'}</td>
                <td>£{order.amount}</td>
                <td className={`${getStatusClassName(order.status!)} ${styles.status}`}>{order.status} </td>
                <td>
                  {order.status === 'completed' && (
                    <button onClick={() => handleGenerateInvoice(order)} className={styles.actionButton}>
                      Generate Invoice
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default MarketOrders; 