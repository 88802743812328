// CreditsSection.tsx
import React, { FormEvent, useEffect, useState } from 'react';
import { addCredits, getUserInvoices, getAddressDetails, updateAddressDetails, createPayPalOrder, capturePayPalOrder, getAccountDetails } from '../../utils/accountAPI';
import { Invoice, Address } from '../../../../types';
import generateInvoice from '../../utils/accountAPI';
import styles from './CreditForm.module.css'; // Import your CSS module
import { useNavigate } from 'react-router-dom';
import { PayPalButtons } from "@paypal/react-paypal-js";

const CREDITS_TO_COST_MAP: { [credits: number]: number } = {
    5:1,
    25: 10,
    50: 25,
    120: 50,
    200: 75,
    275: 100,
    1000: 275,
};

const getRegularPrice = (credits: number): number => {
    return Math.round(credits * 0.5); // Assuming £0.50 is regular price per credit
};

interface CreditsSectionProps {
    token: string;
    currentCredits: number;
    onCreditsAdded: (newCredits: number) => void;
    initialSelectedCredits?: number;
}

interface PaymentFormProps {
    amount: number;
    onPaymentSuccess: () => void;
    selectedCredits: number;
}

interface BillingModalProps {
  onClose: () => void;
  onUseShipping: () => void;
  onAddNew: () => void;
}

const BillingAddressModal: React.FC<BillingModalProps> = ({ onClose, onUseShipping, onAddNew }) => {
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <h3>Billing Address Required</h3>
        <p>Please select a billing address option to continue:</p>
        <div className={styles.modalButtons}>
          <button onClick={onUseShipping} className={styles.submitButton}>
            Use Shipping Address
          </button>
          <button onClick={onAddNew} className={styles.submitButton}>
            Add New Address
          </button>
          <button onClick={onClose} className={styles.cancelButton}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const PaymentForm: React.FC<PaymentFormProps> = ({ 
    amount, 
    onPaymentSuccess,
    selectedCredits
}) => {
    const navigate = useNavigate();
    const [paymentError, setPaymentError] = useState('');
    const [showBillingModal, setShowBillingModal] = useState(false);
    const [addresses, setAddresses] = useState<Address[]>([]);
    const [paypalError, setPaypalError] = useState<string>('');

    useEffect(() => {
        checkBillingAddress();
    }, []);

    const checkBillingAddress = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) return;
            
            const response = await getAddressDetails(token);
            setAddresses(response.data);
            
            const hasBilling = response.data.some(addr => addr.address_type === 'BILLING');
            if (!hasBilling) {
                setShowBillingModal(true);
            }
        } catch (error) {
            console.error('Error checking billing address:', error);
        }
    };

    const handleUseShippingAddress = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) return;

            const shippingAddress = addresses.find(addr => addr.address_type === 'SHIPPING');
            if (shippingAddress) {
                const { id, ...addressData } = shippingAddress;
                await updateAddressDetails(token, {
                    ...addressData,
                    address_type: 'BILLING'
                });
                setShowBillingModal(false);
            }
        } catch (error) {
            console.error('Error copying shipping address:', error);
        }
    };

    const handleAddNewBilling = () => {
        navigate('/account', { 
            state: { 
                section: 'account', 
                subsection: 'billingAddress',
                returnTo: '/account/credits'
            } 
        });
    };

    const handlePayPalCreateOrder = async () => {
        try {
            console.log('Creating PayPal order for amount:', amount);
            const order = await createPayPalOrder(amount, 'GBP', -1, undefined, selectedCredits, 'credits');
            console.log('PayPal order created:', order);
            return order.id;
        } catch (error) {
            console.error('Detailed PayPal create order error:', error);
            setPaypalError(error instanceof Error ? error.message : 'Unknown PayPal error');
            throw error;
        }
    };

    const handlePayPalApprove = async (data: any) => {
        try {
            await capturePayPalOrder(data.orderID);
            onPaymentSuccess();
        } catch (error) {
            console.error('PayPal capture error:', error);
            setPaymentError('Failed to process PayPal payment');
        }
    };

    const handlePayPalError = (err: Record<string, unknown>) => {
        console.error('PayPal error:', err);
        setPaypalError('PayPal encountered an error. Please try again.');
    };

    return (
        <form className={`${styles.paymentForm} ${showBillingModal ? styles.modalOpen : ''}`}>
            {showBillingModal && (
                <BillingAddressModal
                    onClose={() => setShowBillingModal(false)}
                    onUseShipping={handleUseShippingAddress}
                    onAddNew={handleAddNewBilling}
                />
            )}

            <div className={styles.paypalButtonContainer}>
                <PayPalButtons
                    createOrder={handlePayPalCreateOrder}
                    onApprove={handlePayPalApprove}
                    onError={handlePayPalError}
                    style={{ layout: "horizontal" }}
                    forceReRender={[amount]}
                />
                {paypalError && (
                    <div className={styles.paymentError}>
                        {paypalError}
                    </div>
                )}
            </div>

            {paymentError && <div className={styles.paymentError}>{paymentError}</div>}
        </form>
    );
};

const CreditsSection: React.FC<CreditsSectionProps> = ({ token, currentCredits, onCreditsAdded, initialSelectedCredits }) => {
    const [selectedCredits, setSelectedCredits] = useState<number>(initialSelectedCredits || 0);
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [invoicesLoading, setInvoicesLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [successMessage, setSuccessMessage] = useState<string>('');
    const [isHistoryOpen, setIsHistoryOpen] = useState<boolean>(false);

    useEffect(() => {
        fetchInvoices();
    }, []);

    useEffect(() => {
        if (initialSelectedCredits) {
            setSelectedCredits(initialSelectedCredits);
        }
    }, [initialSelectedCredits]);

    const fetchInvoices = async () => {
        setInvoicesLoading(true);
        setError('');
        try {
            const data = await getUserInvoices(token);
            const creditInv = data.filter(invoice => invoice.credits != null && invoice.credits > 0);

            setInvoices(creditInv);
        } catch (err) {
            setError('Failed to fetch invoices');
        } finally {
            setInvoicesLoading(false);
        }
    };

    const handleCreditsChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedCredits(parseInt(event.target.value, 10));
    };

    const handlePaymentSuccess = async () => {
        setError('');
        setSuccessMessage('');
        setLoading(true);
        try {
            const response = await addCredits(token, selectedCredits);
            setSuccessMessage('Credits added successfully');
            if (response.data.credits && typeof response.data.credits === 'number') {
                onCreditsAdded(response.data.credits);
            }
            fetchInvoices();
        } catch (err: any) {
            setError(err.response?.data?.message || 'Failed to add credits');
        } finally {
            setLoading(false);
        }
    };

    const getAvailableCreditOptions = () => {
        if (invoices.length === 0) {
            return Object.entries(CREDITS_TO_COST_MAP).map(([credits, cost]) => {
                const creditsNum = parseInt(credits);
                if (creditsNum === 5 || creditsNum === 25) {
                    const regularPrice = getRegularPrice(creditsNum);
                    const savings = Math.round(((regularPrice - cost) / regularPrice) * 100);
                    return {
                        credits: creditsNum,
                        cost,
                        label: `FIRST-TIME OFFER! £${cost} - ${credits} Credits (${savings}% OFF!)`
                    };
                }
                return {
                    credits: creditsNum,
                    cost,
                    label: `£${cost} - ${credits} Credits`
                };
            });
        }
        // If user has invoice history, exclude the first two options
        return Object.entries(CREDITS_TO_COST_MAP)
            .filter(([credits]) => parseInt(credits) > 25)
            .map(([credits, cost]) => ({
                credits: parseInt(credits),
                cost,
                label: `£${cost} - ${credits} Credits`
            }));
    };

    const handleGenerateInvoice = async (invoice: Invoice) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            // Get user data from localStorage or context
            const userData = (await getAccountDetails(token)).data;
            // For credits invoice, we don't need product or auction data
            const product = null;
            const auction = null;

            // Get addresses from state
            const addresses = await getAddressDetails(token);
            const billingAddress = addresses.data.find(addr => addr.address_type === 'BILLING');
            const shippingAddress = addresses.data.find(addr => addr.address_type === 'SHIPPING');

            if (!billingAddress || !shippingAddress) {
                throw new Error('Missing address information');
            }

            await generateInvoice(
                invoice,
                userData,
                product,
                auction,
                shippingAddress,
                billingAddress,
            );
        } catch (error) {
            console.error('Failed to generate invoice:', error);
            setError('Failed to generate invoice. Please ensure all required information is available.');
        }
    };

    return (
        <div className={styles.creditsSection}>
            <h2 className={styles.sectionTitle}>Credit Management</h2>
            <div className={styles.creditsDisplay}>
                <span className={styles.creditsLabel}>Available Credits:</span>
                <span className={styles.creditsAmount}>{currentCredits}</span>
            </div>

            {successMessage && <div className={styles.successMessage}>{successMessage}</div>}
            {error && <div className={styles.errorMessage}>{error}</div>}

            <div className={styles.creditsForm}>
                <select 
                    onChange={handleCreditsChange} 
                    className={styles.creditsSelect}
                    value={selectedCredits}
                >
                    <option value="0">Choose top-up amount</option>
                    {getAvailableCreditOptions().map((option) => (
                        <option 
                            key={option.credits} 
                            value={option.credits}
                            className={option.credits <= 25 ? styles.firstTimeOffer : ''}
                        >
                            {option.label}
                        </option>
                    ))}
                </select>

                {invoices.length === 0 && selectedCredits > 0 && selectedCredits <= 25 && (
                    <div className={styles.firstTimeMessage}>
                        🎉 Special first-time purchase offer! Regular price: £{getRegularPrice(selectedCredits)}
                    </div>
                )}

                {selectedCredits > 0 &&                        
                    <PaymentForm
                        amount={CREDITS_TO_COST_MAP[selectedCredits]}
                        onPaymentSuccess={handlePaymentSuccess}
                        selectedCredits={selectedCredits}
                    />
                }
            </div>

            <div className={styles.historySection}>
                <button onClick={() => setIsHistoryOpen(!isHistoryOpen)} className={styles.historyToggle}>
                    Credits Top-Up History
                    <span className={styles.toggleIcon}>{isHistoryOpen ? '▲' : '▼'}</span>
                </button>
            </div>

            {isHistoryOpen && (
                <div className={styles.invoiceHistory}>
                    {invoicesLoading ? (
                        <p>Loading invoices...</p>
                    ) : invoices.length === 0 ? (
                        <p>No credit top-up history available.</p>
                    ) : (
                        <table className={styles.invoiceTable}>
                            <thead>
                                <tr>
                                    <th>Invoice ID</th>
                                    <th>Amount (£)</th>
                                    <th>Credits</th>
                                    <th>Status</th>
                                    <th>Date</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoices.map(invoice => (
                                    <tr key={invoice.id}>
                                        <td>{invoice.id}</td>
                                        <td>{invoice.amount}</td>
                                        <td>{invoice.credits}</td>
                                        <td>{invoice.status}</td>
                                        <td>{new Date(invoice.date).toLocaleDateString()}</td>
                                        <td>
                                            <button
                                                onClick={() => handleGenerateInvoice(invoice)}
                                                className={styles.generateInvoiceButton}
                                                disabled={invoice.status !== 'completed'}
                                            >
                                                <span className={styles.buttonIcon}>📄</span>
                                                Generate Invoice
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            )}
        </div>
    );
};

export default CreditsSection;
