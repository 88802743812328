import React, { useState, useEffect } from 'react';
import { Product, Auction } from '../../../../types';
import { getProducts } from '../../utils/adminAPI';
import styles from './BatchAuctionScheduler.module.css';

interface BatchAuctionSchedulerProps {
  onSubmit: (scheduledAuctions: Partial<Auction>[]) => void;
}

const BatchAuctionScheduler: React.FC<BatchAuctionSchedulerProps> = ({ onSubmit }) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<number[]>([]);
  const [startDate, setStartDate] = useState<string>(new Date().toISOString().split('T')[0]);
  const [numDays, setNumDays] = useState<number>(5);
  const [dayType, setDayType] = useState<'all' | 'weekdays' | 'weekends'>('all');
  const [timeOption, setTimeOption] = useState<'fixed' | 'random' | 'specified'>('fixed');
  const [fixedTime, setFixedTime] = useState<string>('09:00');
  const [randomTimeStart, setRandomTimeStart] = useState<string>('09:00');
  const [randomTimeEnd, setRandomTimeEnd] = useState<string>('18:00');
  const [countdownOption, setCountdownOption] = useState<'fixed' | 'random'>('fixed');
  const [fixedCountdown, setFixedCountdown] = useState<number>(30);
  const [randomCountdownOptions, setRandomCountdownOptions] = useState<number[]>([20, 30, 60]);
  const [auctionTimes, setAuctionTimes] = useState<string[]>(['09:00']);
  const [auctionsPerDay, setAuctionsPerDay] = useState<number>(1);
  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await getProducts(token || '');
      setProducts(response.data);
    } catch (error) {
      console.error('Failed to fetch products', error);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const scheduledAuctions = generateScheduledAuctions();
    console.log('Generated auctions:', scheduledAuctions);
    onSubmit(scheduledAuctions);
  };

  const generateRandomTime = (start: string, end: string) => {
    const [startHour, startMinute] = start.split(':').map(Number);
    const [endHour, endMinute] = end.split(':').map(Number);

    const startTime = new Date();
    startTime.setHours(startHour, startMinute, 0);

    const endTime = new Date();
    endTime.setHours(endHour, endMinute, 0);

    const randomTime = new Date(startTime.getTime() + Math.random() * (endTime.getTime() - startTime.getTime()));
    return randomTime.toTimeString().slice(0, 5);
  };

  const generateScheduledAuctions = (): Partial<Auction>[] => {
    const scheduledAuctions: Partial<Auction>[] = [];
    let currentDate = new Date(startDate);
    let productIndex = 0;

    for (let i = 0; i < numDays; i++) {
      if (
        (dayType === 'all') ||
        (dayType === 'weekdays' && currentDate.getDay() !== 0 && currentDate.getDay() !== 6) ||
        (dayType === 'weekends' && (currentDate.getDay() === 0 || currentDate.getDay() === 6))
      ) {
        for (let j = 0; j < auctionsPerDay; j++) {
          let time = timeOption === 'fixed'
            ? fixedTime
            : generateRandomTime(randomTimeStart, randomTimeEnd);

            if (timeOption === 'specified') {
              time = auctionTimes[j % auctionTimes.length];
            }

          const [hours, minutes] = time.split(':').map(Number);
          const auctionDate = new Date(currentDate);
          auctionDate.setHours(hours, minutes, 0, 0);

          const countdownTimer = countdownOption === 'fixed'
            ? fixedCountdown
            : randomCountdownOptions[Math.floor(Math.random() * randomCountdownOptions.length)];

          scheduledAuctions.push({
            product_id: selectedProducts[productIndex % selectedProducts.length],
            start_time: auctionDate.toISOString(),
            countdown_timer: countdownTimer,
            current_bid: 0,
            status: 'upcoming'
          });

          productIndex++;
        }
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return scheduledAuctions;
  };

  return (
    <form onSubmit={handleSubmit} className={styles.container}>
      <h2>Batch Auction Scheduler</h2>
      
      <div className={styles.formGroup}>
        <label htmlFor="products">Select Products:</label>
        <select 
          id="products"
          multiple 
          value={selectedProducts.map(String)}
          onChange={(e) => setSelectedProducts(Array.from(e.target.selectedOptions, option => Number(option.value)))}
          required
        >
          {products.map(product => (
            <option key={product.id} value={product.id}>{product.title}</option>
          ))}
        </select>
        <small>Hold Ctrl (Windows) or Cmd (Mac) to select multiple products</small>
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="startDate">Start Date:</label>
        <input
          id="startDate"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          min={new Date().toISOString().split('T')[0]}
          required
        />
        <small>Select the date to start scheduling auctions</small>
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="numDays">Duration:</label>
        <select 
          id="numDays"
          value={numDays} 
          onChange={(e) => setNumDays(Number(e.target.value))}
          required
        >
          <option value={5}>5 days</option>
          <option value={7}>1 week</option>
          <option value={14}>2 weeks</option>
          <option value={30}>1 month</option>
        </select>
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="dayType">Schedule on:</label>
        <select 
          id="dayType"
          value={dayType} 
          onChange={(e) => setDayType(e.target.value as 'all' | 'weekdays' | 'weekends')}
          required
        >
          <option value="all">All Days</option>
          <option value="weekdays">Weekdays Only</option>
          <option value="weekends">Weekends Only</option>
        </select>
      </div>

      <fieldset className={styles.formGroup}>
        <legend>Auction Start Time</legend>
        <div>
          <input 
            type="radio" 
            id="fixedTime" 
            name="timeOption" 
            value="fixed" 
            checked={timeOption === 'fixed'}
            onChange={() => setTimeOption('fixed')}
          />
          <label htmlFor="fixedTime">Fixed Time:</label>
          <input 
            type="time" 
            value={fixedTime} 
            onChange={(e) => setFixedTime(e.target.value)} 
            disabled={timeOption !== 'fixed'}
          />
        </div>
        <div>
          <input 
            type="radio" 
            id="randomTime" 
            name="timeOption" 
            value="random" 
            checked={timeOption === 'random'}
            onChange={() => setTimeOption('random')}
          />
          <label htmlFor="randomTime">Random Time Between:</label>
          <input 
            type="time" 
            value={randomTimeStart} 
            onChange={(e) => setRandomTimeStart(e.target.value)} 
            disabled={timeOption !== 'random'}
          />
          and 
          <input 
            type="time" 
            value={randomTimeEnd} 
            onChange={(e) => setRandomTimeEnd(e.target.value)} 
            disabled={timeOption !== 'random'}
          />
        </div>
        <div>
          <input 
            type="radio" 
            id="specifiedTimes" 
            name="timeOption" 
            value="specified" 
            checked={timeOption === 'specified'}
            onChange={() => setTimeOption('specified')}
          />
          <label htmlFor="specifiedTimes">Specified Times:</label>
          <input
            id="auctionTimes"
            type="text"
            value={auctionTimes.join(', ')}
            onChange={(e) => setAuctionTimes(e.target.value.split(',').map(time => time.trim()))}
            placeholder="e.g., 09:00, 12:00, 15:00"
            disabled={timeOption !== 'specified'}
            required={timeOption === 'specified'}
          />
          <small>Enter comma-separated times for auctions each day</small>
        </div>
      </fieldset>

      <fieldset className={styles.formGroup}>
        <legend>Countdown Timer (seconds)</legend>
        <div>
          <input 
            type="radio" 
            id="fixedCountdown" 
            name="countdownOption" 
            value="fixed" 
            checked={countdownOption === 'fixed'}
            onChange={() => setCountdownOption('fixed')}
          />
          <label htmlFor="fixedCountdown">Fixed Countdown:</label>
          <input 
            type="number" 
            value={fixedCountdown} 
            onChange={(e) => setFixedCountdown(Number(e.target.value))} 
            disabled={countdownOption !== 'fixed'}
            min={1}
          />
        </div>
        <div>
          <input 
            type="radio" 
            id="randomCountdown" 
            name="countdownOption" 
            value="random" 
            checked={countdownOption === 'random'}
            onChange={() => setCountdownOption('random')}
          />
          <label htmlFor="randomCountdown">Random Countdown Options:</label>
          <input 
            type="text" 
            value={randomCountdownOptions.join(', ')} 
            onChange={(e) => setRandomCountdownOptions(e.target.value.split(',').map(Number))} 
            disabled={countdownOption !== 'random'}
          />
          <small>Enter comma-separated values, e.g., 20, 30, 60</small>
        </div>
      </fieldset>

      <div className={styles.formGroup}>
        <label htmlFor="auctionsPerDay">Auctions Per Day:</label>
        <input
          id="auctionsPerDay"
          type="number"
          value={auctionsPerDay}
          onChange={(e) => setAuctionsPerDay(Number(e.target.value))}
          min={1}
          required
        />
      </div>

      <button type="submit" className={styles.submitButton}>Schedule Auctions</button>
    </form>
  );
};

export default BatchAuctionScheduler;